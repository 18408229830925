import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, Paper, IconButton, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { CloudUpload, CheckCircle, Delete, Add } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'; // Ensure moment is imported for date formatting
import { FixedSizeList } from 'react-window';
import Autocomplete from '@mui/material/Autocomplete';
import JSZip from 'jszip';
import AWS from 'aws-sdk';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import CarpentryFields from './CarpentryFields';
import ElectricalFields from './ElectricalFields';
import RestorerFields from './RestorerFields';
import RoofPlumberFields from './RoofPlumberFields';
import PlumberFields from './PlumberFields';
import PlastererPainterFields from './PlastererPainterFields';
import FencingFields from './FencingFields';
import CleaningFields from './CleaningFields';
import GeneralTradeFields from './GeneralTradeFields';
import MuiAlert from '@mui/material/Alert';

AWS.config.update({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});



const s3 = new AWS.S3({
  httpOptions: {
      timeout: 300000, // 300 seconds = 5 minutes
      connectTimeout: 60000 // 60 seconds
  }
});
const lambda = new AWS.Lambda();

const schoolsData = [
  { school: "Abbotsford Primary School", campus: "Abbotsford Primary School", address: "Lithgow Street, Abbotsford, VIC, 3067" },
  { school: "Aberfeldie Primary School", campus: "Aberfeldie Primary School", address: "1 Doone Street, Essendon, VIC, 3040" },
  { school: "Ainslie Parklands Primary School", campus: "Ainslie Parklands Primary School", address: "Hinkley Ave, Croydon, VIC, 3136" },
  { school: "Aintree Primary School", campus: "Aintree Primary School", address: "11, Timbertop, Parade, Aintree, VIC, 3336" },
  { school: "Aireys Inlet Primary School", campus: "Aireys Inlet Primary School", address: "Anderson Street, Aireys Inlet, VIC, 3231" },
  { school: "Airly Primary School", campus: "Airly Primary School", address: "226 Airly Estate Road, Airly, VIC, 3851" },
  { school: "Aitken Creek Primary School", campus: "Aitken Creek Primary School", address: "51-81 Grevillea Street, Craigieburn, VIC, 3064" },
  { school: "Aitken Hill Primary School", campus: "Aitken Hill Primary School", address: "2 Rhyolite Drive, Craigieburn, VIC, 3064" },
  { school: "Alamanda K-9 College", campus: "Alamanda K-9 College", address: "86-100 Alamanda Boulevard, Point Cook, VIC, 3030" },
  { school: "Albanvale Primary School", campus: "Albanvale Primary School", address: "54-64 Diamond Ave, Albanvale, VIC, 3021" },
  { school: "Albany Rise Primary School", campus: "Albany Rise Primary School", address: "74-82 Albany Drive, Mulgrave, VIC, 3170" },
  { school: "Albert Park College", campus: "120 Bay Street Campus", address: "120 Bay Street, Port Melbourne, VIC, 3207" },
  { school: "Albert Park College", campus: "Albert Park College", address: "83 Danks Street, Albert Park, VIC, 3206" },
  { school: "Albert Park College", campus: "Environmental Arts Hub", address: "Gasworks Arts Park, Albert Park, VIC, 3206" },
  { school: "Albert Park College", campus: "Bay Street Campus", address: "40 Bay Street, Port Melbourne, VIC, 3207" },
  { school: "Albert Park College", campus: "Liberal Arts Precinct", address: "62-74, Pickles Street, South Melbourne, VIC, 3205" },
  { school: "Albert Park College", campus: "Gatehouse Princes Pier Campus", address: "2 Pier Street, Port Melbourne, VIC, 3207" },
  { school: "Albert Park Primary School", campus: "Albert Park Primary School", address: "Bridport Street, Albert Park, VIC, 3206" },
  { school: "Alberton Primary School", campus: "Alberton Primary School", address: "21 Thomson Street, Alberton, VIC, 3971" },
  { school: "Albion North Primary School", campus: "Albion North Primary School", address: "67-73 Furlong Road, Sunshine North, VIC, 3020" },
  { school: "Albion Primary School", campus: "Albion Primary School", address: "Adelaide Street, Albion, VIC, 3020" },
  { school: "Aldercourt Primary School", campus: "Aldercourt Primary School", address: "Silver Avenue, Frankston North, VIC, 3200" },
  { school: "Alexandra Primary School", campus: "Alexandra Primary School", address: "15 Webster Street, Alexandra, VIC, 3714" },
  { school: "Alexandra Secondary College", campus: "Alexandra Secondary College", address: "Downey Street, Alexandra, VIC, 3714" },
  { school: "Alfredton Primary School", campus: "Alfredton Primary School", address: "89a Cuthberts Road, Alfredton, VIC, 3350" },
  { school: "Alkira Secondary College", campus: "Alkira Secondary College", address: "15 Nurture Avenue, Cranbourne North, VIC, 3977" },
  { school: "Allansford and District Primary School", campus: "Allansford and District Primary School", address: "Frank Street, Allansford, VIC, 3277" },
  { school: "Alphington Primary School", campus: "Alphington Primary School", address: "Yarralea Street, Alphington, VIC, 3078" },
  { school: "Altona College", campus: "Altona College", address: "103 A Grieve Parade, Altona, VIC, 3018" },
  { school: "Altona Green Primary School", campus: "Altona Green Primary School", address: "240 Victoria Street, Altona Meadows, VIC, 3028" },
  { school: "Altona Meadows Primary School", campus: "Altona Meadows Primary School", address: "78-88 Alma Avenue, Altona Meadows, VIC, 3028" },
  { school: "Altona North Primary School", campus: "Altona North Primary School", address: "51 Cresser Street, Altona North, VIC, 3025" },
  { school: "Altona Primary School", campus: "Altona Primary School", address: "109, Blyth Street, Altona, VIC, 3018" },
  { school: "Alvie Consolidated School", campus: "Alvie Consolidated School", address: "40 Wool Wool Rd, Alvie, VIC, 3249" },
  { school: "Amphitheatre Primary School", campus: "Amphitheatre Primary School", address: "16 School Road, Amphitheatre, VIC, 3468" },
  { school: "Amsleigh Park Primary School", campus: "Amsleigh Park Primary School", address: "17 State Street, Oakleigh East, VIC, 3166" },
  { school: "Anakie Primary School", campus: "Anakie Primary School", address: "2125-2135 Ballan Road, Anakie, VIC, 3213" },
  { school: "Andersons Creek Primary School", campus: "Andersons Creek Primary School", address: "36-42 Drysdale Road, Warrandyte, VIC, 3113" },
  { school: "Anglesea Primary School", campus: "Anglesea Primary School", address: "85 Camp Road, Anglesea,VIC,  3230" },
  { school: "Antonio Park Primary School", campus: "Antonio Park Primary School", address: "631-639 Whitehorse Road, Mitcham, VIC, 3132" },
  { school: "Apollo Bay P-12 College", campus: "Apollo Bay P-12 College", address: "Pengilley Avenue, Apollo Bay, VIC, 3233" },
  { school: "Apollo Parkways Primary School", campus: "Apollo Parkways Primary School", address: "31-43 Civic Drive, Greensborough, VIC, 3088" },
  { school: "Appin Park Primary School", campus: "Appin Park Primary School", address: "149 Appin Street, Wangaratta, VIC, 3677" },
  { school: "Apsley Primary School", campus: "Apsley Primary School", address: "46 Laidlaw Street, Apsley, VIC, 3319" },
  { school: "Araluen Primary School", campus: "Araluen Primary School", address: "Patten Street, Sale, VIC, 3850" },
  { school: "Ararat North Primary School", campus: "Ararat North Primary School", address: "66 Blake Street, Ararat, VIC, 3377" },
  { school: "Ararat Primary School", campus: "Ararat Primary School", address: "70-78 Moore Street, Ararat, VIC, 3377" },
  { school: "Ararat Secondary College", campus: "Ararat Secondary College", address: "4-30 Barkly Street, Ararat, VIC, 3377" },
  { school: "Ararat Secondary College", campus: "School Farm Campus", address: "Cnr Elizabeth St and Jacksons Creek Rd, Ararat, VIC, 3377" },
  { school: "Ararat West Primary School", campus: "Ararat West Primary School", address: "Kneebone Street, Ararat, VIC, 3377" },
  { school: "Ardeer Primary School", campus: "Ardeer Primary School", address: "Suspension Street, Ardeer, VIC, 3022" },
  { school: "Ardeer South Primary School", campus: "Ardeer South Primary School", address: "59-75 Murray Street, Sunshine West, VIC, 3020" },
  { school: "Ardmona Primary School", campus: "Ardmona Primary School", address: "580 Turnbull Road, Ardmona, VIC, 3629" },
  { school: "Armadale Primary School", campus: "Armadale Primary School", address: "9-23 Densham Road, Armadale, VIC, 3143" },
  { school: "Armstrong Creek School", campus: "Armstrong Creek School", address: "100 Central Boulevard, Armstrong Creek, VIC, 3217" },
  { school: "Armstrong Creek School", campus: "Specialist P-12 Campus", address: "100 Central Boulevard, Armstrong Creek, VIC, 3217" },
  { school: "Arnolds Creek Primary School", campus: "Arnolds Creek Primary School", address: "59 Claret Ash Boulevard, Melton West, VIC, 3337" },
  { school: "Arthurs Creek Primary School", campus: "Arthurs Creek Primary School", address: "900 Arthurs Creek Road, Arthurs Creek	, VIC, 3099" },
  { school: "Ascot Vale Heights School", campus: "Ascot Vale Heights School", address: "1 Tasma Street, Ascot Vale, VIC, 3032" },
  { school: "Ascot Vale Primary School", campus: "Ascot Vale Primary School", address: "Bank Street, Ascot Vale, VIC, 3032" },
  { school: "Ascot Vale West Primary School", campus: "Ascot Vale West Primary School", address: "Langs Road, Ascot Vale, VIC, 3032" },
  { school: "Ashburton Primary School", campus: "Ashburton Primary School", address: "Fakenha, Road, Ashburton, VIC, 3147" },
  { school: "Ashby Primary School", campus: "Ashby Primary School", address: "1-7 Waratah Street, Geelong West, VIC, 3218" },
  { school: "Ashley Park Primary School", campus: "Ashley Park Primary School", address: "105 Orchard Road, Doreen, VIC, 3754" },
  { school: "Ashwood High School", campus: "Ashwood High School", address: "50 Vannam Drive, Ashwood, VIC, 3147" },
  { school: "Ashwood School", campus: "Ashwood School", address: "Montpellier Road, Ashwood, VIC, 3147" },
  { school: "Aspendale Gardens Primary School", campus: "Aspendale Gardens Primary School", address: "96 Keatney Drive, Aspendale Gardens, VIC, 3195" },
  { school: "Aspendale Primary School", campus: "Aspendale Primary School", address: "23 Laura Street, Aspendale, VIC, 3195" },
  { school: "Athol Road Primary School", campus: "Athol Road Primary School", address: "159-167 Athol Road, Springvale South, VIC, 3172" },
  { school: "Auburn High School", campus: "Auburn High School", address: "26 Burgess Street, Hawthorn East, VIC, 3123" },
  { school: "Auburn Primary School", campus: "Auburn Primary School", address: "51 Rathmines Road, Hawthorn East, VIC, 3123" },
  { school: "Auburn South Primary School", campus: "Auburn South Primary School", address: "419 Tooronga Road, Hawthorn East, VIC, 3123" },
  { school: "Aurora School", campus: "Aurora School", address: "96 Holland Road, Blackburn South, VIC, 3130" },
  { school: "Avenel Primary School", campus: "Avenel Primary School", address: "40 Anderson Street, Avenel, VIC, 3664" },
  { school: "Avenues Education", campus: "Avenues Education", address: "Level 2 999 Nepean Highway, Moorabbin,VIC, 3189" },
  { school: "Avenues Education", campus: "Avenues Education - Eastern Metropolitan", address: "19 Grey Street, Ringwood East, VIC, 3135" },
  { school: "Avoca Primary School", campus: "Avoca Primary School", address: "118 Barnett Street, Avoca, VIC, 3467" },
  { school: "Avondale Primary School", campus: "Avondale Primary School", address: "Clarendon St, Avondale Heights, VIC, 3034" },
  { school: "Axedale Primary School", campus: "Axedale Primary School", address: "81 High Street, Axedale, VIC, 3551" },
  { school: "Bacchus Marsh College", campus: "Bacchus Marsh College", address: "73 Grant Street, Bacchus Marsh, VIC, 3340" },
  { school: "Bacchus Marsh Primary School", campus: "Bacchus Marsh Primary School", address: "56-62 Lerderderg Street, Bacchus Marsh, VIC, 3340" },
  { school: "Baden Powell P-9 College", campus: "Derrimut Heath Campus", address: "Sycamore Street, Hoppers Crossing, VIC, 3029" },
  { school: "Baden Powell P-9 College", campus: "Tarneit Campus", address: "81-97 Baden Powell Drive, Tarneit, VIC, 3029" },
  { school: "Badger Creek Primary School", campus: "Badger Creek Primary School", address: "Badger Creek Rd, Healesville, VIC, 3777" },
  { school: "Baimbridge College", campus: "Baimbridge College", address: "59 Mt Baimbridge Rd, Hamilton, VIC, 3300" },
  { school: "Bairnsdale Primary School", campus: "Bairnsdale Primary School", address: "330-370 Main Street, Bairnsdale, VIC, 3857" },
  { school: "Bairnsdale Secondary College", campus: "Bairnsdale Secondary College", address: "90 Mclean Street, Bairnsdale, VIC, 3857" },
  { school: "Bairnsdale Secondary College", campus: "Changing Lanes - VCAL", address: "91 Rupert Street, Bairnsdale, VIC, 3857" },
  { school: "Bairnsdale West Primary School", campus: "Bairnsdale West Primary School", address: "Cnr Wallace & Mckean Streets, Bairnsdale, VIC, 3857" },
  { school: "Ballam Park Primary School", campus: "Ballam Park Primary School", address: "Belar Avenue, Frankston, VIC, 3199" },
  { school: "Ballan Primary School", campus: "Ballan Primary School", address: "8-14 Duncan Street, Ballan, VIC, 3342" },
  { school: "Ballarat High School", campus: "Ballarat High School", address: "Sturt Street West, Ballarat, VIC, 3350" },
  { school: "Ballarat North Primary School", campus: "Ballarat North Primary School", address: "513 Landsborough Street, Ballarat, VIC, 3350" },
  { school: "Ballarat Primary School (Dana Street)", campus: "Ballarat Primary School (Dana Street)", address: "401 Dana Street, Ballarat, VIC, 3350" },
  { school: "Ballarat Specialist School", campus: "Gillies Street Campus", address: "25 Gilliws Street North, Lake Gardens, VIC, 3355" },
  { school: "Ballarat Specialist School", campus: "Midlands Farm Campus", address: "800 Norman Street, Invermay Park, VIC, 3352" },
  { school: "Balliang East Primary School", campus: "Balliang East Primary School", address: "4 School Road, Bailang East, VIC, 3340" },
  { school: "Balmoral K-12 Community College", campus: "Balmoral K-12 Community College", address: "Harrow Balmoral Road, Balmoral, VIC, 3407" },
  { school: "Balnarring Primary School", campus: "Balnarring Primary School", address: "1 Civic Court, Balnarring, VIC, 3926" },
  { school: "Balwyn High School", campus: "Balwyn High School", address: "Buchanan Avenue, Balwyn North, VIC, 3104" },
  { school: "Balwyn North Primary School", campus: "Balwyn North Primary School", address: "Buchanan Avenue, Balwyn North, VIC, 3104" },
  { school: "Balwyn Primary School", campus: "Balwyn Primary School", address: "86B Balwyn Road, Balwyn, VIC, 3103" },
  { school: "Bandiana Primary School", campus: "Bandiana Primary School", address: "44 Leumeah Road, Bandiana, VIC, 3694" },
  { school: "Bannockburn P-12 College", campus: "Bannockburn P-12 College", address: "139 Milton Street, Bannockburn, VIC, 3331" },
  { school: "Banyan Fields Primary School", campus: "Banyan Fields Primary School", address: "90 Cadles Road, Carrum Downs, VIC 3201" },
  { school: "Banyule Primary School", campus: "Banyule Primary School", address: "Banyule Road, Rosanna, VIC, 3084" },
  { school: "Baranduda Primary School", campus: "Baranduda Primary School", address: "7 Verbena Street, Baranduda, VIC, 3691" },
  { school: "Baringa Special School", campus: "Baringa Special School", address: "47 Staff Street, Moe, VIC, 3825" },
  { school: "Baringa Special School", campus: "Baringa Homestead", address: "984 Allambee-Childers Road, Childers, VIC, 3824" },
  { school: "Baringhup Primary School", campus: "Baringhup Primary School", address: "Mitchell Street, Baringhup, VIC,  3463" },
  { school: "Barnawartha Primary School", campus: "Barnawartha Primary School", address: "11-17 Stanely Street, Barnawartha, VIC, 3688" },
  { school: "Barton Primary School", campus: "Barton Primary School", address: "90 Everlasting Boulevard, Cranbourne West, 3977" },
  { school: "Barwon Heads Primary School", campus: "Barwon Heads Primary School", address: "Golf Links Road, Barwon Heads, VIC, 3227" },
  { school: "Barwon Valley School", campus: "Barwon Valley School", address: "76-94 Laura Avenue, Belmont, VIC, 3216" },
  { school: "Bass Coast Specialist School", campus: "Bass Coast Specialist School", address: "6 Mckenzie Street, Wonthaggi, VIC, 3995" },
  { school: "Bass Valley Primary School", campus: "Bass Valley Primary School", address: "70 Corinella Road, Corinella, VIC, 3984" },
  { school: "Baxter Primary School", campus: "Baxter Primary School", address: "Grant Road, Baxter, VIC, 3911" },
  { school: "Bayles Regional Primary School", campus: "Bayles Regional Primary School", address: "3730 Ballarto Road, Bayles, VIC, 3981" },
  { school: "Bayside P-12 College", campus: "Altona North Campus", address: "1 McArthurs Road, Altona North, VIC, 3025" },
  { school: "Bayside P-12 College", campus: "Williamstown Campus", address: "29-69 Kororoit Creek Road, Williamstown, VIC, 3016" },
  { school: "Bayside P-12 College", campus: "Paisley Campus", address: "33 Blenheim Road, Newport, VIC, 3015" },
  { school: "Bayside Special Developmental School", campus: "Bayside Special Developmental School", address: "4 Genoa Street, Moorabbin, VIC, 3189" },
  { school: "Bayswater North Primary School", campus: "Bayswater North Primary School", address: "Stuart Street, Bayswater North, VIC 3153" },
  { school: "Bayswater Primary School", campus: "Bayswater Primary School", address: "2 Birch Street, bayswater, VIC, 3153" },
  { school: "Bayswater Secondary College", campus: "Bayswater Secondary College", address: "14 Orchard Road, Bayswater, VIC, 3153" },
  { school: "Bayswater South Primary School", campus: "Bayswater South Primary School", address: "Enfield Drive, Bayswater, VIC, 3153" },
  { school: "Bayswater West Primary School", campus: "Bayswater West Primary School", address: "Phyllis Street, Bbayswater, VIC, 3153" },
  { school: "Beaconsfield Primary School", campus: "Beaconsfield Primary School", address: "71-77 Old Princess Highway, Beaconsfield, VIC, 3807" },
  { school: "Beaconsfield Upper Primary School", campus: "Beaconsfield Upper Primary School", address: "40 Stoney Creek Road, BEACONSFIELD UPPER, VIC, 3808" },
  { school: "Bealiba Primary School", campus: "Bealiba Primary School", address: "9 Grant Street, Bealiba, VIC, 3475" },
  { school: "Beaufort Primary School", campus: "Beaufort Primary School", address: "2/37 Park Road, Beaufort, VIC, 3373" },
  { school: "Beaufort Secondary College", campus: "Beaufort Secondary College", address: "1/37 Park Road, Beaufort, VIC, 3373" },
  { school: "Beaumaris North Primary School", campus: "Beaumaris North Primary School", address: "Wood Street, Beaumaris, VIC, 3193" },
  { school: "Beaumaris Primary School", campus: "Beaumaris Primary School", address: "79-99 Dalgetty Road, VIC, 3193" },
  { school: "Beaumaris Secondary College", campus: "Beaumaris Secondary College", address: "117-136 Reserve Road, Beaumaris, VIC, 3193" },
  { school: "Beeac Primary School", campus: "Beeac Primary School", address: "35 Lang Street, Beeac, VIC, 3251" },
  { school: "Beechworth Primary School", campus: "Beechworth Primary School", address: "17 Junction Road, Beechworth, VIC, 3753" },
  { school: "Beechworth Secondary College", campus: "Beechworth Secondary College", address: "85 Balaclava Road, Beechworth, VIC, 3747" },
  { school: "Belgrave South Primary School", campus: "Belgrave South Primary School", address: "175 Colby Drive, Belgrave South, VIC, 3160" },
  { school: "Bell Park North Primary School", campus: "Bell Park North Primary School", address: "16-26 Barton Street, Bell Park, VIC, 3215" },
  { school: "Bell Park North Primary School", campus: "Geelong English Language Program", address: "26 Barton Street, Bell Park, VIC, 3215" },
  { school: "Bell Primary School", campus: "Bell Primary School", address: "78A Oakover Road, Preston, VIC, 3072" },
  { school: "Bellaire Primary School", campus: "Bellaire Primary School", address: "59-71 Larcombe Street, Highton, VIC, 3216" },
  { school: "Bellarine Secondary College", campus: "Drysdale Campus", address: "Peninsula Drive, Drysdale, VIC, 3222" },
  { school: "Bellarine Secondary College", campus: "Ocean Grove Campus", address: "70 Shell Road, Ocean Grove, VIC, 3226" },
  { school: "Bellbrae Primary School", campus: "Bellbrae Primary School", address: "50 School Road, Bellbrae, VIC, 3228" },
  { school: "Bellbridge Primary School", campus: "Bellbridge Primary School", address: "120 Bellbridge Drive, Hoppers Crossing, VIC, 3029" },
  { school: "Belle Vue Park Primary School", campus: "Belle Vue Park Primary School", address: "Morell Street, Glenroy, VIC, 3046" },
  { school: "Belle Vue Primary School", campus: "Belle Vue Primary School", address: "20 Highview Road, Balwyn North, VIC, 3104" },
  { school: "Belmont High School", campus: "Belmont High School", address: "Rotherham Street, Belmont, VIC, 3216" },
  { school: "Belmont High School", campus: "Tanybryn Camp", address: "Cnr Wild Dog Road, Tanybryn, VIC, 3233" },
  { school: "Belmont Primary School", campus: "Belmont Primary School", address: "51 Mt Pleasant Road, Belmont, VIC, 3216" },
  { school: "Belmore School", campus: "Belmore School", address: "49 Stroud Street, Balwyn, VIC, 3103" },
  { school: "Belvedere Park Primary School", campus: "Belvedere Park Primary School", address: "30 Belvedere Road, Seaford, VIC, 3198" },
  { school: "Belvoir Wodonga Special Developmental School", campus: "Belvoir Wodonga Special Developmental School", address: "54 Gayview Drive, West Wodonga, VIC, 3690" },
  { school: "Benalla P-12 College", campus: "Faithfull Street Campus", address: "20 Faithfull Street, Benalla, VIC, 3672" },
  { school: "Benalla P-12 College", campus: "Barkly Street Campus", address: "41-55 Barkly Street, Benalla, VIC, 3672" },
  { school: "Benalla P-12 College", campus: "Waller Street Campus", address: "127-139 Waller Street, Benalla, VIC, 3672" },
  { school: "Benalla P-12 College", campus: "Lake Nillacoote Camp", address: "4035 Midland Highway, Benalla, VIC, 3672" },
  { school: "Benalla P-12 College", campus: "Avon Street Campus", address: "Avon Street, Benalla, VIC, 3672" },
  { school: "Benalla P-12 College", campus: "Clarke Street Campus", address: "44 Clarke Street, Benalla, VIC, 3672" },
  { school: "Bendigo Primary School", campus: "Bendigo Primary School", address: "Old Violet Street, Bendigo, VIC, 3550" },
  { school: "Bendigo Senior Secondary College", campus: "Netschool Campus", address: "26 Williamson Street, Bendigo, VIC, 3550" },
  { school: "Bendigo Senior Secondary College", campus: "Bendigo Senior Secondary College", address: "40 Goal Road, Bendigo, VIC, 3550" },
  { school: "Bendigo South East 7-10 Secondary College", campus: "Bendigo South East 7-10 Secondary College", address: "Ellis Street, Bendigo, VIC, 3550" },
  { school: "Bendigo Special Developmental School", campus: "Bendigo Special Developmental School", address: "26-32 Lockwood Rpad, Kangaroo Flat, VIC, 3555" },
  { school: "Bentleigh Secondary College", campus: "Bentleigh Secondary College", address: "Vivien St, Bentleigh East, VIC, 3165" },
  { school: "Bentleigh West Primary School", campus: "Bentleigh West Primary School", address: "23 Brewer Road, Bentleigh, VIC, 3204" },
  { school: "Benton Junior College", campus: "Benton Junior College", address: "261 Racecourse Road, Mornington, VIC, 3931" },
  { school: "Berendale School", campus: "Berendale School", address: "2 Berend Street, Hampton East, VIC, 3188" },
  { school: "Berendale School", campus: "SMR Secondary Teaching Unit", address: "2 Berend Street, Hampton East, VIC, 3488" },
  { school: "Berwick Chase Primary School", campus: "Berwick Chase Primary School", address: "72 Viewgrand Drive, Berwick, VIC, 3806" },
  { school: "Berwick Fields Primary School", campus: "Berwick Fields Primary School", address: "35 Gwendoline Drive, Berwick, VIC, 3806" },
  { school: "Berwick Lodge Primary School", campus: "Berwick Lodge Primary School", address: "139-145 Mansfield Street, Berwick, VIC, 3806" },
  { school: "Berwick Primary School", campus: "Berwick Primary School", address: "37 Fairholme Boulevard, Berwick, VIC, 3806" },
  { school: "Berwick Secondary College", campus: "Berwick Secondary College", address: "Manuka Road, Berwick, VIC, 3806" },
  { school: "Bethal Primary School", campus: "Bethal Primary School", address: "26-52 Eldorado Crescent, Meadow Heights, VIC, 3048" },
  { school: "Bethanga Primary School", campus: "Bethanga Primary School", address: "5 Beardmore Street, Bethanga, VIC, 3691" },
  { school: "Beulah Primary School", campus: "Beulah Primary School", address: "Henty Highway, Beulah, VIC, 3395" },
  { school: "Beverford District Primary School", campus: "Beverford District Primary School", address: "20 School Road, Beverford, VIC, 3590" },
  { school: "Beveridge Primary School", campus: "Beveridge Primary School", address: "Arrowsmith Street, Beveridge, VIC, 3753" },
  { school: "Beveridge Primary School", campus: "Ambrosia Campus (interim name)", address: "30 Ambrosia Way, Beveridge, VIC, 3753" },
  { school: "Beverley Hills Primary School", campus: "Beverley Hills Primary School", address: "2-14 Cassowary Street, Doncaster East, VIC, 3109" },
  { school: "Big Hill Primary School", campus: "Big Hill Primary School", address: "5644 Calder Highway, Big Hill, VIC, 3555" },
  { school: "Billanook Primary School", campus: "Billanook Primary School", address: "270 Sheffield Road, Montrose, VIC, 3675" },
  { school: "Bimbadeen Heights Primary School", campus: "Bimbadeen Heights Primary School", address: "118 Hayrick Lane, Mooroolbark, VIC, 3138" },
  { school: "Birchip P-12 School", campus: "Birchip P-12 School", address: "43-47 Campbell Street, Birchip, VIC, 3483" },
  { school: "Birmingham Primary School", campus: "Birmingham Primary School", address: "43-49 Francis crescent, Mount Evelyn, VIC, 3796" },
  { school: "Birralee Primary School", campus: "Birralee Primary School", address: "Heyington Avenue, Doncaster, VIC, 3108" },
  { school: "Birregurra Primary School", campus: "Birregurra Primary School", address: "58 Beal Street, Birregurra, VIC, 3242" },
  { school: "Bittern Primary School", campus: "Bittern Primary School", address: "Portsmouth Road, Bittern, VIC, 3918" },
  { school: "Black Hill Primary School", campus: "Black Hill Primary School", address: "601 Chisholm Street, Ballarat, VIC, 3350" },
  { school: "Black Rock Primary School", campus: "Black Rock Primary School", address: "71 Arkaringa Crescent, Black Rock, VIC, 3913" },
  { school: "Blackburn English Language School", campus: "Blackburn English Language School", address: "120 Eley Road, Burwood East, VIC, 3151" },
  { school: "Blackburn English Language School", campus: "Maroondah Campus", address: "431 Maroondah Highway, Croydon North, VIC, 3136" },
  { school: "Blackburn English Language School", campus: "Wodonga Campus", address: "22-24 Mitchell Street, Wodonga, VIC, 3690" },
  { school: "Blackburn High School", campus: "Blackburn High School", address: "60 Springfield, Blackburn, VIC, 3130" },
  { school: "Blackburn Lake Primary School", campus: "Blackburn Lake Primary School", address: "12-14 Florence Street, Blackburn, VIC, 3130" },
  { school: "Blackburn Primary School", campus: "Blackburn Primary School", address: "185 Surrey Road, Blackburn, VIC, 3130" },
  { school: "Boisdale Consolidated School", campus: "Boisdale Consolidated School", address: "69 Newry Road, Boisdale, VIC, 3860" },
  { school: "Bolinda Primary School", campus: "Bolinda Primary School", address: "1 Mullalys Road, Bolinda, VIC, 3432" },
  { school: "Bolwarra Primary School", campus: "Bolwarra Primary School", address: "249 Princes Highway, Bolwarra, VIC, 3305" },
  { school: "Bona Vista Primary School", campus: "Bona Vista Primary School", address: "520 Bona Vista Road, Bona Vista, VIC, 3820" },
  { school: "Bonbeach Primary School", campus: "Bonbeach Primary School", address: "29-63 Breeze Street, Bonbeach, VIC, 3196" },
  { school: "Boneo Primary School", campus: "Boneo Primary School", address: "582 Boneo Road, Boneo, VIC, 3939" },
  { school: "Boolarra Primary School", campus: "Boolarra Primary School", address: "Tarwin Street, Boolarra, VIC, 3870" },
  { school: "Boort District P-12 School", campus: "Boort District P-12 School", address: "9-27 Malone Street, Boort, VIC, 3537" },
  { school: "Boronia Heights Primary School", campus: "Boronia Heights Primary School", address: "Landscape Drive, Boronia, VIC, 3155" },
  { school: "Boronia K-12 College", campus: "Boronia K-12 College", address: "35-37 Albert Avenue, Boronia, VIC, 3155" },
  { school: "Boronia West Primary School", campus: "Boronia West Primary School", address: "25 Tormore Road, Boronia, VIC, 3155" },
  { school: "Boroondara Park Primary School", campus: "Boroondara Park Primary School", address: "40 Almond Street, Balwyn North, VIC, 3104" },
  { school: "Botanic Ridge Primary School", campus: "Botanic Ridge Primary School", address: "10 Eschidna Parade, Botanic Ridge, VIC, 3977" },
  { school: "Bourchier Street Primary School Shepparton", campus: "Bourchier Street Primary School Shepparton", address: "Cnr Bourchier St & Balaclava R, VIC, 3630" },
  { school: "Box Hill High School", campus: "Box Hill High School", address: "1180 Whitehorse Road, Box Hill, VIC, 3128" },
  { school: "Box Hill North Primary School", campus: "Box Hill North Primary School", address: "Elizabeth Street, Box Hill North, VIC, 3129" },
  { school: "Box Hill Senior Secondary College", campus: "Box Hill Senior Secondary College", address: "Dunloe Avenue, Mont Albert North, VIC, 3129" },
  { school: "Brandon Park Primary School", campus: "Brandon Park Primary School", address: "1-5 Ninevah Crescent, Whellers Hill, VIC, 3150" },
  { school: "Branxholme-Wallacedale Community School", campus: "Branxholme-Wallacedale Community School", address: "2 Monroe Street, Branxholme, VIC, 3302" },
  { school: "Brauer Secondary College", campus: "Brauer Secondary College", address: "Caramut Road, Warrnambool, VIC, 3280" },
  { school: "Brauer Secondary College", campus: "Dunkeld Annexe", address: "Grampians Tourist Road, Dunkeld, VIC, 3294" },
  { school: "Braybrook College", campus: "Braybrook College", address: "352 Ballarat Road, Braybrook, VIC, 3019" },
  { school: "Brentwood Park Primary School", campus: "Brentwood Park Primary School", address: "Bemersyde Drive, Berwick, VIC, 3806" },
  { school: "Brentwood Secondary College", campus: "Brentwood Secondary College", address: "65-71 Watsons Road, Glen Waverley, VIC, 3150" },
  { school: "Briagolong Primary School", campus: "Briagolong Primary School", address: "2 Church Street, Briagolong, VIC, 3860" },
  { school: "Briar Hill Primary School", campus: "Briar Hill Primary School", address: "25 Gladstone Road, Briar Hill, VIC, 3088" },
  { school: "Bridgewater Primary School", campus: "Bridgewater Primary School", address: "34-44 Eldon Street, Bridgewater On London, VIC, 3516" },
  { school: "Bridgewood Primary School", campus: "Bridgewood Primary School", address: "115 Bridge Road, Officer, VIC, 3809" },
  { school: "Bright P-12 College", campus: "Bright P-12 College", address: "46 bakers Gully Road, Bright, VIC, 3741" },
  { school: "Bright P-12 College", campus: "Dinner Plain Campus", address: "Community House, Dinner Plain, VIC, 3898" },
  { school: "Brighton Beach Primary School", campus: "Brighton Beach Primary School", address: "19 Windermere Crescent, Brighton, VIC, 3186" },
  { school: "Brighton Primary School", campus: "Brighton Primary School", address: "59 Wilson Street, Brighton, VIC, 3186" },
  { school: "Brighton Primary School", campus: "Brighton Primary School Deaf Facility", address: "Wilson Street, Brighton, VIC, 3186" },
  { school: "Brighton Secondary College", campus: "Brighton Secondary College", address: "120 Marriage Road, Brighton East, VIC, 3187" },
  { school: "Broadford Primary School", campus: "Broadford Primary School", address: "17-23 Powlett Street, Broadford, VIC, 3658" },
  { school: "Broadford Secondary College", campus: "Broadford Secondary College", address: "2-12 Pinniger Street, Broadford,  VIC, 3658" },
  { school: "Broadmeadows Primary School", campus: "Broadmeadows Primary School", address: "62-70 Blair Street, Broadmeadows, VIC, 3047" },
  { school: "Broadmeadows Special Developmental School", campus: "Sorrento Street Campus", address: "30-40 Sorrento Street, Broadmeadows, VIC, 3047" },
  { school: "Broadmeadows Special Developmental School", campus: "Dimboola Road Campus", address: "29-35 Dimboola Rd, Broadmeadows, VIC, 3047" },
  { school: "Broadmeadows Valley Primary School", campus: "Broadmeadows Valley Primary School", address: "Dimbool Road, Broadmeadows, VIC, 3047" },
  { school: "Broken Creek Primary School", campus: "Broken Creek Primary School", address: "413 Quinn Road, Broken Creek, VIC, 3673" },
  { school: "Brookside P-9 College", campus: "Brookside P-9 College", address: "13-16 Federation Way, Caroline Springs, VIC, 3023" },
  { school: "Brunswick East Primary School", campus: "Brunswick East Primary School", address: "195a Stewart Street, Brunswick East, VIC, 3057" },
  { school: "Brunswick North Primary School", campus: "Brunswick North Primary School", address: "Pearson Street, Brunswick West, VIC, 3055" },
  { school: "Brunswick North West Primary School", campus: "Brunswick North West Primary School", address: "culloden Street, Brunswick West, VIC, 3055" },
  { school: "Brunswick Secondary College", campus: "Brunswick Secondary College", address: "47 Dawson Street, Brunswick, VIC, 3056" },
  { school: "Brunswick Secondary College", campus: "Brunswick English Language Centre", address: "47 Dawson Street, Brunswick, VIC, 3056" },
  { school: "Brunswick South Primary School", campus: "Brunswick South Primary School", address: "56 Brunswick Road, Brunswick East, VIC, 3057" },
  { school: "Brunswick South West Primary School", campus: "Brunswick South West Primary School", address: "South Daly Street, Brunswick West, VIC, 3055" },
  { school: "Bruthen Primary School", campus: "Bruthen Primary School", address: "31 Great Alpine Road, Bruthen, VIC, 3885" },
  { school: "Buangor Primary School", campus: "Buangor Primary School", address: "26 School Road, Buangor, VIC, 3375" },
  { school: "Buchan Primary School", campus: "Buchan Primary School", address: "4 Dalley Street, Buchan, VIC, 3885" },
  { school: "Buckley Park College", campus: "Buckley Park College", address: "59-99 Cooper Street, Essendon, VIC, 3040" },
  { school: "Bullarto Primary School", campus: "Bullarto Primary School", address: "131 Bullarto South Road, Bullarto, VIC, 3461" },
  { school: "Bulleen Heights School", campus: "Bulleen Heights School", address: "49-61 Pleasant Road, Bulleen, VIC, 3105" },
  { school: "Bulleen Heights School", campus: "Manningham Road Campus", address: "221 Manningham Road, Lower Templestowe, VIC, 3107" },
  { school: "Bulleen Heights School", campus: "Garfield North Outdoor Education Centre", address: "335 Garfield North Road, Garfield North, VIC, 3814" },
  { school: "Buln Buln Primary School", campus: "Buln Buln Primary School", address: "15 Old Sale Road, Buln Buln, VIC, 3821" },
  { school: "Bundalaguah Primary School", campus: "Bundalaguah Primary School", address: "627 Maffra-Sale Road, Bundalaguah, VIC, 3851" },
  { school: "Bundarra Primary School", campus: "Bundarra Primary School", address: "Cnr Fawthrop & Edgar Streets, Portland, VIC, 3305" },
  { school: "Bundoora Primary School", campus: "Bundoora Primary School", address: "6 Bendoran Crescent, Bundoora, VIC, 3083" },
  { school: "Bundoora Secondary College", campus: "Bundoora Secondary College", address: "53 Balmoral Avenue, Bundoora, VIC, 3083" },
  { school: "Bungaree Primary School", campus: "Bungaree Primary School", address: "348 Bungaree-wallace Road, Bungaree, VIC, 3352" },
  { school: "Buninyong Primary School", campus: "Buninyong Primary School", address: "202 Simpson Street, Buninyong, VIC, 3357" },
  { school: "Buninyong Primary School", campus: "Scotsburn Primary School", address: "6859 Midland Highway, Scotsburn, VIC, 3352" },
  { school: "Bunyip Primary School", campus: "Bunyip Primary School", address: "1290 Nar Nar Goon - Longwarry Road, Bunyip, VIC, 3815" },
  { school: "Burnside Primary School", campus: "Burnside Primary School", address: "35 Lexington Drive, Burnside, VIC, 3023" },
  { school: "Burwood East Primary School", campus: "Burwood East Primary School", address: "Cnr Blackburn And Highbury Rds, Burwood East, VIC, 3151" },
  { school: "Burwood East Special Developmental School", campus: "Burwood East Special Developmental School", address: "18 Manooka Street, Burwood East, VIC, 3151" },
  { school: "Burwood Heights Primary School", campus: "Burwood Heights Primary School", address: "Cnr Hawthorn & Mahoneys Rds, VIC, 3151" },
  { school: "Buxton Primary School", campus: "Buxton Primary School", address: "2208 Maroondah Highway, Buxton, VIC, 3711" },
  { school: "Cairnlea Park Primary School", campus: "Cairnlea Park Primary School", address: "49 Carmody Drive, Cairnlea, VIC, 3023" },
  { school: "Cairnlea Park Primary School", campus: "Western Special Assistance Unit - Language", address: "Carmody Drive, Cairnlea, VIC, 3023" },
  { school: "Caledonian Primary School", campus: "Caledonian Primary School", address: "Thompson Street, Brown hill, VIC, 3350" },
  { school: "California Gully Primary School", campus: "California Gully Primary School", address: "4-14 Staley Street, California Gully, VIC, 3556" },
  { school: "Camberwell High School", campus: "Camberwell High School", address: "Prospect Hill Road, Canterbury, VIC, 3126" },
  { school: "Camberwell Primary School", campus: "Camberwell Primary School", address: "290 Camberwell, Camberwell, VIC, 3124" },
  { school: "Camberwell South Primary School", campus: "Camberwell South Primary School", address: "Peate Avenue, Glen Iris, VIC, 3146" },
  { school: "Cambridge Primary School", campus: "Cambridge Primary School", address: "Carruthers Drive, Hoppers Crossing, VIC, 3029" },
  { school: "Camelot Rise Primary School", campus: "Camelot Rise Primary School", address: "Galahad Crescent, Glen Waverley, VIC, 3150" },
  { school: "Camp Hill Primary School", campus: "Camp Hill Primary School", address: "50 Gaol Rd, Bendigo, VIC, 3550" },
  { school: "Campbellfield Heights Primary School", campus: "Campbellfield Heights Primary School", address: "1-13 Laurel Crescent, Campbellfield, VIC, 3061" },
  { school: "Campbells Creek Primary School", campus: "Campbells Creek Primary School", address: "Mains Road, Campbells Creek, VIC, 3451" },
  { school: "Campbells Creek Primary School", campus: "Guildford Campus", address: "6 Franklin Street, Guildford, VIC, 3451" },
  { school: "Camperdown College", campus: "Wilson Street", address: "1 Wilson Street, Camperdown, VIC, 3260" },
  { school: "Camperdown College", campus: "Brooke Street", address: "30 Brooke Street, Camperdown, VIC, 3260" },
  { school: "Canadian Lead Primary School", campus: "Canadian Lead Primary School", address: "301 Otway Street South, Ballarat East, VIC, 3350" },
  { school: "Cann River P-12 College", campus: "Cann River P-12 College", address: "20-24 Tamboon Road, Cann River, VIC, 3890" },
  { school: "Canterbury Girls Secondary College", campus: "Canterbury Girls Secondary College", address: "16 Mangarra Road, Canterbury, VIC, 3126" },
  { school: "Canterbury Primary School", campus: "Canterbury Primary School", address: "Molesworth Street, Canterbury, VIC, 3126" },
  { school: "Cape Clear Primary School", campus: "Cape Clear Primary School", address: "1539 Scardale-pitfield Road, VIC, 3351" },
  { school: "Cardinia Primary School", campus: "Cardinia Primary School", address: "2405 Ballarto Road, Cardinia, VIC, 3978" },
  { school: "Cardross Primary School", campus: "Cardross Primary School", address: "415 Dairtnnk Avenue, Cardross, VIC, 3496" },
  { school: "Carisbrook Primary School", campus: "Carisbrook Primary School", address: "2 Camp Street, Carisbrook, VIC, 3464" },
  { school: "Carlisle River Primary School", campus: "Carlisle River Primary School", address: "49 Moomowroong Road, Carlisle River, VIC, 3239" },
  { school: "Carlton Gardens Primary School", campus: "Carlton Gardens Primary School", address: "215 Rathdowne Street, Carlton, VIC, 3053" },
  { school: "Carlton North Primary School", campus: "Carlton North Primary School", address: "60 Lee Street, Carlton North, VIC, 3054" },
  { school: "Carlton Primary School", campus: "Carlton Primary School", address: "150 Palmerston Street, Carlton, VIC, 3053" },
  { school: "Carnegie Primary School", campus: "Carnegie Primary School", address: "51 Truganini Road, Carnegie, VIC, 3163" },
  { school: "Carranballac P-9 College", campus: "Boardwalk Campus", address: "Cnr Foxwood Dve & Dunnings Rd, Point Cook, VIC, 3030" },
  { school: "Carranballac P-9 College", campus: "Jamieson Way Campus", address: "Cnr Jamieson Way & La Rochelle, Point Cook, VIC, 3030" },
  { school: "Carraragarmungee Primary School", campus: "Carraragarmungee Primary School", address: "1005 Wangaratta-Eldorado Road, Londrigan, VIC, 3678" },
  { school: "Carrington Primary School", campus: "Carrington Primary School", address: "Laura Road, Knoxfield, VIC, 3180" },
  { school: "Carrum Downs Secondary College", campus: "Carrum Downs Secondary College", address: "263 McCormicks Road, Carrum Downs, VIC, 3201" },
  { school: "Carrum Primary School", campus: "Carrum Primary School", address: "Walkers Road, Carrum, VIC, 3197" },
  { school: "Carwatha College P-12", campus: "Carwatha College P-12", address: "43-81 Browns Road, Noble Park, VIC, 3174" },
  { school: "Casey Fields Primary School", campus: "Casey Fields Primary School", address: "50S Eliburn Drive, Cranbourne East, VIC, 3977" },
  { school: "Casterton Primary School", campus: "Casterton Primary School", address: "14-24 Mc Pherson Street, Caserton, VIC, 3311" },
  { school: "Casterton Secondary College", campus: "Casterton Secondary College", address: "27 Mt Gambier Road, Caserton, VIC, 3311" },
  { school: "Castlemaine North Primary School", campus: "Castlemaine North Primary School", address: "270 Bakers Street, Castlemaine, VIC, 3450" },
  { school: "Castlemaine Primary School", campus: "Castlemaine Primary School", address: "Cnr Mostyn & Urquhart Street, Castlemaine, VIC, 3450" },
  { school: "Castlemaine Secondary College", campus: "Yapeen Campus", address: "Yapeen School Lane, Yapeen, VIC, 3451" },
  { school: "Castlemaine Secondary College", campus: "Senior Campus", address: "Etty Street, Castlemaine, VIC, 3450" },
  { school: "Castlemaine Secondary College", campus: "Junior Campus", address: "Blakeley Road, Castlemaine, VIC, 3450" },
  { school: "Caulfield Junior College", campus: "Caulfield Junior College", address: "186 Balaclava Road, Caufield North, VIC, 3161" },
  { school: "Caulfield Primary School", campus: "Caulfield Primary School", address: "724 Glen Huntly Road, Caufield South, VIC, 3162" },
  { school: "Caulfield South Primary School", campus: "Caulfield South Primary School", address: "24 Bundeera Road, Caufield South, VIC, 3162" },
  { school: "Cavendish Primary School", campus: "Cavendish Primary School", address: "8-10 Barker Street, Cavendish, VIC, 3314" },
  { school: "Ceres Primary School", campus: "Ceres Primary School", address: "605 Barrabool Road, Ceres, VIC, 3221" },
  { school: "Chaffey Secondary College", campus: "Chaffey Secondary College", address: "261-289 Deakin Avenue, Mildura, VIC, 3500" },
  { school: "Chalcot Lodge Primary School", campus: "Chalcot Lodge Primary School", address: "Primrose Hill Close, Endeavour Hills, VIC, 3802" },
  { school: "Chandler Park Primary School", campus: "Chandler Park Primary School", address: "Cochrane Avenue, Keysborough, VIC, 3173" },
  { school: "Charles La Trobe P-12 College", campus: "Pavilion - The Edge South Morang Campus", address: "Cnr of Cooper Street and Dalton Road, VIC, 3076" },
  { school: "Charles La Trobe P-12 College", campus: "Deaf Facility", address: "235 Kingsbury Drive, Macleod West, VIC, 3081" },
  { school: "Charles La Trobe P-12 College", campus: "La Trobe Campus", address: "235 Kingsbury Drive, Macleod West, VIC, 3081" },
  { school: "Charles La Trobe P-12 College", campus: "The Pavilion Campus - Preston East", address: "1-3 Sylvester Grove, Preston East, VIC, 3072" },
  { school: "Charles La Trobe P-12 College", campus: "Olympic Village Primary Campus", address: "152 Southern Road, West Heidelberg, VIC, 3081" },
  { school: "Charlton College", campus: "Charlton College", address: "33-49 Davies Street, Charlton, VIC, 3525" },
  { school: "Chatham Primary School", campus: "Chatham Primary School", address: "47A Weybridge Street, Surrey Hills, VIC, 3127" },
  { school: "Chelsea Heights Primary School", campus: "Chelsea Heights Primary School", address: "194 Thames Promenade, Chelsea Heights, VIC,  3196" },
  { school: "Chelsea Primary School", campus: "Chelsea Primary School", address: "34-44 Argyle Ave, Chelsea, VIC, 3196" },
  { school: "Cheltenham East Primary School", campus: "Cheltenham East Primary School", address: "Silver Street, Cheltenham, VIC, 3192" },
  { school: "Cheltenham Primary School", campus: "Cheltenham Primary School", address: "231 Charman Road, Cheltenham, VIC, 3192" },
  { school: "Cheltenham Secondary College", campus: "Cheltenham Secondary College", address: "73-75 Bernard Street, Cheltenham, VIC, 3192" },
  { school: "Chewton Primary School", campus: "Chewton Primary School", address: "1 Hunter Street, Chewton,  VIC, 3451" },
  { school: "Chiltern Primary School", campus: "Chiltern Primary School", address: "91-101 Albert Road, Chiltern, VIC, 3683" },
  { school: "Chilwell Primary School", campus: "Chilwell Primary School", address: "313a Pakington Street, Newtown, VIC, 3220" },
  { school: "Chirnside Park Primary School", campus: "Chirnside Park Primary School", address: "66 Kimberely Drive, Chirnside Park, VIC, 3116" },
  { school: "Christmas Hills Primary School", campus: "Christmas Hills Primary School", address: "1409 Eltham-yarra Glen Road, Christmas Hills, VIC, 3775" },
  { school: "Chum Creek Primary School", campus: "Chum Creek Primary School", address: "705 Chum Creek Road, Chum Creek, VIC, 3777" },
  { school: "Churchill North Primary School", campus: "Churchill North Primary School", address: "Blackwood Crescent, Churchill, VIC, 3842" },
  { school: "Churchill Primary School", campus: "Churchill Primary School", address: "Coleman Parade, Churchill, VIC, 3842" },
  { school: "Churchill Primary School", campus: "Hazelwood Estate", address: "Hazelwood Estate, Yinnar, VIC, 3869" },
  { school: "Clarinda Primary School", campus: "Clarinda Primary School", address: "1166 Centre Road, Clarinda, VIC, 3169" },
  { school: "Clayton North Primary School", campus: "Clayton North Primary School", address: "1714 Dandenong Road, Clayton, VIC, 3168" },
  { school: "Clayton South Primary School", campus: "Clayton South Primary School", address: "539 Clayton Road, Clayton South, VIC, 3169" },
  { school: "Clifton Creek Primary School", campus: "Clifton Creek Primary School", address: "1020 Deptford Road, Clifton Creek, VIC, 3875" },
  { school: "Clifton Hill Primary School", campus: "Clifton Hill Primary School", address: "185 Gold St, Clifton Hill, VIC, 3068" },
  { school: "Clifton Springs Primary School", campus: "Clifton Springs Primary School", address: "84-118 Jetty Road, Clifton Springs, VIC, 3222" },
  { school: "Clunes Primary School", campus: "Clunes Primary School", address: "Cantebury Street, Clunes, VIC, 3370" },
  { school: "Clyde Creek Primary School ", campus: "Clyde Creek Primary School ", address: "40 Eliston Avenue, Clyde, VIC, 3978" },
  { school: "Clyde Primary School", campus: "Clyde Primary School", address: "13 Oroya Grove, Clyde, VIC, 3978" },
  { school: "Clyde Secondary College", campus: "Clyde Secondary College", address: "80 Woodfield Avenue, Clyde North, VIC, 3978" },
  { school: "Coatesville Primary School", campus: "Coatesville Primary School", address: "21 Mackie Road, Bentleigh East, VIC, 3165" },
  { school: "Cobains Primary School", campus: "Cobains Primary School", address: "3 Crofts Road, Cobains, VIC, 3851" },
  { school: "Cobden Primary School", campus: "Cobden Primary School", address: "Silvester Street, Cobden, VIC, 3266" },
  { school: "Cobden Technical School", campus: "Cobden Technical School", address: "Mckenzie Street, Cobden, VIC, 3266" },
  { school: "Cobram and District Specialist School", campus: "Cobram and District Specialist School", address: "4-8 Hume Street, Cobram, VIC, 3644" },
  { school: "Cobram Primary School", campus: "Cobram Primary School", address: "90-100 WILLIAM STREET, Cobram, VIC, 3644" },
  { school: "Cobram Secondary College", campus: "Cobram Secondary College", address: "25-47 Warkil Street. Cobram, VIC, 3644" },
  { school: "Coburg High School", campus: "Coburg High School", address: "101 Urquhart Street, Coburg, VIC, 3588" },
  { school: "Coburg North Primary School", campus: "Coburg North Primary School", address: "180 O'Hea Street, Coburg, VIC, 3058" },
  { school: "Coburg Primary School", campus: "Coburg Primary School", address: "92 Bell Street, Coburg, VIC, 3058" },
  { school: "Coburg Special Developmental School", campus: "Coburg Special Developmental School", address: "Cnr Bishop & Gaffney Streets, Coburg, VIC, 3058" },
  { school: "Coburg West Primary School", campus: "Coburg West Primary School", address: "185-187 Reynard Street, Coburg, VIC, 3058" },
  { school: "Coburn Primary School", campus: "Coburn Primary School", address: "26-42 Richard Road, Melton South, VIC, 3338" },
  { school: "Cockatoo Primary School", campus: "Cockatoo Primary School", address: "19-33 Belgrave-Gembrook Road, Cockatoo, VIC, 3781" },
  { school: "Cohuna Consolidated School", campus: "Cohuna Consolidated School", address: "Mead St, Cohuna, VIC, 3568" },
  { school: "Cohuna Secondary College", campus: "Cohuna Secondary College", address: "6415 Murray Valley Highway, Cohuna, VIC, 3568" },
  { school: "Coimadai Primary School", campus: "Coimadai Primary School", address: "86 Bennetts Lane, Coimadai, VIC, 3340" },
  { school: "Colac Primary School", campus: "Colac Primary School", address: "53-65 Murray Street East, Colac, VIC, 3250" },
  { school: "Colac Secondary College", campus: "Colac Secondary College", address: "173 Queen Street, Colac, VIC, 3250" },
  { school: "Colac South West Primary School", campus: "Colac South West Primary School", address: "238 Wilson Street, Colac, VIC, 3250" },
  { school: "Colac Specialist School", campus: "Colac Specialist School", address: "120A Wilson Street, Colac, VIC, 3250" },
  { school: "Colac West Primary School", campus: "Colac West Primary School", address: "12 Ligar Street, Colac, VIC, 3250" },
  { school: "Colbinabbin Primary School", campus: "Colbinabbin Primary School", address: "56 itchell Street, Colbinabbin, VIC, 3559" },
  { school: "Coldstream Primary School", campus: "Coldstream Primary School", address: "Kelso Street, Coldstream, VIC, 3770" },
  { school: "Coleraine Primary School", campus: "Coleraine Primary School", address: "124 Church Street, Coleraine, VIC, 3315" },
  { school: "Collingwood College", campus: "Collingwood College", address: "Cnr Cromwell St/McCutcheon Way, VIC, 3066" },
  { school: "Collingwood College", campus: "Collingwood Alternative School", address: "7 Stanley Street, Collingwood, VIC, 3066" },
  { school: "Collingwood English Language School", campus: "Broadmeadows Campus", address: "80 Johnstone Street, Broadmeadows, VIC, 3047" },
  { school: "Collingwood English Language School", campus: "Collingwood English Language School", address: "19 Cambridge Street, Collingwood, VIC, 3066" },
  { school: "Collingwood English Language School", campus: "Victoria Park Campus", address: "19 Cambridge Street, Collingwood, VIC, 3067" },
  { school: "Collingwood English Language School", campus: "Craigieburn Campus", address: "71 Northleigh Avenue, Craigieburn, VIC, 3064" },
  { school: "Concongella Primary School", campus: "Concongella Primary School", address: "61 Concongella School Road, Concongellla, VIC, 3384" },
  { school: "Concord School", campus: "Concord School", address: "411 Grimshaw Street, Bundoora, VIC, 3083" },
  { school: "Concord School", campus: "Concord Junior Campus", address: "Meagher Street, Watsonia, VIC, 3087" },
  { school: "Congupna Primary School", campus: "Congupna Primary School", address: "3580 Katamatite Road, Congupna, VIC, 3633" },
  { school: "Coolaroo South Primary School", campus: "Coolaroo South Primary School", address: "52-72 Bushfield Crescent, Coolaroo, VIC, 3048" },
  { school: "Copperfield College", campus: "Kings Park Junior Campus", address: "Kambalda Circuit, Kings Park, VIC, 3021" },
  { school: "Copperfield College", campus: "Delahey Senior Campus", address: "Goldsmith Avenue, Delahey, VIC, 3037" },
  { school: "Copperfield College", campus: "Sydenham Junior Campus", address: "Community House, Sydenham, VIC, 3037" },
  { school: "Coral Park Primary School", campus: "Coral Park Primary School", address: "145 Coral Drive, Hampton Park, VIC, 3976" },
  { school: "Corryong College", campus: "Corryong College", address: "27-45 Towong Road, Corryong, VIC, 3707" },
  { school: "Courtenay Gardens Primary School", campus: "Courtenay Gardens Primary School", address: "35 Rosebank Drive, Cranbourne North, VIC, 3977" },
  { school: "Cowes Primary School", campus: "Cowes Primary School", address: "177 Settlement Road, Cowes, VIC, 3922" },
  { school: "Cowwarr Primary School", campus: "Cowwarr Primary School", address: "Church Street, Cowwarr, VIC, 3857" },
  { school: "Craigieburn Primary School", campus: "Craigieburn Primary School", address: "87-91 Grand Boulevard, Craigeburn, VIC, 3064" },
  { school: "Craigieburn Secondary College", campus: "Craigieburn Secondary College", address: "102 Hothylyn Drive, Craigieburn, VIC, 3064" },
  { school: "Craigieburn South Primary School", campus: "Craigieburn South Primary School", address: "102 Hothylyn Drive, Craigieburn, VIC, 3064" },
  { school: "Cranbourne Carlisle Primary School", campus: "Cranbourne Carlisle Primary School", address: "15 Silky Oak Drive, Cranbourne, VIC, 3977" },
  { school: "Cranbourne East Primary School", campus: "Cranbourne East Primary School", address: "2 Bowyer Avenue, Cranbourne East, VIC, 3977" },
  { school: "Cranbourne East Secondary College", campus: "Cranbourne East Secondary College", address: "50 Stately Drive, Cranbourne East, VIC, 3977" },
  { school: "Cranbourne Park Primary School", campus: "Cranbourne Park Primary School", address: "1 Tucker Street, Cranbourne, VIC, 3977" },
  { school: "Cranbourne Primary School", campus: "Cranbourne Primary School", address: "Bakewell Street, Cranbourne, VIC, 3977" },
  { school: "Cranbourne Secondary College", campus: "Cranbourne Secondary College", address: "Stawell Street, Cranbourne, VIC, 3977" },
  { school: "Cranbourne South Primary School", campus: "Cranbourne South Primary School", address: "Pearcedale Road, Cranbourne South, VIC, 3977" },
  { school: "Cranbourne West Primary School", campus: "Cranbourne West Primary School", address: "110 Duff Street, Cranbourne, VIC, 3977" },
  { school: "Cranbourne West Secondary College", campus: "Cranbourne West Secondary College", address: "80 Strathlea Drive, Cranbourne West, VIC, 3977" },
  { school: "Creekside K-9 College", campus: "Creekside K-9 College", address: "9 The Crossing, Caroline Springs, VIC, 3023" },
  { school: "Creswick North Primary School", campus: "Creswick North Primary School", address: "93 Macs Street, Creswick, VIC, 3363" },
  { school: "Creswick Primary School", campus: "Creswick Primary School", address: "67 Napier Street, Creswick, VIC, 3363" },
  { school: "Crib Point Primary School", campus: "Crib Point Primary School", address: "70 Milne Street, Crib Point, VIC, 3919" },
  { school: "Croxton Special School", campus: "Croxton Special School", address: "159-165 Beaconsfield Parade, Northcote, VIC, 3070" },
  { school: "Croxton Special School", campus: "Transition Learning Centre", address: "238-298 Colllins Street, Thornbury, VIC, 3071" },
  { school: "Croydon Community School", campus: "Croydon Community School", address: "177-181 Mt Dandenong Road, Croydon, VIC, 3136" },
  { school: "Croydon Community School", campus: "OPTIONS@Bayswater", address: "29 Neal Street, Bayswater, VIC, 3153" },
  { school: "Croydon Community School", campus: "Capacity Building", address: "Cnr Strada Cres & Academy Ave, Wheelers Hill, VIC, 3150" },
  { school: "Croydon Community School", campus: "Woori Yallock Farm School Campus", address: "30 Church Road, Woori Yallock, VIC, 3139" },
  { school: "Croydon Hills Primary School", campus: "Croydon Hills Primary School", address: "Campaspe Drive, Croydon Hills, VIC, 3136" },
  { school: "Croydon Primary School", campus: "Croydon Primary School", address: "Cnr Kent Avenue & Croydon Road, Croydon, VIC, 3136" },
  { school: "Croydon Special Developmental School", campus: "Croydon Special Developmental School", address: "71-95 Belmont Road East, Croydon South, VIC, 3136" },
  { school: "Crusoe 7-10 Secondary College", campus: "Crusoe 7-10 Secondary College", address: "57-75 Olympic Parade, Kangaroo Flat, VIC, 3555" },
  { school: "Cudgee Primary School", campus: "Cudgee Primary School", address: "3 Hallowells Road, Cudgee, VIC, 3265" },
  { school: "Currawa Primary School", campus: "Currawa Primary School", address: "2 Ridge Road, Dookie Campus, VIC, 3647" },
  { school: "Dallas Brooks Community Primary School", campus: "Dallas Brooks Community Primary School", address: "23-36 King Street, Dallas, VIC, 3047" },
  { school: "Dandenong High School", campus: "Dandenong Campus", address: "92-106 Princes Highway, Dandenong, VIC, 3175" },
  { school: "Dandenong High School", campus: "Cleeland Campus", address: "23 Ann Street, Dandenong, VIC, 3175" },
  { school: "Dandenong High School", campus: "Myuna Secondary Teaching Unit", address: "182 Kidds Road, Doveton, VIC, 3177" },
  { school: "Dandenong North Primary School", campus: "Dandenong North Primary School", address: "117-125 Cleeland Street, Dandenong, VIC, 3175" },
  { school: "Dandenong Primary School", campus: "Dandenong Primary School", address: "174-182 Foster Street, Dandenong, VIC, 3175" },
  { school: "Dandenong South Primary School", campus: "Dandenong South Primary School", address: "52 Kirkham Road, Dandenong, VIC, 3175" },
  { school: "Dandenong Valley Special Developmental School", campus: "Dandenong Valley Special Developmental School", address: "19-21 Victoria Road, Narre Warren, VIC, 3805" },
  { school: "Dandenong West Primary School", campus: "Dandenong West Primary School", address: "32 Birdwood Avenue, Dandenong, VIC, 3175" },
  { school: "Darley Primary School", campus: "Darley Primary School", address: "21 Nelson Street, Darley, VIC, 3340" },
  { school: "Darnum Primary School", campus: "Darnum Primary School", address: "6 Graham Street, Darnum, VIC, 3822" },
  { school: "Darraweit Guim Primary School", campus: "Darraweit Guim Primary School", address: "2 Darraweit Valley Road, Darraweit Guim, VIC, 3756" },
  { school: "Dartmoor Primary School", campus: "Dartmoor Primary School", address: "76 Greenham Street, Dartmoor, VIC, 3304" },
  { school: "Davis Creek Primary School", campus: "Davis Creek Primary School", address: "65 Wootten Road, Tarneit, VIC, 3029" },
  { school: "Daylesford Primary School", campus: "Daylesford Primary School", address: "102-128 Vincent Street, Dylesford, VIC, 3460" },
  { school: "Daylesford Secondary College", campus: "Daylesford Secondary College", address: "39 Smith Street, Daylesford, VIC, 3460" },
  { school: "Deans Marsh Primary School", campus: "Deans Marsh Primary School", address: "30 Deans Marsh-Lorne Road, Deans Marsh, VIC, 3235" },
  { school: "Deanside Primary School", campus: "Deanside Primary School", address: "38 Conservatory Drive, Deanside, VIC, 3336" },
  { school: "Debney Meadows Primary School", campus: "Debney Meadows Primary School", address: "100 Victoria Street, Flemington, VIC, 3031" },
  { school: "Dederang Primary School", campus: "Dederang Primary School", address: "4364 Kiewa Valley Highway, Dederang, VIC, 3691" },
  { school: "Deepdene Primary School", campus: "Deepdene Primary School", address: "958a Burke Road, Deepdene, VIC, 3103" },
  { school: "Deer Park North Primary School", campus: "Deer Park North Primary School", address: "18-36 Mawson Avenue, Deer Park, VIC, 3023" },
  { school: "Deer Park West Primary School", campus: "Deer Park West Primary School", address: "Quinn Street, Deer Park, VIC, 3023" },
  { school: "Delacombe Primary School", campus: "Delacombe Primary School", address: "110-120 Greenhalghs Road, Delacombe, VIC, 3356" },
  { school: "Derinya Primary School", campus: "Derinya Primary School", address: "Overport Road, Frankston South, VIC, 3199" },
  { school: "Derrimut Primary School", campus: "Derrimut Primary School", address: "40 Lennon Parkway, Derrimut, VIC, 3026" },
  { school: "Derrinallum P-12 College", campus: "Derrinallum P-12 College", address: "Campbell Street, Derrinallum, VIC, 3325" },
  { school: "Devenish Primary School", campus: "Devenish Primary School", address: "76 Main Road, Devenish, VIC, 3726" },
  { school: "Devon Meadows Primary School", campus: "Devon Meadows Primary School", address: "Worthing Road, Devon Meadows, VIC, 3977" },
  { school: "Dhurringile Primary School", campus: "Dhurringile Primary School", address: "605 Langham Road, Dhurringile, VIC, 3610" },
  { school: "Diamond Creek East Primary School", campus: "Diamond Creek East Primary School", address: "129-163 Main Hurstbridge Road, Diamond Creek, VIC, 3089" },
  { school: "Diamond Creek Primary School", campus: "Diamond Creek Primary School", address: "17 Clyde Street, Diamond Creek, VIC, 3089" },
  { school: "Diamond Valley College", campus: "Diamond Valley College", address: "165-179 Main Hurstbridge Road, VIC, 3089" },
  { school: "Diamond Valley Special Developmental School", campus: "Diamond Valley Special Developmental School", address: "14-24 Brentwick Drive, Greensborough, VIC, 3088" },
  { school: "Diamond Valley Special Developmental School", campus: "Whittlesea Campus", address: "76 Laurel st, Whittlesea, VIC, 3757" },
  { school: "Diggers Rest Primary School", campus: "Diggers Rest Primary School", address: "Plumpton Road, Diggers Rest, VIC, 3427" },
  { school: "Dimboola Memorial Secondary College", campus: "Dimboola Memorial Secondary College", address: "66 Ellerman Street, Dimboola, VIC, 3414" },
  { school: "Dimboola Primary School", campus: "Dimboola Primary School", address: "Hindmarsh Street, Dimboola, VIC, 3414" },
  { school: "Dingley Primary School", campus: "Dingley Primary School", address: "111-115 Centre Dandenong Road, Dingley Village, VIC, 3172" },
  { school: "Dinjerra Primary School", campus: "Dinjerra Primary School", address: "44 South Road, Braybook, VIC, 3019" },
  { school: "Dixons Creek Primary School", campus: "Dixons Creek Primary School", address: "1815 Melba highway, Dixon Creek, VIC, 3755" },
  { school: "Docklands Primary School", campus: "Docklands Primary School", address: "17 Little Docklands Drive, Docklands, VIC, 3008" },
  { school: "Dohertys Creek P-9 College", campus: "Dohertys Creek P-9 College", address: "60 Elmhurst Road, Truganina, VIC, 3029" },
  { school: "Don Valley Primary School", campus: "Don Valley Primary School", address: "Old Dalry Road, Don Valley, VIC, 3139" },
  { school: "Donald High School", campus: "Donald High School", address: "2-16 Camp Street, Donald, VIC, 3480" },
  { school: "Donald Primary School", campus: "Donald Primary School", address: "Walker Street, Donald, VIC, 3480" },
  { school: "Doncaster Gardens Primary School", campus: "Doncaster Gardens Primary School", address: "Sandhurst Avenue, Doncaster East, VIC, 3109" },
  { school: "Doncaster Primary School", campus: "Doncaster Primary School", address: "2-12 Council Street, Doncaster, VIC, 3108" },
  { school: "Doncaster Secondary College", campus: "Doncaster Secondary College", address: "123 Church Road, Doncaster, VIC, 3108" },
  { school: "Donvale Primary School", campus: "Donvale Primary School", address: "Elata Street, Donvale, VIC, 3111" },
  { school: "Dookie Primary School", campus: "Dookie Primary School", address: "Baldock Street, Dookie, VIC, 3646" },
  { school: "Doreen Primary School", campus: "Doreen Primary School", address: "75 Doctors Gully Road, Doreen, VIC, 3754" },
  { school: "Dorset Primary School", campus: "Dorset Primary School", address: "1-11 Rescorla Avenue, Croydon, VIC, 3136" },
  { school: "Doveton College", campus: "Doveton College", address: "62 Tristania Street, Doveton, VIC, 3177" },
  { school: "Dromana Primary School", campus: "Dromana Primary School", address: "Mcculloch Street, Dromana, VIC, 3936" },
  { school: "Dromana Secondary College", campus: "Dromana Secondary College", address: "110 Harrisons Road, Dromana, VIC, 3936" },
  { school: "Drouin Primary School", campus: "Drouin Primary School", address: "153-161 Princes Way, Drouin, VIC, 3818" },
  { school: "Drouin Secondary College", campus: "Drouin Secondary College", address: "South Road, Drouin, VIC, 3818" },
  { school: "Drouin Secondary College", campus: "Blackwood Annexe", address: "Brock Road, Hallora, VIC, 3818" },
  { school: "Drouin South Primary School", campus: "Drouin South Primary School", address: "3061 Westernport Road, Drouin South, VIC, 3818" },
  { school: "Drouin West Primary School", campus: "Drouin West Primary School", address: "535 Main Neerim Road, Drouin West, VIC, 3818" },
  { school: "Drummond Primary School", campus: "Drummond Primary School", address: "9 Lauriston Road, Drummond, VIC, 3461" },
  { school: "Drysdale Primary School", campus: "Drysdale Primary School", address: "52-66 Clifton Springs Rd, Drysdale, VIC, 3222" },
  { school: "Dunkeld Consolidated School", campus: "Dunkeld Consolidated School", address: "93 Victoria Valley Road, Dunkeld, VIC, 3294" },
  { school: "Dunolly Primary School", campus: "Dunolly Primary School", address: "8 Eligin Street, Dunolly, VIC, 3472" },
  { school: "Eagle Point Primary School", campus: "Eagle Point Primary School", address: "43 School Road, Eagle Point, VIC, 3878" },
  { school: "Eaglehawk North Primary School", campus: "Eaglehawk North Primary School", address: "1-9 Bendigo-Pyramid Road, eaglehawk, VIC, 3566" },
  { school: "Eaglehawk Primary School", campus: "Eaglehawk Primary School", address: "Church St, Eaglehawk, VIC, 3556" },
  { school: "Eaglehawk Secondary College", campus: "Eaglehawk Secondary College", address: "1-3 Rserve Street, Eaglehawk, VIC, 3556" },
  { school: "East Bentleigh Primary School", campus: "East Bentleigh Primary School", address: "Biignell Road, East Bentleigh, VIC, 3566" },
  { school: "East Doncaster Secondary College", campus: "East Doncaster Secondary College", address: "20 George Street, Doncaster East, VIC, 3109" },
  { school: "East Gippsland Specialist School", campus: "East Gippsland Specialist School", address: "31 Calvert Street, Bairnsdale, VIC, 3875" },
  { school: "East Loddon P-12 College", campus: "East Loddon P-12 College", address: "Dingee Road, Dingee, VIC, 3571" },
  { school: "Eastbourne Primary School", campus: "Eastbourne Primary School", address: "Allabami Avenue, Capel Sound, VIC, 3940" },
  { school: "Eastern Ranges School", campus: "Eastern Ranges School", address: "56 Dorset Road, Ferntree Gully, VIC, 3156" },
  { school: "Eastwood Primary School", campus: "Eastwood Primary School", address: "Alexandra Road, Ringwood East, VIC, 3135" },
  { school: "Eastwood Primary School", campus: "Eastwood Deaf Facility", address: "Alexandra Road, Ringwood East, VIC, 3135" },
  { school: "Echuca College", campus: "Echuca College", address: "20-50 Butcher Street, Echuca, VIC, 3564" },
  { school: "Echuca East Primary School", campus: "Echuca East Primary School", address: "21-31 Eyre Street, Echuca, VIC, 3564" },
  { school: "Echuca Primary School", campus: "Echuca Primary School", address: "413-431 High Street, Echuca, VIC, 3564" },
  { school: "Echuca Specialist School", campus: "Echuca Specialist School", address: "High Street South, Echuca, VIC, 3564" },
  { school: "Echuca Twin Rivers Primary School", campus: "Echuca Twin Rivers Primary School", address: "66 Wilkinson Drive, Echuca, VIC, 3564" },
  { school: "Edenbrook Secondary College", campus: "Edenbrook Secondary College", address: "133 Henry Road, Pakenham, VIC, 3810" },
  { school: "Edenhope College", campus: "Edenhope College", address: "40-48 Lake Street, Edenhope, VIC, 3318" },
  { school: "Edgars Creek Primary School", campus: "Edgars Creek Primary School", address: "45 Macedon Parade, Wollert, VIC, 3750" },
  { school: "Edgars Creek Secondary College", campus: "Edgars Creek Secondary College", address: "2 Steen Avenue, Wollert, VIC, 3750" },
  { school: "Edi Upper Primary School", campus: "Edi Upper Primary School", address: "832 Edi-Chestnut Road, Edi Upper, VIC, 3678" },
  { school: "Edithvale Primary School", campus: "Edithvale Primary School", address: "42-54 Edithvale Road, Edithvale, VIC, 3196" },
  { school: "Elevation Secondary College", campus: "Elevation Secondary College", address: "199 Elevation Boulevard, Craigieburn, VIC, 3064" },
  { school: "Elisabeth Murdoch College", campus: "Elisabeth Murdoch College", address: "80 Warrandyte Road, Langwarrin, VIC, 3910" },
  { school: "Elliminyt Primary School", campus: "Elliminyt Primary School", address: "135 Slater Street, Elliminyt, VIC, 3249" },
  { school: "Ellinbank Primary School", campus: "Ellinbank Primary School", address: "13 Community Place, Ellinbank, VIC, 3821" },
  { school: "Elmhurst Primary School", campus: "Elmhurst Primary School", address: "48 Byerly Street, Elmhurst, VIC, 3469" },
  { school: "Elmore Primary School", campus: "Elmore Primary School", address: "36 Michie Street, Elmore, VIC, 3558" },
  { school: "Elphinstone Primary School", campus: "Elphinstone Primary School", address: "26 Wright Street, Elphinstone, VIC, 3448" },
  { school: "Elsternwick Primary School", campus: "Elsternwick Primary School", address: "Murphy Street, Brighton, VIC, 3186" },
  { school: "Eltham East Primary School", campus: "Eltham East Primary School", address: "16 Grove Street, Eltham, VIC, 3095" },
  { school: "Eltham High School", campus: "Eltham High School", address: "30-60 Winters Way, Eltham, VIC, 3095" },
  { school: "Eltham North Primary School", campus: "Eltham North Primary School", address: "35-47 Wattletree Road, Eltham North, VIC, 3095" },
  { school: "Eltham Primary School", campus: "Eltham Primary School", address: "Dalton Street, Eltham, VIC, 3095" },
  { school: "Elwood College", campus: "Elwood College", address: "101 Glenhuntly Road, Elwood, VIC, 3184" },
  { school: "Elwood Primary School", campus: "Elwood Primary School", address: "Scott Street, Elwood, VIC, 3184" },
  { school: "Emerald Primary School", campus: "Emerald Primary School", address: "10 Heroes Avenue, Emerald, VIC, 3782" },
  { school: "Emerald Secondary College", campus: "Emerald Secondary College", address: "425 Belgrave-gembrook Rd, Emerald, VIC, 3782" },
  { school: "Emerson School", campus: "Emerson School", address: "1430 Heatherton Road, Dandenong, VIC, 3175" },
  { school: "Emerson School", campus: "Emerson Middle campus", address: "19-21 Gloria Avenue, Dandenong North, VIC, 3175" },
  { school: "Endeavour Hills Specialist School", campus: "Endeavour Hills Specialist School", address: "12-38 Amalfi Drive, Endeavour Hills, VIC, 3802" },
  { school: "Eppalock Primary School", campus: "Eppalock Primary School", address: "149 Patons Road, Axe Creek, VIC, 3551" },
  { school: "Epping Primary School", campus: "High Street Campus", address: "805 High Street, Epping, VIC, 3076" },
  { school: "Epping Primary School", campus: "Greenbrook Campus", address: "66 Peppercorn Parade, Epping, VIC, 3076" },
  { school: "Epping Secondary College", campus: "Epping Secondary College", address: "Mcdonalds Road, Epping, VIC, 3076" },
  { school: "Epping Views Primary School", campus: "Epping Views Primary School", address: "20 Mansfield Street, Epping, VIC, 3076" },
  { school: "Epsom Primary School", campus: "Epsom Primary School", address: "Howard Street, Epsom, VIC, 3551" },
  { school: "Eskdale Primary School", campus: "Eskdale Primary School", address: "3790 Omeo Highway, Eskadale, VIC, 3701" },
  { school: "Essendon Keilor College", campus: "Senior Campus", address: "Etty Street, Castlemaine, VIC, 3450" },
  { school: "Essendon Keilor College", campus: "East Keilor Campus", address: "Quinn Grove, Keilor East, VIC, 3033" },
  { school: "Essendon Keilor College", campus: "Niddrie Campus", address: "19 Peters Street, Niddrie, VIC, 3042" },
  { school: "Essendon North Primary School", campus: "Essendon North Primary School", address: "112 Keilor Road, Essendon North, VIC, 3041" },
  { school: "Essendon Primary School", campus: "Essendon Primary School", address: "38 Raleigh Street, Essendon, VIC, 3040" },
  { school: "Essex Heights Primary School", campus: "Essex Heights Primary School", address: "22-30 Essex Road, Mount Waverely, VIC, 3149" },
  { school: "Euroa Primary School", campus: "Euroa Primary School", address: "Clifton Street, Euroa, VIC, 3666" },
  { school: "Euroa Secondary College", campus: "Euroa Secondary College", address: "26 Campbell St, Euroa, VIC, 3666" },
  { school: "Everton Primary School", campus: "Everton Primary School", address: "2157 Great Alpine Road, Everton, VIC, 3678" },
  { school: "Exford Primary School", campus: "Exford Primary School", address: "1137 Exford Road, Exford, VIC, 3338" },
  { school: "Eynesbury Primary School", campus: "Eynesbury Primary School", address: "756 Eynesbury Road, Eynesbury, VIC, 3338" },
  { school: "Fairfield Primary School", campus: "Fairfield Primary School", address: "Langridge Street, Fairfield, VIC, 3078" },
  { school: "Fairhills High School", campus: "Fairhills High School", address: "Scoresby Road, Knoxfield, VIC, 3180" },
  { school: "Fairhills Primary School", campus: "Fairhills Primary School", address: "Manuka Drive, Ferntree Gully, VIC, 3156" },
  { school: "Falls Creek Primary School", campus: "Falls Creek Primary School", address: "15 Slalom Street, Falls Creek, VIC, 3699" },
  { school: "Fawkner Primary School", campus: "Fawkner Primary School", address: "Lorne Street, Fawkner, VIC, 3060" },
  { school: "Featherbrook P-9 College", campus: "Featherbrook P-9 College", address: "281 Boardwalk Boulevard, Point Cook, VIC, 3030" },
  { school: "Ferntree Gully North Primary School", campus: "Ferntree Gully North Primary School", address: "111 Forest Road, Ferntree Gully, VIC, 3156" },
  { school: "Ferny Creek Primary School", campus: "Ferny Creek Primary School", address: "School Road, Ferny Creek, VIC, 3786" },
  { school: "Findon Primary School", campus: "Findon Primary School", address: "Cuthbert Drive, Mill Park, VIC, 3082" },
  { school: "Fish Creek and District Primary School", campus: "Fish Creek and District Primary School", address: "10 Foster Road, Fish Creek, VIC, 3959" },
  { school: "Fitzroy High School", campus: "Wurun Senior Campus", address: "111 Queens Parade, Fitzroy North, VIC, 3068" },
  { school: "Fitzroy High School", campus: "Fitzroy High School", address: "Falconer Street, Fitzroy North, VIC, 3068" },
  { school: "Fitzroy North Primary School", campus: "Fitzroy North Primary School", address: "3 Fergie Street, Fitzroy North, VIC, 3068" },
  { school: "Fitzroy Primary School", campus: "Fitzroy Primary School", address: "319 George Street, Fitzroy, VIC, 3065" },
  { school: "Fleetwood Primary School", campus: "Fleetwood Primary School", address: "97 Fleetwood Drive, Narre Warren, VIC, 3805" },
  { school: "Flemington Primary School", campus: "Flemington Primary School", address: "Cnr Mt Alexander Rd & Padman Lane, Flemington, VIC, 3031" },
  { school: "Flowerdale Primary School", campus: "Flowerdale Primary School", address: "3377 Whittlesea Yea Road, Flowerdale, VIC, 3717" },
  { school: "Footscray City Primary School", campus: "Footscray City Primary School", address: "10 Hyde Street, Footscray, VIC, 3011" },
  { school: "Footscray High School", campus: "Kinnear Street Campus", address: "1 Kinnear Street, Footscray, VIC, 3011" },
  { school: "Footscray High School", campus: "Barkly Street Campus", address: "41-55 Barkly Street, Benalla, VIC, 3672" },
  { school: "Footscray High School", campus: "Pilgrim Campus", address: "1 Piligrim Street, Seddon, VIC, 3011" },
  { school: "Footscray North Primary School", campus: "Footscray North Primary School", address: "14 Rosamond Road, Footscrat, VIC, 3011" },
  { school: "Footscray Primary School", campus: "Footscray Primary School", address: "Cnr Geelong Road & Barkly St, Footscray, VIC, 3011" },
  { school: "Footscray West Primary School", campus: "Footscray West Primary School", address: "Argyle Street, West Footscray, VIC, 3012" },
  { school: "Forest Hill College", campus: "Forest Hill College", address: "178-180 Mahoneys Road, Burwood East, VIC, 3151" },
  { school: "Forest Hill College", campus: "Forest Hill Deaf Facility", address: "178-180 Mahoneys Road, Burwood East, VIC, 3151" },
  { school: "Forest Street Primary School", campus: "Forest Street Primary School", address: "Cnr Forest & Hancock Streets, Wendouree, VIC, 3355" },
  { school: "Forest Street Primary School", campus: "Forest Street Deaf Facility", address: "Cnr Forest & Hancock Streets, Wendouree, VIC, 3355" },
  { school: "Forrest Primary School", campus: "Forrest Primary School", address: "10 Grant Street, Forrest, VIC, 3236" },
  { school: "Foster Primary School", campus: "Foster Primary School", address: "75 Pioneer Street, Foster, VIC, 3960" },
  { school: "Foster Secondary College", campus: "Foster Secondary College", address: "2-10 Pioneer Street, Foster, VIC, 3960" },
  { school: "Fountain Gate Primary School", campus: "Fountain Gate Primary School", address: "7-21 Prospect Hill Road, VIC, 3805" },
  { school: "Fountain Gate Secondary College", campus: "Fountain Gate Secondary College", address: "45 Josephine Avenue, Narre Warren, VIC, 3805" },
  { school: "Frankston East Primary School", campus: "Frankston East Primary School", address: "6A Ashleigh Avenue, Frankston, VIC, 3199" },
  { school: "Frankston Heights Primary School", campus: "Frankston Heights Primary School", address: "10 Kalmia Street, Frankston, VIC, 3199" },
  { school: "Frankston High School", campus: "7-10 Campus", address: "97 Foot Street, Frankston, VIC, 3199" },
  { school: "Frankston High School", campus: "VCE Campus", address: "48 Foot Street, Frankston, VIC, 3199" },
  { school: "Frankston Primary School", campus: "Frankston Primary School", address: "Davey Street, Frankston, VIC, 3199" },
  { school: "Frankston Special Developmental School", campus: "Frankston Special Developmental School", address: "Sassafras Drive, Frankton, VIC, 3199" },
  { school: "Frankston Special Developmental School", campus: "Blackwood Special Schools Outdoor Education Centre", address: "1015 Greeendale-Trentham road, Blackwood, VIC, 3458" },
  { school: "Furlong Park School For Deaf Children", campus: "Furlong Park School For Deaf Children", address: "58-84 Furlong Road, Sunshine North, VIC, 3020" },
  { school: "Fyans Park Primary School", campus: "Fyans Park Primary School", address: "37 Cook Street, Newton, VIC, 3220" },
  { school: "Gaayip-Yagila Primary School", campus: "Gaayip-Yagila Primary School", address: "115 Blackmore Road, Mickleham, VIC, 3064" },
  { school: "Gardenvale Primary School", campus: "Gardenvale Primary School", address: "66 Landcox Street, Brighton East, VIC, 3187" },
  { school: "Garfield Primary School", campus: "Garfield Primary School", address: "84 Railway Avenue,  Garfield, VIC, 3814" },
  { school: "Garrang Wilam Primary School", campus: "Garrang Wilam Primary School", address: "2 Canvas Street, Truganina, VIC, 3029" },
  { school: "Geelong East Primary School", campus: "Geelong East Primary School", address: "184 Boundary Road, Geelong East, VIC, 3219" },
  { school: "Geelong High School", campus: "Geelong High School", address: "385 Ryrie Street, East Geelong, VIC, 3219" },
  { school: "Geelong South Primary School", campus: "Geelong South Primary School", address: "200 Yarra Street, South Geelong, VIC, 3220" },
  { school: "Gembrook Primary School", campus: "Gembrook Primary School", address: "50 Main Street, Gembrook, VIC, 3783" },
  { school: "George Street Primary School - Hamilton", campus: "George Street Primary School - Hamilton", address: "32-48 George Street, Hamilton, VIC, 3300" },
  { school: "Girgarre Primary School", campus: "Girgarre Primary School", address: "528 Winter Road, Girgarre, VIC, 3624" },
  { school: "Gisborne Primary School", campus: "Gisborne Primary School", address: "34 Fisher Street, Gisborne, VIC, 3437" },
  { school: "Gisborne Secondary College", campus: "Gisborne Secondary College", address: "95 Melton Road, Gisborne, VIC, 3437" },
  { school: "Gladesville Primary School", campus: "Gladesville Primary School", address: "48 Gladesville Drive, Kilsyth, VIC, 3137" },
  { school: "Gladstone Park Primary School", campus: "Gladstone Park Primary School", address: "15-25 South Circular Road, Gladstone, VIC, 3043" },
  { school: "Gladstone Park Secondary College", campus: "Gladstone Park Secondary College", address: "14-36 Taylor Drive, Gladstone Park, VIC, 3043" },
  { school: "Gladstone Views Primary School", campus: "Gladstone Views Primary School", address: "195-227 Carrick Drive, Goldstone Park, VIC, 3043" },
  { school: "Gladysdale Primary School", campus: "Gladysdale Primary School", address: "550 Little Yarra Road, Gladysdale, VIC, 3797" },
  { school: "Glen Eira College", campus: "Glen Eira College", address: "76 Booran Road, Caufield East, VIC, 3145" },
  { school: "Glen Eira College", campus: "Glen Eira English Language Centre", address: "76 Booran Road, Caufield East, VIC, 3145" },
  { school: "Glen Huntly Primary School", campus: "Glen Huntly Primary School", address: "Grange Road, Glent Huntly, VIC, 3163" },
  { school: "Glen Iris Primary School", campus: "Glen Iris Primary School", address: "170 Glen Iris Road, Glen Iris, VIC, 3136" },
  { school: "Glen Katherine Primary School", campus: "Glen Katherine Primary School", address: "39 Calendonia Drive, Eltham North, VIC, 3095" },
  { school: "Glen Park Primary School", campus: "Glen Park Primary School", address: "265 Ralstons Road, Glen Park, VIC, 3352" },
  { school: "Glen Waverley Primary School", campus: "Glen Waverley Primary School", address: "774 High Street, Glen Waverly, VIC, 3150" },
  { school: "Glen Waverley Secondary College", campus: "Glen Waverley Secondary College", address: "13-21 O'Sullivan Road, VIC, 3150" },
  { school: "Glen Waverley South Primary School", campus: "Glen Waverley South Primary School", address: "Whites Lane, Glen Waverely, VIC, 3150" },
  { school: "Glenallen School", campus: "Glenallen School", address: "7 Allen Street, Glen Wavereley, VIC, 3150" },
  { school: "Glendal Primary School", campus: "Glendal Primary School", address: "55 Nottingham Street, Glen Waverely, VIC, 3150" },
  { school: "Gleneagles Secondary College", campus: "Gleneagles Secondary College", address: "58 Reema Boulevard, Endeavour Hills, VIC, 3802" },
  { school: "Glenferrie Primary School", campus: "Glenferrie Primary School", address: "78-98 Manningtree Road, Hawthirn, VIC, 3122" },
  { school: "Glengala Primary School", campus: "Glengala Primary School", address: "Kermeen Street, Sunshine West, VIC, 3020" },
  { school: "Glengarry Primary School", campus: "Glengarry Primary School", address: "Cairnbrook Road, Glengarry, VIC, 3854" },
  { school: "Glenrowan Primary School", campus: "Glenrowan Primary School", address: "40 Beaconsfield Parade, Glenrowan, VIC, 3675" },
  { school: "Glenroy Central Primary School", campus: "Glenroy Central Primary School", address: "10 Logan Street, Glenroy, VIC, 3046" },
  { school: "Glenroy Secondary College", campus: "Glenroy Secondary College", address: "120 Glenroy Road, Glenroy, VIC, 3046" },
  { school: "Glenroy Specialist School", campus: "Glenroy Specialist School", address: "208 Hilton St, Glenroy, VIC, 3046" },
  { school: "Glenroy West Primary School", campus: "Glenroy West Primary School", address: "York Street, Glenroy, VIC, 3046" },
  { school: "Golden Square Primary School", campus: "Golden Square Primary School", address: "19-31 Maple Street, Golden Square, VIC, 3555" },
  { school: "Goonawarra Primary School", campus: "Goonawarra Primary School", address: "3-6 Gullane Drive, Sunbury, VIC, 3249" },
  { school: "Goornong Primary School", campus: "Goornong Primary School", address: "26 Grant Street, Goornong, VIC, 3557" },
  { school: "Gordon Primary School", campus: "Gordon Primary School", address: "1 Dicker Street, Gordon, VIC, 3345" },
  { school: "Gormandale And District Primary School", campus: "Gormandale And District Primary School", address: "38 Main Street, Gormandale, VIC, 3873" },
  { school: "Goroke P-12 College", campus: "Goroke P-12 College", address: "55 Natimuk-Frances Road, Goroke, VIC, 3412" },
  { school: "Gowrie Street Primary School Shepparton", campus: "Gowrie Street Primary School Shepparton", address: "1-19 Gowrie Street, Shepparton, VIC, 3630" },
  { school: "Gowrie Street Primary School Shepparton", campus: "Manega Koorie Annexe", address: "Cnr Gowrie St & Balaclava Rd, Shepparton, VIC, 3630" },
  { school: "Grahamvale Primary School", campus: "Grahamvale Primary School", address: "85 Grahamvale Road, Grahamvale, VIC, 3631" },
  { school: "Grasmere Primary School", campus: "Grasmere Primary School", address: "18 Ibbs Lane, Grasmere, VIC, 3281" },
  { school: "Grayling Primary School", campus: "Grayling Primary School", address: "25 Ferdinand Drive, Clyde North, VIC, 3978" },
  { school: "Great Ryrie Primary School", campus: "Great Ryrie Primary School", address: "Great Ryrie Street, Heathmont, VIC, 3135" },
  { school: "Great Western Primary School", campus: "Great Western Primary School", address: "Stephenson Street, Great Western, VIC, 3374" },
  { school: "Greater Shepparton Secondary College", campus: "Mooroopna Campus", address: "141-179 Echua Road, Mooroopna, VIC, 3629" },
  { school: "Greater Shepparton Secondary College", campus: "Greater Shepparton Secondary College Deaf Facility", address: "1-19 Parkside Drive, Shepparton, VIC, 3630" },
  { school: "Greater Shepparton Secondary College", campus: "Wanganui Park Campus", address: "1-19 Parkside Drive, Shepparton, VIC, 3630" },
  { school: "Greater Shepparton Secondary College", campus: "McGuire Campus", address: "92-100 Wilmort Road, Shepparton, VIC, 3630" },
  { school: "Greenhills Primary School", campus: "Greenhills Primary School", address: "Mine Street, Greensborough, VIC, 3088" },
  { school: "Greensborough Primary School", campus: "Greensborough Primary School", address: "130 Grimshaw Street, Greensborough, VIC, 3088" },
  { school: "Greensborough Secondary College", campus: "Greensborough Secondary College", address: "Nell Street, Greensborough, VIC, 3088" },
  { school: "Greensborough Secondary College", campus: "Barjarg Lodge", address: "Harpers Road, Barjarg, VIC, 3722" },
  { school: "Greenvale Primary School", campus: "Greenvale Primary School", address: "12-24 Bradford Avenue, Greenvale, VIC, 3059" },
  { school: "Greta Valley Primary School", campus: "Greta Valley Primary School", address: "2376 Wangaratta Kifeera Road, Greta South, VIC, 3675" },
  { school: "Grey Street Primary School (Traralgon)", campus: "Grey Street Primary School (Traralgon)", address: "30-44 Grey Street, Traralgon, VIC, 3844" },
  { school: "Greythorn Primary School", campus: "Greythorn Primary School", address: "Reading Avenue, Balwyn North, VIC, 3104" },
  { school: "Grovedale College", campus: "Grovedale College", address: "19 Wingarra Drive, Grovedale, VIC, 3216" },
  { school: "Grovedale Primary School", campus: "Grovedale Primary School", address: "143 Bailey Street, Grovedale, VIC, 3216" },
  { school: "Grovedale West Primary School", campus: "Grovedale West Primary School", address: "85 Heyers Road, Grovedale, VIC, 3216" },
  { school: "Grovedale West Primary School", campus: "Grovedale West Deaf Facility", address: "85 Heyers Road, Grovedale, VIC, 3216" },
  { school: "Gruyere Primary School", campus: "Gruyere Primary School", address: "99 Killara Road, Gruyere, VIC, 3770" },
  { school: "Gunbower Primary School", campus: "Gunbower Primary School", address: "School Road, Gunbower, VIC, 3566" },
  { school: "Guthridge Primary School", campus: "Guthridge Primary School", address: "Dawson Street, Sale, VIC, 3850" },
  { school: "Guthrie Street Primary School", campus: "Guthrie Street Primary School", address: "33-69 Guthrie Street, Shepparton, VIC, 3630" },
  { school: "Guthrie Street Primary School", campus: "Shepparton Deaf Facility", address: "Guthrie Street, Shepparton, VIC, 3630" },
  { school: "Haddon Primary School", campus: "Haddon Primary School", address: "398 Sago Hill Road, Haddon, VIC, 3351" },
  { school: "Hallam Primary School", campus: "Hallam Primary School", address: "24 Harmer Road, Hallam, VIC, 3803" },
  { school: "Hallam Senior Secondary College", campus: "Hallam Senior Secondary College", address: "74-84 Frawley Road, Hallam, VIC, 3803" },
  { school: "Halls Gap Primary School", campus: "Halls Gap Primary School", address: "10-14 School Road, Halls Gap, VIC, 3381" },
  { school: "Hamilton (Gray Street) Primary School", campus: "Hamilton (Gray Street) Primary School", address: "42 Gray Street, Hamilton, VIC, 3300" },
  { school: "Hamilton North Primary School", campus: "Hamilton North Primary School", address: "34-50 Andrews Street, Hamilton, VIC, 3300" },
  { school: "Hamilton Parklands School", campus: "Hamilton Parklands School", address: "15 Kerr Street, Hamilton, VIC, 3300" },
  { school: "Hamlyn Banks Primary School", campus: "Hamlyn Banks Primary School", address: "2-24 Chaucer Street, Hamlyn Heights, VIC, 3215" },
  { school: "Hamlyn Views School", campus: "Hamlyn Views School", address: "45 Calvert St, Hamlyn Heights, VIC, 3215" },
  { school: "Hampden Specialist School", campus: "Hampden Specialist School", address: "36 Grayland Street, Cobden, VIC, 3266" },
  { school: "Hampden Specialist School", campus: "Terang Campus", address: "71 Warrnambol Road, Terang, VIC, 3264" },
  { school: "Hampton Park Primary School", campus: "Hampton Park Primary School", address: "32 Somerville Road, Hampton Park, VIC, 3976" },
  { school: "Hampton Park Secondary College", campus: "Hampton Park Secondary College", address: "58-96 Fordholm Road, Hampton Park, VIC, 3976" },
  { school: "Hampton Primary School", campus: "Hampton Primary School", address: "528 Hampton Street, Hampton, VIC, 3188" },
  { school: "Harcourt Valley Primary School", campus: "Harcourt Valley Primary School", address: "3 Wilkinson Street, Harcourt, VIC, 3453" },
  { school: "Harkaway Primary School", campus: "Harkaway Primary School", address: "65-67 King Road, Harkaway, VIC, 3806" },
  { school: "Harrietville Primary School", campus: "Harrietville Primary School", address: "165 Great Alpine Road, Harrietville, VIC, 3741" },
  { school: "Harrisfield Primary School", campus: "Harrisfield Primary School", address: "495 Princes Hwy, Noble Park, VIC, 3174" },
  { school: "Harston Primary School", campus: "Harston Primary School", address: "155 Harston Road, Tatura, VIC, 3616" },
  { school: "Hartwell Primary School", campus: "Hartwell Primary School", address: "Milverton St, Camberwell, VIC, 3124" },
  { school: "Harvest Home Primary School", campus: "Harvest Home Primary School", address: "365 Harvest Home Road, Epping, VIC, 3076" },
  { school: "Hastings Primary School", campus: "Hastings Primary School", address: "10-20 Hodgins Road, Hastings, VIC, 3915" },
  { school: "Hawkesdale P12 College", campus: "Hawkesdale P12 College", address: "65 Mitchell Street, Hawkesdale, VIC, 3287" },
  { school: "Hawthorn West Primary School", campus: "Hawthorn West Primary School", address: "Cnr Wood Street & Burwood Road, Hawthorn, VIC, 3122" },
  { school: "Hazel Glen College", campus: "Hazel Glen College", address: "115 Eminence Blvd, Doreen, VIC, 3754" },
  { school: "Hazelwood North Primary School", campus: "Hazelwood North Primary School", address: "121 Church Road, Hazelwood North, VIC, 3840" },
  { school: "Healesville High School", campus: "Healesville High School", address: "10 Camerons Road, Healesville, VIC, 3777" },
  { school: "Healesville Primary School", campus: "Healesville Primary School", address: "2 View Street, Healesville, VIC, 3777" },
  { school: "Heany Park Primary School", campus: "Heany Park Primary School", address: "Buckingham Drive, Rowville, VIC, 3178" },
  { school: "Heathcote Primary School", campus: "Heathcote Primary School", address: "31 Herriot Street, Heathcote, VIC, 3523" },
  { school: "Heatherhill Primary School", campus: "Heatherhill Primary School", address: "959 Heatherton Road, Springvale, VIC, 3171" },
  { school: "Heatherwood School", campus: "Heatherwood School", address: "370-380 Springvale Road, Donvale, VIC, 3111" },
  { school: "Heatherwood School", campus: "State Vision Resource Centre", address: "370-380 Springvale Road, Donvale, VIC, 3111" },
  { school: "Heathmont College", campus: "Heathmont College", address: "Waters Grove, Heathmont, VIC, 3135" },
  { school: "Heathmont East Primary School", campus: "Heathmont East Primary School", address: "Louis Street, Heathmont, VIC, 3135" },
  { school: "Heidelberg Primary School", campus: "Heidelberg Primary School", address: "120 Cape Street, Heidelberg, VIC, 3084" },
  { school: "Hepburn Primary School", campus: "Hepburn Primary School", address: "156 Main Road, Hepburn, VIC, 3461" },
  { school: "Herne Hill Primary School", campus: "Herne Hill Primary School", address: "2-24 Gwynne Street, Hamlyn Heights, VIC, 3218" },
  { school: "Hesket Primary School", campus: "Hesket Primary School", address: "800 Romsey Road, Hesket, VIC, 3442" },
  { school: "Heyfield Primary School", campus: "Heyfield Primary School", address: "22 Temple Street, Heyfield, VIC, 3858" },
  { school: "Heywood Consolidated School", campus: "Heywood Consolidated School", address: "51 Kentbruck Road, Heywood, VIC, 3304" },
  { school: "Heywood District Secondary College", campus: "Heywood District Secondary College", address: "Gorrie Street, Heywood, VIC, 3304" },
  { school: "Highton Primary School", campus: "Highton Primary School", address: "218 Roslyn Road, Highton, VIC, 3216" },
  { school: "Highvale Primary School", campus: "Highvale Primary School", address: "33 Ashton Street, Glen Waverly, VIC, 3150" },
  { school: "Highvale Secondary College", campus: "Highvale Secondary College", address: "Capital Avenue, Glen Waverley, VIC, 3150" },
  { school: "Hillsmeade Primary School", campus: "Hillsmeade Primary School", address: "82S The Promenade, Narre Warren South, VIC, 3805" },
  { school: "Hoddles Creek Primary School", campus: "Hoddles Creek Primary School", address: "700 Gembrook-Launching Place Road, Hoddles Creek, VIC, 3139" },
  { school: "Homestead Senior Secondary College", campus: "Homestead Senior Secondary College", address: "9 Coaching Parade, Point Cook, VIC, 3030" },
  { school: "Hopetoun P-12 College", campus: "Hopetoun P-12 College", address: "1 Dodgshun Street, Hopetoun, VIC, 3396" },
  { school: "Hoppers Crossing Secondary College", campus: "Hoppers Crossing Secondary College", address: "2 Fraser Street, Hoppers Crossing, VIC, 3029" },
  { school: "Horsham College", campus: "Horsham High Campus", address: "310 Baillie Street, Horsham, VIC, 3402" },
  { school: "Horsham College", campus: "Mc Kenzie Creek Campus", address: "4030 Henty Highway, McKenzie Creek, VIC, 3401" },
  { school: "Horsham College", campus: "Baillie Street Campus", address: "198 Baillie Street, Horsham, VIC, 3402" },
  { school: "Horsham Primary School", campus: "298 Campus", address: "38 Baillie Street, Horsham, VIC, 3400" },
  { school: "Horsham Primary School", campus: "Rassmussen Road Campus", address: "2b Rasmussen Road, Horsham, VIC, 3400" },
  { school: "Horsham Special School", campus: "Horsham Special School", address: "17 High Street North, Horsham, VIC, 3400" },
  { school: "Horsham West and Haven Primary School", campus: "Horsham West Campus", address: "24 Hillary Street, Horsham, VIC, 3400" },
  { school: "Horsham West and Haven Primary School", campus: "Haven Campus", address: "4362 Henty Highway, Haven, VIC, 3401" },
  { school: "Hughesdale Primary School", campus: "Hughesdale Primary School", address: "Brine Street, Hughesdale, VIC, 3166" },
  { school: "Hume Central Secondary College", campus: "Blair Street Campus", address: "64-70 Blair Street, Broadmeadows, VIC, 3047" },
  { school: "Hume Central Secondary College", campus: "Town Park Campus", address: "60-78 Tanderrum Way, Broadmeadows, VIC, 3047" },
  { school: "Hume Central Secondary College", campus: "Hume Central English Language Campus", address: "64-70 Blair Street, Broadmeadows, VIC, 3047" },
  { school: "Hume Central Secondary College", campus: "Dimboola Road Campus", address: "29-35 Dimboola Rd, Broadmeadows, VIC, 3047" },
  { school: "Hume Valley School", campus: "Hume Valley School", address: "15-41 Koroit Avenue, Broadmeadows, VIC, 3047" },
  { school: "Hume Valley School", campus: "Tanderrum Way Campus", address: "60-78 Tanderrum Way, Broadmeadows, VIC, 3047" },
  { school: "Hume Valley School", campus: "Narrun Campus", address: "8 Avanel St, Dallas, VIC, 3047" },
  { school: "Huntingdale Primary School", campus: "Huntingdale Primary School", address: "Grange Street, Oakleigh South, VIC, 3167" },
  { school: "Huntly Primary School", campus: "Huntly Primary School", address: "101 Brunel Street, Huntly, VIC, 3551" },
  { school: "Hurstbridge Primary School", campus: "Hurstbridge Primary School", address: "961-989 Heidelberg-Kinglake Rd, Hurstbridge, VIC, 3099" },
  { school: "Inglewood Primary School", campus: "Inglewood Primary School", address: "77 Sullivan Street, Inglewood, VIC, 3517" },
  { school: "Invergordon Primary School", campus: "Invergordon Primary School", address: "1292 Invergordon Road, Invergordon, VIC, 3636" },
  { school: "Inverleigh Primary School", campus: "Inverleigh Primary School", address: "54 High Street, Inverleigh, VIC, 3321" },
  { school: "Inverloch Primary School", campus: "Inverloch Primary School", address: "Bayview Avenue, Inverloch, VIC, 3996" },
  { school: "Invermay Primary School", campus: "Invermay Primary School", address: "187 Swinglers Road, Invermay, VIC, 3352" },
  { school: "Iramoo Primary School", campus: "Iramoo Primary School", address: "261-269 Mcgrath Road, Wyndham Vale, VIC, 3024" },
  { school: "Irymple Primary School", campus: "Irymple Primary School", address: "2032 15th Street, Irymple, VIC, 3498" },
  { school: "Irymple Secondary College", campus: "Irymple Secondary College", address: "975-1011 Karadoc Ave, Irymple, VIC, 3498" },
  { school: "Irymple South Primary School", campus: "Irymple South Primary School", address: "3208 Fifteenth Street, Irymple, VIC, 3498" },
  { school: "Ivanhoe East Primary School", campus: "Warncliffe Road Campus", address: "35 Warncliffe Road Campus, Ivanhoe East, VIC, 3079" },
  { school: "Ivanhoe East Primary School", campus: "Wilfred Road Campus", address: "66 Wilfred Road, Ivanhoe East, VIC, 3079" },
  { school: "Ivanhoe Primary School", campus: "Ivanhoe Primary School", address: "122 Waterdale Road, Ivanhoe, VIC, 3079" },
  { school: "Jacana School for Autism", campus: "Jacana School for Autism", address: "19-39 Landy Road, Jacana, VIC, 3047" },
  { school: "Jackson School", campus: "Jackson School", address: "40-46 Mulhall Drive, St Albans, VIC, 3021" },
  { school: "James Cook Primary School", campus: "James Cook Primary School", address: "29-59 James Cook Drive, Endeavour Hills, VIC, 3802" },
  { school: "Jamieson Primary School", campus: "Jamieson Primary School", address: "1 The Sideling, Jamieson, VIC, 3723" },
  { school: "Jells Park Primary School", campus: "Jells Park Primary School", address: "17-33 Petronella Avenue, Wheelers Hill, VIC, 3150" },
  { school: "Jennings Street School", campus: "Jennings Street School", address: "4 Jennings Street, Laverton, VIC, 3028" },
  { school: "Jeparit Primary School", campus: "Jeparit Primary School", address: "Sands Avenue, Jeparit, VIC, 3423" },
  { school: "Jindivick Primary School", campus: "Jindivick Primary School", address: "1080 Jacksons Track, Jindivick, VIC, 3818" },
  { school: "John Fawkner Secondary College", campus: "John Fawkner Secondary College", address: "51 Jukes Road, Fawkner, VIC, 3060" },
  { school: "John Fawkner Secondary College", campus: "Sports Learning Centre", address: "77 Jukes Road, Fawkner, VIC, 3060" },
  { school: "John Henry Primary School", campus: "John Henry Primary School", address: "141 Henry Road, Pakenham, VIC, 3810" },
  { school: "John Monash Science School", campus: "John Monash Science School", address: "39 Innovation Walk, Clayton, VIC, 3800" },
  { school: "Kalianna Special School", campus: "Kalianna Special School", address: "Nolan Street, Bendigo, VIC, 3550" },
  { school: "Kalianna Special School", campus: "Whipstick Environment Centre", address: "Crameri Lane, Neilborough, VIC, 3570" },
  { school: "Kalinda Primary School", campus: "Kalinda Primary School", address: "39-49 Kalinda Road, Ringwood, VIC, 3134" },
  { school: "Kallista Primary School", campus: "Kallista Primary School", address: "72 Monbulk Road, Kallista, VIC, 3791" },
  { school: "Kambrya College", campus: "Kambrya College", address: "68 Bemersyde Drive, Berwick, VIC, 3806" },
  { school: "Kananook Primary School", campus: "Kananook Primary School", address: "22 Wells Road, Seaford, VIC, 3198" },
  { school: "Kangaroo Flat Primary School", campus: "Kangaroo Flat Primary School", address: "60-80 Olympic Parade, Kangaroo Flat, VIC, 3555" },
  { school: "Kangaroo Ground Primary School", campus: "Kangaroo Ground Primary School", address: "Cr Graham&Eltham-Yarra Glen Rd, Kangaroo Ground, VIC, 3097" },
  { school: "Kaniva College", campus: "Kaniva College", address: "2 Farmers Street, Kaniva, VIC, 3419" },
  { school: "Karingal Heights Primary School", campus: "Karingal Heights Primary School", address: "Havana Crescent, Frankston, VIC, 3199" },
  { school: "Karingal Primary School", campus: "Karingal Primary School", address: "Mallun Avenue, Frankston, VIC, 3199" },
  { school: "Karoo Primary School", campus: "Karoo Primary School", address: "Karoo Road, Rowville, VIC, 3178" },
  { school: "Katamatite Primary School", campus: "Katamatite Primary School", address: "4-6 Currajong Avenue, Katamatite, VIC, 3649" },
  { school: "Katandra School", campus: "Katandra School", address: "2 Walsh Street, Ormond, VIC, 3204" },
  { school: "Katandra West Primary School", campus: "Katandra West Primary School", address: "17-27 Bankin Street, Katandra West, VIC, 3634" },
  { school: "Katunga Primary School", campus: "Katunga Primary School", address: "24 CARTER STREET, Katunga, VIC, 3640" },
  { school: "Katunga South Primary School", campus: "Katunga South Primary School", address: "245 Hays Road, Katunga, VIC, 3640" },
  { school: "Keelonith Primary School", campus: "Keelonith Primary School", address: "1 Blossom Drive, Greenvale, VIC, 3059" },
  { school: "Keilor Downs Secondary College", campus: "Keilor Downs Secondary College", address: "100-110 Odessa Avenue, Keilor Downs, VIC, 3038" },
  { school: "Keilor Heights Primary School", campus: "Keilor Heights Primary School", address: "Ronald Grove, Keilor East, VIC, 3033" },
  { school: "Keilor Primary School", campus: "Keilor Primary School", address: "25 Kennedy Street, Keilor, VIC, 3036" },
  { school: "Keilor Views Primary School", campus: "Keilor Views Primary School", address: "18 Swindon Crescent, Keilor Downs, VIC, 3038" },
  { school: "Kennington Primary School", campus: "Kennington Primary School", address: "60 Crook Street, Kennington, VIC, 3550" },
  { school: "Kennington Primary School", campus: "Bendigo Deaf Facility", address: "60 Crook Street, Kennington, VIC, 3550" },
  { school: "Kensington Community High School", campus: "Kensington Community High School", address: "405 Racecourse Rd, Kensington, VIC, 3031" },
  { school: "Kensington Primary School", campus: "Kensington Primary School", address: "Mccracken Street, Kensington, VIC, 3031" },
  { school: "Kent Park Primary School", campus: "Kent Park Primary School", address: "Greenaway Drive, Ferntree Gully, VIC, 3156" },
  { school: "Kerang Primary School", campus: "Kerang Primary School", address: "75-79 Victoria Street, Kerang, VIC, 3579" },
  { school: "Kerang South Primary School", campus: "Kerang South Primary School", address: "Mitchell Street, Kerang, VIC, 3579" },
  { school: "Kerang Technical High School", campus: "Kerang Technical High School", address: "Murray St, Kerang, VIC, 3579" },
  { school: "Kerrimuir Primary School", campus: "Kerrimuir Primary School", address: "178 Dorking Road, Box Hill North, VIC, 3129" },
  { school: "Kew East Primary School", campus: "Kew East Primary School", address: "35 Kitchener Street, Kew East, VIC, 3102" },
  { school: "Kew High School", campus: "Kew High School", address: "1393 Burke Road, Kew East, VIC, 3102" },
  { school: "Kew Primary School", campus: "Kew Primary School", address: "Peel Street, Kew, VIC, 3101" },
  { school: "Keysborough Gardens Primary School", campus: "Keysborough Gardens Primary School", address: "10 Homeleigh Road, Keysborough, VIC, 3173" },
  { school: "Keysborough Primary School", campus: "Keysborough Primary School", address: "33 Coomoora Road, Springvale South, VIC, 3172" },
  { school: "Keysborough Secondary College", campus: "Acacia Campus", address: "28 Isaac Road, Keysborough, VIC, 3173" },
  { school: "Keysborough Secondary College", campus: "Banksia Campus", address: "8-20 Janine Road, Springvale South, VIC, 3172" },
  { school: "Kialla Central Primary School", campus: "Kialla Central Primary School", address: "128 Central Kialla Road, Kialla, VIC, 3631" },
  { school: "Kialla West Primary School", campus: "Kialla West Primary School", address: "7370 Goulburn Valley Highway, Kialla West, VIC, 3631" },
  { school: "Kiewa Valley Primary School", campus: "Kiewa Valley Primary School", address: "81 Kiewa East Road, Tangambalanga, VIC, 3691" },
  { school: "Kilberry Valley Primary School", campus: "Kilberry Valley Primary School", address: "Kilberry Boulevard, Hampton Park, VIC, 3976" },
  { school: "Killara Primary School", campus: "Killara Primary School", address: "61 Phillip Drive, Sunbury, VIC, 3429" },
  { school: "Kilmore Primary School", campus: "Kilmore Primary School", address: "35 Lancefield Road, Kilmore, VIC, 3764" },
  { school: "Kilsyth Primary School", campus: "Kilsyth Primary School", address: "Durham Road, Kilsyth, VIC, 3137" },
  { school: "Kinglake Primary School", campus: "Kinglake Primary School", address: "20 Mcmahons Rd, Kinglake, VIC, 3763" },
  { school: "Kinglake West Primary School", campus: "Kinglake West Primary School", address: "1041-1061 Whittlesea-Kinglake Rd, Kinglake West, VIC, 3757" },
  { school: "Kings Park Primary School", campus: "Kings Park Primary School", address: "128b Gillespie Road, St Albans, VIC, 3021" },
  { school: "Kingsbury Primary School", campus: "Kingsbury Primary School", address: "1A Maryborough Avenue, Kingsbury, VIC, 3083" },
  { school: "Kingsley Park Primary School", campus: "Kingsley Park Primary School", address: "Franciscan Avenue, Frankston, VIC, 3199" },
  { school: "Kingston Heath Primary School", campus: "Kingston Heath Primary School", address: "25 Farm Road, Cheltenham, VIC, 3192" },
  { school: "Kingsville Primary School", campus: "Kingsville Primary School", address: "58 Bishop Street, Yarraville, VIC, 3013" },
  { school: "Kingswood Primary School", campus: "Kingswood Primary School", address: "1-25 Plaza Crescent, Dingley Vilage, VIC, 3172" },
  { school: "Kismet Park Primary School", campus: "Kismet Park Primary School", address: "Mcewen Drive, Sunbury, VIC, 3429" },
  { school: "Knox Central Primary School", campus: "Knox Central Primary School", address: "39 Darwin Road, Boronia, VIC, 3155" },
  { school: "Knox Gardens Primary School", campus: "Knox Gardens Primary School", address: "80 Argyle Way, Wantirna South, VIC, 3152" },
  { school: "Knox Park Primary School", campus: "Knox Park Primary School", address: "Kathryn Road, Knoxfield, VIC, 3180" },
  { school: "Kongwak Primary School", campus: "Kongwak Primary School", address: "1445 Wonthaggi Korumburra Road, Kongwak, VIC, 3951" },
  { school: "Koo Wee Rup Primary School", campus: "Koo Wee Rup Primary School", address: "Moody Street, Koo Wee Rup, VIC, 3981" },
  { school: "Koo Wee Rup Secondary College", campus: "Koo Wee Rup Secondary College", address: "Rossiter Road, Koo Wee Rup, VIC, 3981" },
  { school: "Koondrook Primary School", campus: "Koondrook Primary School", address: "9 Punt Road, Koondrook, VIC, 3580" },
  { school: "Koonung Secondary College", campus: "Koonung Secondary College", address: "615 Elgar Road, Mont Albert North, VIC, 3129" },
  { school: "Koorlong Primary School", campus: "Koorlong Primary School", address: "3645 Benetook Ave, Koorlong, VIC, 3501" },
  { school: "Koroit And District Primary School", campus: "Koroit And District Primary School", address: "91 Commercial Road, Koroit, VIC, 3282" },
  { school: "Kororoit Creek Primary School", campus: "Kororoit Creek Primary School", address: "130 Tenterfield Drive, Burnside Heights, VIC, 3023" },
  { school: "Korumburra Primary School", campus: "Korumburra Primary School", address: "6-16 Mine Road, Korumburra, VIC, 3950" },
  { school: "Korumburra Secondary College", campus: "Korumburra Secondary College", address: "125 Jumbunna Road, Korumburra, VIC, 3950" },
  { school: "Kunyung Primary School", campus: "Kunyung Primary School", address: "50 Kunyung Road, Mount Eliza, VIC, 3930" },
  { school: "Kurnai College", campus: "FLO Campus", address: "Commercial Road, Morwell, VIC, 3840" },
  { school: "Kurnai College", campus: "Churchill Campus", address: "Cnr Northways Road & McDonald Way, Churchill, VIC, 3842" },
  { school: "Kurnai College", campus: "Morwell Campus", address: "Bridle Road, Morwell, VIC, 3840" },
  { school: "Kurnai College", campus: "University Campus", address: "53 Northways Road, Churchill, VIC, 3842" },
  { school: "Kurnai College", campus: "Young Parent Campus", address: "Commercial Road, Morwell, VIC, 3841" },
  { school: "Kurunjang Primary School", campus: "Kurunjang Primary School", address: "37-45 Mowbray Crescent, Melton, VIC, 3337" },
  { school: "Kurunjang Secondary College", campus: "Kurunjang Secondary College", address: "Kurunjang Drive, Melton, VIC, 3337" },
  { school: "Kyabram P-12 College", campus: "Kyabram P-12 College", address: "57 Fischer Street, Kyabram, VIC, 3620" },
  { school: "Kyabram P-12 College", campus: "Haslem Campus", address: "Haslem Street, Kyabram, VIC, 3620" },
  { school: "Kyneton High School", campus: "Kyneton High School", address: "1-9 Epping Street, Kyneton, VIC, 3444" },
  { school: "Kyneton High School", campus: "Macedon Ranges Satellite VCAL", address: "67 Baynton Street, KYNETON, VIC, 3444" },
  { school: "Kyneton Primary School", campus: "Kyneton Primary School", address: "49-79 Edgecombe Street, Kyneton, VIC, 3444" },
  { school: "Labertouche Primary School", campus: "Labertouche Primary School", address: "35 School Road, Labertouche, VIC, 3816" },
  { school: "Laburnum Primary School", campus: "Laburnum Primary School", address: "Janet Street, Blackburn, VIC, 3130" },
  { school: "Laharum Primary School", campus: "Laharum Primary School", address: "1574 Nothern Grampians Road, Laharum, VIC, 3401" },
  { school: "Lake Boga Primary School", campus: "Lake Boga Primary School", address: "8 Williams Street, Lake Boga, VIC, 3584" },
  { school: "Lake Bolac College", campus: "Lake Bolac College", address: "90 Montgomery Street, Lake Bolac, VIC, 3351" },
  { school: "Lake Charm Primary School", campus: "Lake Charm Primary School", address: "1939 Murray Valley Highway, Lake Charm, VIC, 3581" },
  { school: "Lakes Entrance Primary School", campus: "Lakes Entrance Primary School", address: "49 Myer Street, Lakes Entrance, VIC, 3909" },
  { school: "Lakes Entrance Secondary College", campus: "Lakes Entrance Secondary College", address: "1-11 North Street, Lakes Entrance, VIC, 3909" },
  { school: "Lakeview Senior College", campus: "Lakeview Senior College", address: "College Street, Caroline Springs, VIC, 3023" },
  { school: "Lal Lal Primary School", campus: "Lal Lal Primary School", address: "12 Vaughan Street, Lal Lal, VIC, 3352" },
  { school: "Lalor East Primary School", campus: "Lalor East Primary School", address: "39 Cleveland Street, Thomastown, VIC, 3074" },
  { school: "Lalor Gardens Primary School", campus: "Lalor Gardens Primary School", address: "134 Kingsway Drive, Lalor, VIC, 3075" },
  { school: "Lalor North Primary School", campus: "Lalor North Primary School", address: "2 Derna Crescent, Lalor, VIC, 3075" },
  { school: "Lalor North Secondary College", campus: "Lalor North Secondary College", address: "114 Childs Road, Epping, VIC, 3076" },
  { school: "Lalor Primary School", campus: "Lalor Primary School", address: "26 Maxwell Street, Lalor, VIC, 3075" },
  { school: "Lalor Secondary College", campus: "Lalor Secondary College", address: "118 David Street, Lalor, VIC, 3075" },
  { school: "Lancaster Primary School", campus: "Lancaster Primary School", address: "8 Weller Road, Lancaster, VIC, 3620" },
  { school: "Lancefield Primary School", campus: "Lancefield Primary School", address: "80 High Street, Lancefield, VIC, 3435" },
  { school: "Landsborough Primary School", campus: "Landsborough Primary School", address: "82 McKinlay Street, Landsborough, VIC, 3384" },
  { school: "Lang Lang Primary School", campus: "Lang Lang Primary School", address: "52 Westernport Road, Lang Lang, VIC, 3984" },
  { school: "Langley Primary School", campus: "Langley Primary School", address: "Crn Kyneton/Heathcote & Parsells Rd, Langley, VIC, 3444" },
  { school: "Langwarrin Park Primary School", campus: "Langwarrin Park Primary School", address: "19-27 Northgateway, Langwarrin, VIC, 3910" },
  { school: "Langwarrin Primary School", campus: "Langwarrin Primary School", address: "90 Warrandyte Road, Langwarrin, VIC, 3910" },
  { school: "Lara Lake Primary School", campus: "Lara Lake Primary School", address: "89 Forest Road South, Lara Lake, VIC, 3212" },
  { school: "Lara Primary School", campus: "Lara Primary School", address: "120 Flinders Avenue, Lara, VIC, 3212" },
  { school: "Lara Secondary College", campus: "Lara Secondary College", address: "34 Alkara Avenue, Lara, VIC, 3212" },
  { school: "Lardner And District Primary School", campus: "Lardner And District Primary School", address: "270 Burnt Store Road, Lardner, VIC, 3821" },
  { school: "Latrobe Special Developmental School", campus: "Latrobe Special Developmental School", address: "Hickox Street, Traralgon, VIC, 3844" },
  { school: "Launching Place Primary School", campus: "Launching Place Primary School", address: "Carter Street, Launching Place, VIC, 3139" },
  { school: "Laurimar Primary School", campus: "Laurimar Primary School", address: "Armidale Road, Doreen, VIC, 3754" },
  { school: "Lavers Hill K-12 College", campus: "Lavers Hill K-12 College", address: "Great Ocean Road, Lavers Hill, VIC, 3238" },
  { school: "Lavers Hill K-12 College", campus: "Greater Otway Applied Learning School", address: "6 Murray Street, Colac, VIC, 3250" },
  { school: "Laverton P-12 College", campus: "Laverton P-12 College", address: "91 Bladin Street, Laverton, VIC, 3028" },
  { school: "Le Page Primary School", campus: "Le Page Primary School", address: "77 Argus Street, Cheltenham, VIC, 3192" },
  { school: "Leitchville Primary School", campus: "Leitchville Primary School", address: "2-4 Hawken Street, Leitchville, VIC, 3567" },
  { school: "Lemnos Primary School", campus: "Lemnos Primary School", address: "Lemnos Road, Lemnos, VIC, 3631" },
  { school: "Leongatha Primary School", campus: "Leongatha Primary School", address: "Nerrena Road, Leongatha, VIC, 3953" },
  { school: "Leongatha Secondary College", campus: "Leongatha Secondary College", address: "115 Nerrena Road, Leongatha, VIC, 3953" },
  { school: "Leopold Primary School", campus: "Leopold Primary School", address: "1 Kensington Road, Leopold, VIC, 3224" },
  { school: "Lethbridge Primary School", campus: "Lethbridge Primary School", address: "2 Stephenson Street, Lethbridge, VIC, 3332" },
  { school: "Lightning Reef Primary School", campus: "Lightning Reef Primary School", address: "74 Holmes Road, Bendigo, VIC, 355O" },
  { school: "Lilydale Heights College", campus: "Lilydale Heights College", address: "17-19 Nelson Road, Lilydale, VIC, 3140" },
  { school: "Lilydale High School", campus: "Lilydale High School", address: "25 Melba Avenue, Lilydale, VIC, 3140" },
  { school: "Lilydale Primary School", campus: "Lilydale Primary School", address: "63-65 Castella Street, Lilydale, VIC, 3140" },
  { school: "Lindenow Primary School", campus: "Lindenow Campus", address: "140 Henry Street, Lindenow, vic, 3865" },
  { school: "Lindenow Primary School", campus: "Woodglen Campus", address: "205 Woodglen Road, Woodglen, VIC, 3875" },
  { school: "Lindenow South Primary School", campus: "Lindenow South Primary School", address: "607 Lindenow-Glenaladale Road, Lindenow South, VIC, 3875" },
  { school: "Linton Primary School", campus: "Linton Primary School", address: "28 Adair Street, Linton, VIC, 3360" },
  { school: "Lismore Primary School", campus: "Lismore Primary School", address: "77 William Street, Lismore, VIC, 3324" },
  { school: "Little Bendigo Primary School", campus: "Little Bendigo Primary School", address: "8 Monte Street, Nerrina, VIC, 3350" },
  { school: "Little River Primary School", campus: "Little River Primary School", address: "21 Flinders Street, Little River, VIC, 3211" },
  { school: "Livingstone Primary School", campus: "Livingstone Primary School", address: "25-29 Livingstone Road, Vermont South, VIC, 3133" },
  { school: "Lloyd Street Primary School", campus: "Lloyd Street Primary School", address: "Lloyd Street, Malvern East, VIC, 3145" },
  { school: "Loch Primary School", campus: "Loch Primary School", address: "2 Victoria Road, Loch, VIC, 3945" },
  { school: "Loch Sport Primary School", campus: "Loch Sport Primary School", address: "7 Charlies Street, Loch Sport, VIC, 3851" },
  { school: "Lockington Consolidated School", campus: "Lockington Consolidated School", address: "20 Burns Street, Lockington, VIC, 3563" },
  { school: "Lockwood Primary School", campus: "Lockwood Primary School", address: "190 Wiegards Road, Lockwood, VIC, 3551" },
  { school: "Lockwood South Primary School", campus: "Lockwood South Primary School", address: "710 Calder Alternative Highway, Lockwood South, VIC, 3551" },
  { school: "Longford Primary School", campus: "Longford Primary School", address: "6380 South Gippsland Highway, Longford, VIC, 3851" },
  { school: "Longwarry Primary School", campus: "Longwarry Primary School", address: "5 McCrae Street, Longwarry, VIC, 3816" },
  { school: "Longwood Primary School", campus: "Longwood Primary School", address: "1-21 Hurley Street, Longwood, VIC, 3665" },
  { school: "Lorne P-12 College", campus: "Lorne P-12 College", address: "15 Grove Road, Lorne, VIC, 3232" },
  { school: "Lowanna College", campus: "Lowanna College", address: "72-96 Newark Avenue, VIC, 3825" },
  { school: "Lower Plenty Primary School", campus: "Lower Plenty Primary School", address: "126-146 Main Road, Lower Plenty, VIC, 3093" },
  { school: "Lucas Primary School", campus: "Lucas Primary School", address: "46 OShannassy Parade, Lucas, VIC, 3350" },
  { school: "Lucknow Primary School", campus: "Lucknow Primary School", address: "35 Howitt Avenue, Eastwood, VIC, 3875" },
  { school: "Lynall Hall Community School", campus: "The Island Campus", address: "189 Urquhart Street, Coburg, VIC, 3058" },
  { school: "Lynall Hall Community School", campus: "Lynall Hall Community School", address: "Cnr Gleadell & Highett Street, Richmond, VIC, 3121" },
  { school: "Lynall Hall Community School", campus: "Richmond Campus", address: "1 Gleadell Street, Richmond, VIC, 3121" },
  { school: "Lynbrook Primary School", campus: "Lynbrook Primary School", address: "Paterson Drive, Lynbrook, VIC, 3975" },
  { school: "Lyndale Greens Primary School", campus: "Lyndale Greens Primary School", address: "1-19 Oakwood Avenue, Dandenong North, VIC, 3175" },
  { school: "Lyndale Secondary College", campus: "Lyndale Secondary College", address: "14 Halton Road, Dandenong North, VIC, 3175" },
  { school: "Lyndhurst Primary School", campus: "Lyndhurst Primary School", address: "70 Brookwater Parade, Lyndhurst, VIC, 3975" },
  { school: "Lyndhurst Secondary College", campus: "Lyndhurst Secondary College", address: "950 South Gippsland Highway, Cranbourne, VIC, 3977" },
  { school: "Lysterfield Primary School", campus: "Lysterfield Primary School", address: "Bellfield Drive, Lysterfield, VIC, 3156" },
  { school: "Macarthur Primary School", campus: "Macarthur Primary School", address: "23 Russell Street, Macarthur, VIC, 3286" },
  { school: "Macarthur Street Primary School", campus: "Macarthur Street Primary School", address: "401 Macarthur Street, Soldiers Hill, VIC, 3350" },
  { school: "Macclesfield Primary School", campus: "Macclesfield Primary School", address: "405 Macclesfield Road, Macclesfield, VIC, 3782" },
  { school: "Macedon Primary School", campus: "Macedon Primary School", address: "67 Smith Street, Macedon, VIC, 3440" },
  { school: "Mackellar Primary School", campus: "Mackellar Primary School", address: "29-35 Goldsmith Avenue, Delahey, VIC, 3037" },
  { school: "Macleod College", campus: "Macleod College", address: "Carwarp Street, Macleod, VIC, 3085" },
  { school: "MacRobertson Girls High School", campus: "MacRobertson Girls High School", address: "350-370 Kings Way, Melbourne, VIC, 3004" },
  { school: "Maffra Primary School", campus: "Maffra Primary School", address: "22-28 Church Street, Maffra, VIC, 3860" },
  { school: "Maffra Secondary College", campus: "Maffra Secondary College", address: "Bill Cane Court, Maffra, VIC, 3860" },
  { school: "Magpie Primary School", campus: "Magpie Primary School", address: "61 Docwra Street, Magpie, VIC, 3352" },
  { school: "Mahogany Rise Primary School", campus: "Mahogany Rise Primary School", address: "25 Forster Avenue, Frankston North, VIC, 3200" },
  { school: "Mahogany Rise Primary School", campus: "Mahogany Rise Student Development Centre", address: "25 Forster Avenue, Frankston North, VIC, 3200" },
  { school: "Maiden Gully Primary School", campus: "Maiden Gully Primary School", address: "10 Carolyn Way, Maiden Gully, VIC, 3551" },
  { school: "Maldon Primary School", campus: "Maldon Primary School", address: "109 High Street, Maldon, VIC, 3463" },
  { school: "Mallacoota P-12 College", campus: "Mallacoota P-12 College", address: "25 Buchnall Street, Mallacoota, VIC, 3892" },
  { school: "Malmsbury Primary School", campus: "Malmsbury Primary School", address: "28 Cameron Street, Malmsbury, VIC, 3446" },
  { school: "Malvern Central School", campus: "Malvern Central School", address: "Spring road, Malvern, VIC, 3144" },
  { school: "Malvern Primary School", campus: "Malvern Primary School", address: "17 Tooronga Road, Malvern East, VIC, 3145" },
  { school: "Malvern Valley Primary School", campus: "Malvern Valley Primary School", address: "Abbotsford Avenue, Malvern East, VIC, 3145" },
  { school: "Manangatang P-12 College", campus: "Manangatang P-12 College", address: "4105 Mallee Highway, Manangatang, VIC, 3546" },
  { school: "Manchester Primary School", campus: "Manchester Primary School", address: "Monomeith Street, Mooroolbark, VIC, 3138" },
  { school: "Mandama Primary School", campus: "Mandama Primary School", address: "10 Corang Avenue, Grovedale, VIC, 3216" },
  { school: "Manifold Heights Primary School", campus: "Manifold Heights Primary School", address: "20-24 Strachan Avenue, Manifold Heights, VIC, 3218" },
  { school: "Manor Lakes P-12 College", campus: "Manor Lakes P-12 College", address: "2-50 Minindee Road, Wyndham Vale, VIC, 3024" },
  { school: "Manorvale Primary School", campus: "Manorvale Primary School", address: "232-246 Greaves St North, Werribee, VIC, 3030" },
  { school: "Mansfield Primary School", campus: "Mansfield Primary School", address: "4 Apollo Street, Mansfield, VIC, 3722" },
  { school: "Mansfield Primary School", campus: "Mt Buller Campus", address: "L2 Mt Buller Community Centre, Mt Buller, VIC, 3723" },
  { school: "Mansfield Secondary College", campus: "Mansfield Secondary College", address: "15 View Street, Mansfield, VIC, 3722" },
  { school: "Mansfield Secondary College", campus: "Mansfield Secondary Mt Buller Annexe", address: "Mt Buller and Mt Stirling Resort, Mt Buller, VIC, 3723" },
  { school: "Maramba Primary School", campus: "Maramba Primary School", address: "Maramba Drive, Narre Warren, VIC, 3805" },
  { school: "Maribyrnong Secondary College", campus: "Maribyrnong Secondary College", address: "River Street, Maribyrnong, VIC, 3032" },
  { school: "Marlborough Primary School", campus: "Marlborough Primary School", address: "Hardy Crescent, Heathmont, VIC, 3135" },
  { school: "Marlo Primary School", campus: "Marlo Primary School", address: "Jorgensen Street, Marlo, VIC, 3888" },
  { school: "Marnebek School Cranbourne", campus: "Marnebek School Cranbourne", address: "9 New Holland Drive, Cranbourne East, VIC, 3977" },
  { school: "Marnebek School Cranbourne", campus: "Cranbourne East Campus", address: "Corrigans Road, Cranbourne East, VIC, 3977" },
  { school: "Marnoo Primary School", campus: "Marnoo Primary School", address: "Newall Street, Marnoo, VIC, 3387" },
  { school: "Marong Primary School", campus: "Marong Primary School", address: "2 Leslie Street, Marong, VIC, 3515" },
  { school: "Maroona Primary School", campus: "Maroona Primary School", address: "7431 Mortlake-ararat Road, Maroona, VIC, 3377" },
  { school: "Maryborough Education Centre", campus: "Balaclava Road Campus", address: "102-192 Balaclava Road, Maryborough, VIC, 3465" },
  { school: "Maryborough Education Centre", campus: "Beckworth Campus", address: "102-192 Balaclava Road, Maryborough, VIC, 3465" },
  { school: "Maryborough Education Centre", campus: "Maryborough FLO", address: "53 Burns Street, Maryborough, VIC, 3465" },
  { school: "Marysville Primary School", campus: "Marysville Primary School", address: "15 Falls Road, Marysville, VIC, 3779" },
  { school: "Matthew Flinders Girls Secondary College", campus: "Matthew Flinders Girls Secondary College", address: "2 Little Ryrie Street, Geelong, VIC, 3220" },
  { school: "McClelland Secondary College", campus: "McClelland Secondary College", address: "31 Belar Avenue, Frankston, VIC, 3199" },
  { school: "McClelland Secondary College", campus: "Camp Mahaikah", address: "2975 Mansfield-Whitefield Road, Tolmie, VIC, 3273" },
  { school: "McKinnon Primary School", campus: "McKinnon Primary School", address: "253 Tucker Road, Ormond, VIC, 3204" },
  { school: "McKinnon Secondary College", campus: "McKinnon Secondary College", address: "291a Mckinnon Road, Mckinnon, VIC, 3204" },
  { school: "Meadow Heights Primary School", campus: "Meadow Heights Primary School", address: "46-62 Paringa Boulevard, Meadow Heights, VIC, 3048" },
  { school: "Meadowglen Primary School", campus: "Meadowglen Primary School", address: "22-48 Meadow Glen Drive, Epping, VIC, 3076" },
  { school: "Meadows Primary School", campus: "Meadows Primary School", address: "41 Gerbert Street, Broadmeadows, VIC, 3047" },
  { school: "Melba Secondary College", campus: "Melba Secondary College", address: "20 Brentnall Road, Croydon, VIC, 3136" },
  { school: "Melba Secondary College", campus: "Junior Campus", address: "Blakeley Rd, Castlemaine, VIC, 3450" },
  { school: "Melbourne Girls College", campus: "Melbourne Girls College", address: "Yarra Boulevard, Richmond, VIC, 3121" },
  { school: "Melbourne High School", campus: "Melbourne High School", address: "Forrest Hill, South Yarra, VIC, 3141" },
  { school: "Melbourne High School", campus: "Millgrove Outdoor Education Centre", address: "140 Dee Road, Millgrove, VIC, 3799" },
  { school: "Melrose Primary School", campus: "Melrose Primary School", address: "25 Silva Drive, West Wodonga, VIC, 3690" },
  { school: "Melton Primary School", campus: "Melton Primary School", address: "Unitt Street, Melton, VIC, 3337" },
  { school: "Melton Secondary College", campus: "Melton Secondary College", address: "Coburns Road, Melton, VIC, 3337" },
  { school: "Melton Secondary College", campus: "CaLM Link up Campus", address: "41 Thrice Lane, Kurunjang, VIC, 3337" },
  { school: "Melton South Primary School", campus: "Melton South Primary School", address: "38-46 Exford Road, Melton South, VIC, 3338" },
  { school: "Melton Specialist School", campus: "Melton Specialist School", address: "159-211 Coburns Road, Melton, VIC, 3337" },
  { school: "Melton West Primary School", campus: "Melton West Primary School", address: "2 Rathdowne Circuit, Melton West, VIC, 3337" },
  { school: "Mentone Girls Secondary College", campus: "Mentone Girls Secondary College", address: "Cnr Balcombe & Charman Rds, Mentone, VIC, 3194" },
  { school: "Mentone Park Primary School", campus: "Mentone Park Primary School", address: "Broome Avenue, Mentone, VIC, 3194" },
  { school: "Mentone Primary School", campus: "Mentone Primary School", address: "6 Childers Street, Mentone, VIC, 3194" },
  { school: "Menzies Creek Primary School", campus: "Menzies Creek Primary School", address: "12 Schoold Road, Menzies Creek, VIC, 3159" },
  { school: "Merbein P-10 College", campus: "Merbein P-10 College", address: "188-206 Commercial Street, Merbein, VIC, 3505" },
  { school: "Meredith Primary School", campus: "Meredith Primary School", address: "5 Wallace Street, Meredith, VIC, 3333" },
  { school: "Merino Consolidated School", campus: "Merino Consolidated School", address: "49 Paschendale Road, Merino, VIC, 3310" },
  { school: "Mernda Central P-12 College", campus: "Mernda Central P-12 College", address: "70 Breadalbane Avenue, Mernda, VIC, 3754" },
  { school: "Mernda Park Primary School", campus: "Mernda Park Primary School", address: "40 Riverdale Boulevard, Mernda, VIC, 3754" },
  { school: "Mernda Primary School", campus: "Mernda Primary School", address: "25 Everton Drive, Mernda, VIC, 3754" },
  { school: "Merri Creek Primary School", campus: "Merri Creek Primary School", address: "Miller Street, Fitzroy North, VIC, 3068" },
  { school: "Merriang Special Developmental School", campus: "Merriang Special Developmental School", address: "11 Duncan Road, Lalor, VIC, 3075" },
  { school: "Merrigum Primary School", campus: "Merrigum Primary School", address: "Judd Avenue, Merrigum, VIC, 3618" },
  { school: "Merrijig Primary School", campus: "Merrijig Primary School", address: "1820 Mount Buller Road, Merrijig, VIC, 3723" },
  { school: "Merrivale Primary School", campus: "Merrivale Primary School", address: "158 Merrivale Drive, Warrnambool, VIC, 3280" },
  { school: "Metung Primary School", campus: "Metung Primary School", address: "90 Stirling Road, Metung, VIC, 3904" },
  { school: "Mickleham Primary School", campus: "Mickleham Primary School", address: "1880 Mickleham Road, Mickleham, VIC, 3064" },
  { school: "Middle Indigo Primary School", campus: "Middle Indigo Primary School", address: "710 Indigo Creek Road, Indigo Valley, VIC, 3688" },
  { school: "Middle Kinglake Primary School", campus: "Middle Kinglake Primary School", address: "1 Extons Road, Kinglake Central, VIC, 3757" },
  { school: "Middle Park Primary School", campus: "Middle Park Primary School", address: "194 Richardson Street, Middle Park, VIC, 3206" },
  { school: "Milawa Primary School", campus: "Milawa Primary School", address: "133 Milawa-Bobinawarrah Road, Milawa, VIC, 3678" },
  { school: "Mildura Primary School", campus: "Mildura Primary School", address: "175-189 San Mateo Avenue, Mildura, VIC, 3500" },
  { school: "Mildura Senior College", campus: "Mildura English Language Centre", address: "307-339 Deakin Avenue, Mildura, VIC, 3500" },
  { school: "Mildura Senior College", campus: "Mildura Senior College", address: "307-339 Deakin Avenue, Mildura, VIC, 3500" },
  { school: "Mildura South Primary School", campus: "Mildura South Primary School", address: "593 Deakin Avenue, Mildura South, VIC, 3501" },
  { school: "Mildura Specialist School", campus: "Mildura Specialist School", address: "341-357 Deakin Avenue, Mildura, VIC, 3500" },
  { school: "Mildura West Primary School", campus: "Mildura West Primary School", address: "219-227 Ninth Street, Mildura, VIC, 3500" },
  { school: "Milgate Primary School", campus: "Milgate Primary School", address: "96 Landscape Drive, Doncaster East, VIC, 3109" },
  { school: "Mill Park Heights Primary School", campus: "Mill Park Heights Primary School", address: "87-93 Pindari Avenue, Mill Park, VIC, 3082" },
  { school: "Mill Park Primary School", campus: "Mill Park Primary School", address: "Blamey Avenue, Mill Park, VIC, 3082" },
  { school: "Mill Park Secondary College", campus: "Senior Campus", address: "Etty Street, Castlemaine, VIC, 3450" },
  { school: "Mill Park Secondary College", campus: "Mill Park Secondary College", address: "9-25 Moorhead Drive, Mill Park, VIC, 3082" },
  { school: "Millwarra Primary School", campus: "Millgrove Campus", address: "Cavanagh Road, Millgrove, VIC, 3799" },
  { school: "Millwarra Primary School", campus: "Warburton East Campus", address: "397 Woods Point Road, East Warburton, VIC, 3799" },
  { school: "Miners Rest Primary School", campus: "Miners Rest Primary School", address: "Dundas Street, Miners Rest, VIC, 3352" },
  { school: "Minyip Primary School", campus: "Minyip Primary School", address: "21 Main Street, Minyip, VIC, 3392" },
  { school: "Mirboo North Primary School", campus: "Mirboo North Primary School", address: "Balook Street, Mirboo North, VIC, 3871" },
  { school: "Mirboo North Secondary College", campus: "Mirboo North Secondary College", address: "Castle Street, Mirboo North, VIC, 3871" },
  { school: "Mirripoa Primary School", campus: "Mirripoa Primary School", address: "80 Unity Drive, Mount Duneed, VIC, 3217" },
  { school: "Mitcham Primary School", campus: "Mitcham Primary School", address: "294 Mitcham Road, Mitcham, VIC, 3132" },
  { school: "Mitta Mitta Primary School", campus: "Mitta Mitta Primary School", address: "27 Giltrap Road, Mitta Mitta, VIC, 3701" },
  { school: "Moe (Albert Street) Primary School", campus: "Moe (Albert Street) Primary School", address: "40 Albert Street, Moe, VIC, 3825" },
  { school: "Moe (South Street) Primary School", campus: "Moe (South Street) Primary School", address: "34 South Street, Moe, VIC, 3825" },
  { school: "Moe Primary School", campus: "Moe Primary School", address: "Cnr Elizabeth & King Streets, Moe, VIC, 3825" },
  { school: "Monash Children's Hospital School", campus: "Monash Children's Hospital School", address: "246 Clayton Road, Clayton, VIC, 3168" },
  { school: "Monash Children's Hospital School", campus: "Monash Adolescent Psychiatric Centre", address: "246 Clayton Road, Clayton, VIC, 3168" },
  { school: "Monash Special Developmental School", campus: "Monash Special Developmental School", address: "15-25 Academy Avenue, Wheelers Hill, VIC, 3150" },
  { school: "Monbulk College", campus: "Monbulk College", address: "146-148 David Hill Road, Monbulk, VIC, 3793" },
  { school: "Monbulk Primary School", campus: "Monbulk Primary School", address: "1 Main Road, Monbulk, VIC, 3793" },
  { school: "Monmia Primary School", campus: "Monmia Primary School", address: "Copernicus Way, Keilor Downs, VIC, 3038" },
  { school: "Mont Albert Primary School", campus: "Mont Albert Primary School", address: "Barloa Road, Mont Albert, VIC, 3127" },
  { school: "Montague Continuing Education Centre", campus: "Montague Continuing Education Centre", address: "100 Montague Street, South Melbourne, VIC, 3205" },
  { school: "Monterey Secondary College", campus: "Monterey Secondary College", address: "Silvertop Street, Frankston North, VIC, 3200" },
  { school: "Montmorency Primary School", campus: "Montmorency Primary School", address: "60a Rattray Road, Montmorency, VIC, 3094" },
  { school: "Montmorency Secondary College", campus: "Montmorency Secondary College", address: "Para Road, Montmorency, VIC, 3094" },
  { school: "Montmorency South Primary School", campus: "Montmorency South Primary School", address: "64 Buena Vista Drive, Montmorency, VIC, 3094" },
  { school: "Montpellier Primary School", campus: "Montpellier Primary School", address: "21-31 Lambhill Crescent, Highton, VIC, 3216" },
  { school: "Montrose Primary School", campus: "Montrose Primary School", address: "17-21 Leith Road, Montrose, VIC, 3765" },
  { school: "Moolap Primary School", campus: "Moolap Primary School", address: "30 Wills Cres, Moolap, VIC, 3221" },
  { school: "Moomba Park Primary School", campus: "Moomba Park Primary School", address: "111 Anderson Road, Fawkner, VIC, 3060" },
  { school: "Moonambel Primary School", campus: "Moonambel Primary School", address: "24 Humffray Street, Moonambel, VIC, 3478" },
  { school: "Moonee Ponds Primary School", campus: "Moonee Ponds Primary School", address: "87 Wilson Street, Moonee Ponds, VIC, 3039" },
  { school: "Moonee Ponds West Primary School", campus: "Moonee Ponds West Primary School", address: "150 Athol Street, Moonee Ponds, VIC, 3039" },
  { school: "Moorabbin Primary School", campus: "Moorabbin Primary School", address: "61 Worthing Road, Moorabbin, VIC, 3189" },
  { school: "Moorooduc Primary School", campus: "Moorooduc Primary School", address: "Cnr Derril Rd & Mornington Tyabb Rd, Moorooduc, VIC, 3933" },
  { school: "Mooroolbark College", campus: "Mooroolbark College", address: "186 Manchester Road, Mooroolbark, Mooroolbark, VIC, 3138" },
  { school: "Mooroolbark East Primary School", campus: "Mooroolbark East Primary School", address: "Tyalor Road, Mooroolbark, VIC, 3138" },
  { school: "Mooroopna North Primary School", campus: "Mooroopna North Primary School", address: "835 Ardmona Road, Mooroopna, VIC, 3629" },
  { school: "Mooroopna Park Primary School", campus: "Mooroopna Park Primary School", address: "45 Maclsaac Road, Mooroopna, VIC, 3629" },
  { school: "Mooroopna Primary School", campus: "Mooroopna Primary School", address: "16-18 O'Brien Street, Mooroopna, VIC, 3629" },
  { school: "Morang South Primary School", campus: "Morang South Primary School", address: "77 Gorge Road, South Morang, VIC, 3752" },
  { school: "Mordialloc Beach Primary School", campus: "Mordialloc Beach Primary School", address: "58 Barkly Street, Mordialloc, VIC, 3195" },
  { school: "Mordialloc College", campus: "Mordialloc College", address: "1 Station Street, Mordialloc, VIC, 3195" },
  { school: "Moreland Primary School", campus: "Moreland Primary School", address: "157-163 Moreland Road, Coburg, VIC, 3058" },
  { school: "Moriac Primary School", campus: "Moriac Primary School", address: "785 Hendy Main Road, Moriac, VIC, 3240" },
  { school: "Mornington Park Primary School", campus: "Mornington Park Primary School", address: "Robertson Drive, Mornington, VIC, 3931" },
  { school: "Mornington Primary School", campus: "Mornington Primary School", address: "Vale Street, Mornington, VIC, 3931" },
  { school: "Mornington Secondary College", campus: "Mornington Secondary College", address: "1051 Nepean Highway, Mornington, VIC, 3931" },
  { school: "Mornington Special Developmental School", campus: "Mornington Special Developmental School", address: "40 Robertson Drive, Mornington, VIC, 3931" },
  { school: "Mortlake P-12 College", campus: "Mortlake P-12 College", address: "10 Hood Avenue, Mortlake, VIC, 3272" },
  { school: "Morwell Central Primary School", campus: "Morwell Central Primary School", address: "47-87 McDonald Street, Morwell, VIC, 3840" },
  { school: "Morwell Park Primary School", campus: "Morwell Park Primary School", address: "76-82 Vary Street, Morwell, VIC, 3840" },
  { school: "Mossfiel Primary School", campus: "Mossfiel Primary School", address: "Langridge Street, Hoppers Crossing, VIC, 3029" },
  { school: "Mossgiel Park Primary School", campus: "Mossgiel Park Primary School", address: "71 Scotsburn Way, Endeavour Hills, VIC, 3802" },
  { school: "Mount Alexander 7-12 College", campus: "Mount Alexander 7-12 College", address: "167-175 Mt Alexander Road, Flemington, VIC, 3031" },
  { school: "Mount Beauty Primary School", campus: "Mount Beauty Primary School", address: "Lakeside Avenue, Mount Beauty, VIC, 3699" },
  { school: "Mount Beauty Secondary College", campus: "Mount Beauty Secondary College", address: "Tailrace Road, Mount Beauty, VIC, 3699" },
  { school: "Mount Blowhard Primary School", campus: "Mount Blowhard Primary School", address: "527 Millers Road, Blowhard, VIC, 3352" },
  { school: "Mount Clear College", campus: "Mount Clear College", address: "59 Olympic Avenue, Mount Clear, VIC, 3353" },
  { school: "Mount Clear Primary School", campus: "Mount Clear Primary School", address: "1206 Geelong Road, Mount Clear, VIC, 3350" },
  { school: "Mount Dandenong Primary School", campus: "Mount Dandenong Primary School", address: "Farndons Road, Mount Dandenong, VIC, 3767" },
  { school: "Mount Duneed Regional Primary School", campus: "Mount Duneed Regional Primary School", address: "90-100 Williams Road, Mount Duneed, VIC, 3217" },
  { school: "Mount Egerton Primary School", campus: "Mount Egerton Primary School", address: "58 Church Street, Mount Egerton, VIC, 3352" },
  { school: "Mount Eliza North Primary School", campus: "Mount Eliza North Primary School", address: "6 Moseley Drive, Mount Eliza, VIC, 3930" },
  { school: "Mount Eliza Primary School", campus: "Mount Eliza Primary School", address: "Wooralla Drive, Mount Eliza, VIC, 3930" },
  { school: "Mount Eliza Secondary College", campus: "Mount Eliza Secondary College", address: "204 Canadian Bay Road, Mount Eliza, VIC, 3930" },
  { school: "Mount Erin Secondary College", campus: "Mount Erin Deaf Facility", address: "Robinsons Road, Frankston, VIC, 3199" },
  { school: "Mount Erin Secondary College", campus: "Frankston Campus", address: "Robinsons Road, Frankston, VIC, 3199" },
  { school: "Mount Evelyn Primary School", campus: "Mount Evelyn Primary School", address: "33 Monbulk Road, Mount Evelyn, VIC, 3796" },
  { school: "Mount Macedon Primary School", campus: "Mount Macedon Primary School", address: "641 Mount Macedon Road, Mount Macedon, VIC, 3441" },
  { school: "Mount Martha Primary School", campus: "Mount Martha Primary School", address: "37-55 Glenisla Drive, Mount Martha, VIC, 3934" },
  { school: "Mount Pleasant Primary School", campus: "Mount Pleasant Primary School", address: "401 Cobden Street, Ballarat, VIC, 3350" },
  { school: "Mount Pleasant Road Nunawading Primary School", campus: "Mount Pleasant Road Nunawading Primary School", address: "Mt Pleasant Road, Nunawading, VIC, 3131" },
  { school: "Mount Ridley P-12 College", campus: "Mount Ridley P-12 College", address: "2-30 Hampton Street, Craigieburn, VIC, 3064" },
  { school: "Mount Rowan Secondary College", campus: "Mount Rowan Campus", address: "Cnr Forest St And Giot Dve, Wendouree, VIC, 3355" },
  { school: "Mount Rowan Secondary College", campus: "LinkUp - Ballarat Learning Exchange Campus", address: "602 Urquhart Street, Ballarat, VIC, 3350" },
  { school: "Mount View Primary School", campus: "Mount View Primary School", address: "Shepherd Road, Glen Waverly, VIC, 3150" },
  { school: "Mount View Primary School", campus: "Mount View Deaf Facility", address: "Shepherd Road, Glen Waverly, VIC, 3150" },
  { school: "Mount Waverley Heights Primary School", campus: "Mount Waverley Heights Primary School", address: "36 Solomon Street, Mount Waverly, VIC, 3149" },
  { school: "Mount Waverley North Primary School", campus: "Mount Waverley North Primary School", address: "Marcus Avenue, Mount Waverly, VIC, 3149" },
  { school: "Mount Waverley Primary School", campus: "Mount Waverley Primary School", address: "37-45 Park Lane, Mount Waverly, VIC, 3149" },
  { school: "Mount Waverley Secondary College", campus: "Middle Senior Campus", address: "9 Lechte Road, Mount Waverley, VIC, 3149" },
  { school: "Mount Waverley Secondary College", campus: "Junior Campus", address: "Blakely Rd, Castlemaine, VIC, 3450" },
  { school: "Mountain Gate Primary School", campus: "Mountain Gate Primary School", address: "Adele Avenue, Ferntree Gully, VIC, 3156" },
  { school: "Movelle Primary School", campus: "Movelle Primary School", address: "39 Gum Road, KINGS PARK, VIC, 3021" },
  { school: "Moyhu Primary School", campus: "Moyhu Primary School", address: "2799 Wangaratta-Whitfield Road, Moyhu, VIC, 3732" },
  { school: "Moyston Primary School", campus: "Moyston Primary School", address: "1349 Ararat- Halls Gap Road, Moyston, VIC, 3377" },
  { school: "Mulgrave Primary School", campus: "Mulgrave Primary School", address: "Gladeswood Drive, Mulgrave, VIC, 3170" },
  { school: "Mullauna Secondary College", campus: "Mullauna Secondary College", address: "456 Springfield Road, Mitcham, VIC, 3132" },
  { school: "Mullum Primary School", campus: "Mullum Primary School", address: "18 Panfield Avenue, Ringwood, VIC, 3134" },
  { school: "Murchison Primary School", campus: "Murchison Primary School", address: "8-10 Impey Street, Murchison, VIC, 3610" },
  { school: "Murrabit Group School", campus: "Murrabit Group School", address: "61 Browning Avenue, Murrabit, VIC, 3579" },
  { school: "Murrayville Community College", campus: "Murrayville Community College", address: "20 Francis Street, Murrayville, VIC, 3512" },
  { school: "Murrumbeena Primary School", campus: "Murrumbeena Primary School", address: "Hobart Road, Murrumbeena, VIC, 3163" },
  { school: "Murtoa College", campus: "Murtoa College", address: "Webb Street, Murtoa, VIC, 3390" },
  { school: "Myrniong Primary School", campus: "Myrniong Primary School", address: "13 Muddy Lane, Myrniong, VIC, 3341" },
  { school: "Myrrhee Primary School", campus: "Myrrhee Primary School", address: "1673 Benalla-whitfield Road, Myrrhee, VIC, 3732" },
  { school: "Myrtleford P-12 College", campus: "Myrtleford P-12 College", address: "Prince Street, Myrtleford, VIC, 3737" },
  { school: "Nagambie Primary School", campus: "Nagambie Primary School", address: "31 Goulburn Street, Nagambie, VIC, 3608" },
  { school: "Nambrok Denison Primary School", campus: "Nambrok Denison Primary School", address: "947 Sale-Cowwarr Road, Nambrok, VIC, 3847" },
  { school: "Nangiloc Colignan And District Primary School", campus: "Nangiloc Colignan And District Primary School", address: "2612 Kulkyne Way, Nangiloc, VIC, 3494" },
  { school: "Nanneella Estate Primary School", campus: "Nanneella Estate Primary School", address: "159 Bennett Road, Nanneella, VIC, 3561" },
  { school: "Napoleons Primary School", campus: "Napoleons Primary School", address: "4721 Colac-Ballarat Road, Napoleons, VIC, 3352" },
  { school: "Nar Nar Goon Primary School", campus: "Nar Nar Goon Primary School", address: "27 Spencer Street, Nar Nar Goon, VIC, 3812" },
  { school: "Naranga Special School", campus: "Naranga Special School", address: "14 Naranga Crescent, Frankston, VIC, 3199" },
  { school: "Narracan Primary School", campus: "Narracan Primary School", address: "32 School Road, Narracan, VIC, 3824" },
  { school: "Narrawong District Primary School", campus: "Narrawong District Primary School", address: "Princes Highway, Narrawong, VIC, 3285" },
  { school: "Narre Warren North Primary School", campus: "Narre Warren North Primary School", address: "15-31 A'beckett Road, Narre Warren North, VIC, 3804" },
  { school: "Narre Warren South P-12 College", campus: "Narre Warren South P-12 College", address: "Amberly Park Drive, Narre Warren South, VIC, 3805" },
  { school: "Nathalia Primary School", campus: "Nathalia Primary School", address: "5 Bromley Street, Nathalia, VIC, 3638" },
  { school: "Nathalia Secondary College", campus: "Nathalia Secondary College", address: "41 Chapel Street, Nathalia, VIC, 3638" },
  { school: "Natimuk Primary School", campus: "Natimuk Primary School", address: "38 Old Noradjuha Road, Natimuk, VIC, 3409" },
  { school: "Natte Yallock Primary School", campus: "Natte Yallock Primary School", address: "10 School Road, Natte Yallock, VIC, 3465" },
  { school: "Navarre Primary School", campus: "Navarre Primary School", address: "High Street, Navarre, VIC, 3384" },
  { school: "Neerim District Rural Primary School", campus: "Neerim District Rural Primary School", address: "145 Nayook-Powelltown Road, Nayook, VIC, 3832" },
  { school: "Neerim District Secondary College", campus: "Neerim District Secondary College", address: "20 Neerim East Road, Neerim South, VIC, 3831" },
  { school: "Neerim South Primary School", campus: "Neerim South Primary School", address: "180 Main Neerim Road, Neerim South, VIC, 3831" },
  { school: "Nelson Park School", campus: "Nelson Park School", address: "Libau Avenue, Bell Park, VIC, 3215" },
  { school: "Nelson Park School", campus: "Illinois Avenue Annexe", address: "Illinois Avenue, Corio, VIC, 3214" },
  { school: "Nepean Special School", campus: "Nepean Special School", address: "33 Klauer St, Seaford, VIC, 3198" },
  { school: "New Gisborne Primary School", campus: "New Gisborne Primary School", address: "239 Station Road, New Gisborne, VIC, 3438" },
  { school: "Newborough East Primary School", campus: "Newborough East Primary School", address: "300 Old Sale Road, Newborough, VIC, 3825" },
  { school: "Newborough Primary School", campus: "Newborough Primary School", address: "24 Murray Road, Newborough, VIC, 3825" },
  { school: "Newbury Primary School", campus: "Newbury Primary School", address: "202-226 Newbury Boulevard, Craigieburn, VIC, 3064" },
  { school: "Newcomb Park Primary School", campus: "Newcomb Park Primary School", address: "7-25 Richard Street, Newcomb, VIC, 3219" },
  { school: "Newcomb Secondary College", campus: "Newcomb Secondary College", address: "81-85 Bellarine Highway, Newcomb, VIC, 3219" },
  { school: "Newham Primary School", campus: "Newham Primary School", address: "1271 Rochford Road, Newham, VIC, 3442" },
  { school: "Newhaven Primary School", campus: "Newhaven Primary School", address: "12-22 School Avenue, Newhaven, VIC, 3925" },
  { school: "Newington Primary School", campus: "Newington Primary School", address: "49 Inkerman Street, Ballarat, VIC, 3350" },
  { school: "Newlands Primary School", campus: "Newlands Primary School", address: "2-26 Murphy Street, Preston, VIC, 3072" },
  { school: "Newlyn Primary School", campus: "Newlyn Primary School", address: "2701 Midland Highway, Newlyn, VIC, 3364" },
  { school: "Newmerella Primary School", campus: "Newmerella Primary School", address: "2-4 McLaughlins Road, Newmerella, VIC, 3886" },
  { school: "Newport Gardens Primary School", campus: "Newport Gardens Primary School", address: "Cnr Woods St & Maddox Rd, Newport, VIC, 3015" },
  { school: "Newport Lakes Primary School", campus: "Newport Lakes Primary School", address: "Elizabeth Street, Newport, VIC, 3015" },
  { school: "Newstead Primary School", campus: "Newstead Primary School", address: "28 Lyons Street, Newstead, VIC, 3462" },
  { school: "Newtown Primary School", campus: "Newtown Primary School", address: "Aberdeen Street, Newtown, VIC, 3220" },
  { school: "Nhill College", campus: "Nhill College", address: "Whitehead Avenue, Nhill, VIC, 3418" },
  { school: "Nichols Point Primary School", campus: "Nichols Point Primary School", address: "110 Koorlong Avenue, Nicholas Point, VIC, 3501" },
  { school: "Nicholson Primary School", campus: "Nicholson Primary School", address: "Princes Highway, Nicholson, VIC, 3882" },
  { school: "Niddrie Autistic School", campus: "Niddrie Autistic School", address: "Cnr Garnet And Teague Street, Niddrie, VIC, 3042" },
  { school: "Niddrie Primary School", campus: "Niddrie Primary School", address: "25 Watt Street, Niddrie, VIC, 3042" },
  { school: "Nilma Primary School", campus: "Nilma Primary School", address: "76 Bloomfield Road, Nilma, VIC, 3821" },
  { school: "Noble Park English Language School", campus: "Noble Park English Language School", address: "21-35 Thomas Street, Noble Park, VIC, 3174" },
  { school: "Noble Park Primary School", campus: "Noble Park Primary School", address: "65 Buckley Street, Noble Park, VIC, 3174" },
  { school: "Noble Park Secondary College", campus: "Noble Park Secondary College", address: "3 Callaghan Street, Noble Park, VIC, 3174" },
  { school: "Noojee Primary School", campus: "Noojee Primary School", address: "65 School Road, Noojee, VIC, 3833" },
  { school: "Noorat Primary School", campus: "Noorat Primary School", address: "MacKinnon's Bridge Road, Noorat, VIC, 3265" },
  { school: "Norris Bank Primary School", campus: "Norris Bank Primary School", address: "146 Settlement Road, Bundora, VIC, 3083" },
  { school: "North Geelong Secondary College", campus: "North Geelong Secondary College", address: "86-132 Separation Street, North Geelong, VIC, 3215" },
  { school: "North Melbourne Primary School", campus: "North Melbourne Primary School", address: "210 Errol Street, North Melbourne, VIC, 3051" },
  { school: "Northcote High School", campus: "Northcote High School", address: "St George Road, Northcote, VIC, 3070" },
  { school: "Northcote Primary School", campus: "Northcote Primary School", address: "33 Helen Street, Northcote, VIC, 3070" },
  { school: "Northern Bay P-12 College", campus: "Northern Bay P-12 College", address: "" },
  { school: "Northern College of the Arts and Technology", campus: "Northern College of the Arts and Technology", address: "62 Murray Road, Preston, VIC, 3072" },
  { school: "Northern School For Autism", campus: "Northern School For Autism", address: "" },
  { school: "Norwood Secondary College", campus: "Norwood Secondary College", address: "Byron Street, Ringwood, VIC, 3134" },
  { school: "Nossal High School", campus: "Nossal High School", address: "Sir Gustav Nossal Boulevard, Berwick, VIC, 3806" },
  { school: "Nowa Nowa Primary School", campus: "Nowa Nowa Primary School", address: "Gorge Road, Nowa Nowa, VIC, 3887" },
  { school: "Nullawarre and District Primary School", campus: "Nullawarre and District Primary School", address: "132 Henry's Sawmill Road, Nullawarre, VIC, 3268" },
  { school: "Nullawil Primary School", campus: "Nullawil Primary School", address: "Church Street, Nullawil, VIC, 3529" },
  { school: "Numurkah Primary School", campus: "Numurkah Primary School", address: "12 Quinn Street, Numurkah, VIC, 3636" },
  { school: "Numurkah Secondary College", campus: "Numurkah Secondary College", address: "104 Tocumwal Road, Numurkah, VIC, 3636" },
  { school: "Nungurner Primary School", campus: "Nungurner Primary School", address: "455 Nungurner Road, Nungurner, VIC, 3909" },
  { school: "Nyah District Primary School", campus: "Nyah District Primary School", address: "53-59 Monash Avenue, Nyah West, VIC, 3595" },
  { school: "Nyora Primary School", campus: "Nyora Primary School", address: "Grundy Avenue, Nyora, VIC, 3987" },
  { school: "Oak Park Primary School", campus: "Oak Park Primary School", address: "Willett Avenue, Oak Park, VIC, 3046" },
  { school: "Oakleigh Primary School", campus: "Oakleigh Primary School", address: "20 Warrigal Road, Oakleigh, VIC, 3166" },
  { school: "Oakleigh South Primary School", campus: "Oakleigh South Primary School", address: "Riley Street, Oakleigh South, VIC, 3167" },
  { school: "Oakwood School", campus: "Caulfield Park Community Campus", address: "319 Balaclava Road, Caulfield North, VIC, 3161" },
  { school: "Oakwood School", campus: "Career Pathway Centre", address: "1/31/33 Key Street, Frankston, VIC, 3199" },
  { school: "Oakwood School", campus: "Oakwood School VCAL Campus", address: "15 Chelsea Road, Chelsea, VIC, 3196" },
  { school: "Oakwood School", campus: "Frankston Campus", address: "Robinsons Road, Frankston South, VIC, 3199" },
  { school: "Oakwood School", campus: "Noble Park Campus", address: "1 Camellia Avenue, Noble Park North, VIC, 3174" },
  { school: "Oatlands Primary School", campus: "Oatlands Primary School", address: "79-93 Kurrajong Road, Narre Warren, VIC, 3805" },
  { school: "Oberon High School", campus: "Oberon High School", address: "163 Batten Road, Charlemont, VIC, 3217" },
  { school: "Oberon Primary School", campus: "Oberon Primary School", address: "28 Dorothy Avenue, Belmont, VIC, 3216" },
  { school: "Oberon South Primary School", campus: "Oberon South Primary School", address: "Tintinara Crescent, Belmont, VIC, 3216" },
  { school: "Ocean Grove Primary School", campus: "Ocean Grove Primary School", address: "1-23 Draper Street, Ocean Grove, VIC, 3226" },
  { school: "Officer Primary School", campus: "Officer Primary School", address: "13-23 Tivendale Rd, Offficer, VIC, 3809" },
  { school: "Officer Secondary College", campus: "Officer Secondary College", address: "1 Parker Street, Officer, VIC, 3809" },
  { school: "Officer Specialist School", campus: "Officer Specialist School", address: "3 Parker Street, Officer, VIC, 3809" },
  { school: "Old Orchard Primary School", campus: "Old Orchard Primary School", address: "88 Koonung Road, Blackburn North, VIC, 3130" },
  { school: "Olinda Primary School", campus: "Olinda Primary School", address: "45 Charlemont Lane, Olinda, VIC, 3788" },
  { school: "Omeo Primary School", campus: "Omeo Primary School", address: "167 Day Avenue, Omeo, VIC, 3898" },
  { school: "Orbost North Primary School", campus: "Orbost North Primary School", address: "Rupert St, Orbost, VIC, 3888" },
  { school: "Orbost Primary School", campus: "Orbost Primary School", address: "10 Ruskin Street, Orbost, VIC, 3888" },
  { school: "Orbost Secondary College", campus: "Orbost Secondary College", address: "20 Arnold Street, Orbost, VIC, 3888" },
  { school: "Orbost Secondary College", campus: "Community VCAL Campus", address: "Orbost SES, Wolseley Street, VIC, 3888" },
  { school: "Orchard Grove Primary School", campus: "Orchard Grove Primary School", address: "101 Orchard Grove, Blackburn South, VIC, 3130" },
  { school: "Orchard Park Primary School", campus: "Orchard Park Primary School", address: "291 Pink Hill Boulevard, Officer, VIC, 3809" },
  { school: "Ormond Primary School", campus: "Ormond Primary School", address: "121 Wheatley Road, Ormond, VIC, 3204" },
  { school: "Orrvale Primary School", campus: "Orrvale Primary School", address: "300 Channel Road, Orrvale, VIC, 3631" },
  { school: "Osborne Primary School", campus: "Osborne Primary School", address: "120 Craige Rd, Mount Martha, VIC, 3934" },
  { school: "Osbornes Flat Primary School", campus: "Osbornes Flat Primary School", address: "350 Osbornes Flat Road, Osbornes Flat, VIC,3691" },
  { school: "Outdoor School", campus: "Outdoor School", address: "1 Black Possum Road, Bogong, VIC, 3699" },
  { school: "Outdoor School", campus: "Fifteen Mile Creek Camp", address: "692 Benalla Whitfield Road, Greta south, VIC, 3675" },
  { school: "Ouyen P-12 College", campus: "Ouyen P-12 College", address: "1-35 Fuller Street, Ouyen, VIC, 3490" },
  { school: "Overport Primary School", campus: "Overport Primary School", address: "Towerhill Road, Frankston, VIC, 3199" },
  { school: "Oxley Primary School", campus: "Oxley Primary School", address: "1050 Wangaratta-Whitfield Road, Oxley, VIC, 3678" },
  { school: "Pakenham Consolidated School", campus: "Pakenham Consolidated School", address: "2 Rundwell Way, Pakenham, VIC, 3180" },
  { school: "Pakenham Hills Primary School", campus: "Pakenham Hills Primary School", address: "15 Kennedy Road, Pakenham, VIC, 3180" },
  { school: "Pakenham Lakeside Primary School", campus: "Pakenham Lakeside Primary School", address: "23 Shearwater Drive, Pakenham, VIC, 3810" },
  { school: "Pakenham Primary School", campus: "Pakenham Primary School", address: "50 Atlantic Drive, Pakenham, VIC, 3810" },
  { school: "Pakenham Secondary College", campus: "Pakenham Secondary College", address: "1020 Princes Highway, Pakenham, VIC, 3810" },
  { school: "Pakenham Springs Primary School", campus: "Pakenham Springs Primary School", address: "5 Livingstone Boulevard, Pakenham, VIC, 3810" },
  { school: "Panmure Primary School", campus: "Panmure Primary School", address: "8759 Princes Highway, Panmure, VIC, 3265" },
  { school: "Panton Hill Primary School", campus: "Panton Hill Primary School", address: "585 Kangaroo Grd-St Andrews Rd, Panton Hill, VIC, 3759" },
  { school: "Park Orchards Primary School", campus: "Park Orchards Primary School", address: "1-3 Bowmore Avenue, Park Orchards, VIC, 3114" },
  { school: "Park Ridge Primary School", campus: "Park Ridge Primary School", address: "Wentworth Avenue, Rowville, VIC, 3178" },
  { school: "Parkdale Primary School", campus: "Parkdale Primary School", address: "305-311 Nepean Highway, Parkdale, VIC, 3195" },
  { school: "Parkdale Secondary College", campus: "Parkdale Secondary College", address: "Warren Road, Mordialloc, VIC, 3195" },
  { school: "Parkhill Primary School", campus: "Parkhill Primary School", address: "4a Parkhill Drive, Ashwood, VIC, 3147" },
  { school: "Parkmore Primary School", campus: "Parkmore Primary School", address: "38-54 Jolimont Road, Forest Hill, VIC, 3131" },
  { school: "Parktone Primary School", campus: "Parktone Primary School", address: "Robert Street, Parkdale, VIC, 3195" },
  { school: "Parkville College", campus: "Ascot Vale Secure Welfare Campus", address: "259 Ascot Vale Road, Ascot Vale, VIC, 3032" },
  { school: "Parkville College", campus: "Malmsbury Campus", address: "Mollison Street, Malmsbury, VIC, 3446" },
  { school: "Parkville College", campus: "Maribyrnong Secure Welfare Campus", address: "26-32 Bloomfield Avenue, Maribyrnong, VIC, 3032" },
  { school: "Parkville College", campus: "Parkville Campus", address: "900-930 Park Street, Parkville, VIC, 3052" },
  { school: "Parkwood Green Primary School", campus: "Parkwood Green Primary School", address: "Cnr Gourlay Rd & Community Hub, hillside, VIC, 3037" },
  { school: "Pascoe Vale Girls Secondary College", campus: "Pascoe Vale Girls Secondary College", address: "Lake Avenue, Pascoe Vale, VIC, 3044" },
  { school: "Pascoe Vale North Primary School", campus: "Pascoe Vale North Primary School", address: "Kent Road, Pascoe Vale, VIC, 3044" },
  { school: "Pascoe Vale Primary School", campus: "Pascoe Vale Primary School", address: "362 Gaffney Street, Pascoe Vale, VIC, 3044" },
  { school: "Pascoe Vale South Primary School", campus: "Pascoe Vale South Primary School", address: "411-429 Reynard Street, Pascoe Vale South, VIC, 3044" },
  { school: "Patterson Lakes Primary School", campus: "Patterson Lakes Primary School", address: "130-148 Gladesville Boulevard, Patterson lakes, VIC, 3197" },
  { school: "Patterson River Secondary College", campus: "Patterson River Secondary College", address: "78-98 Eel race road, Seaford, VIC, 3198" },
  { school: "Paynesville Primary School", campus: "Paynesville Primary School", address: "31-47 Ashley Street, Paynesville, VIC, 3880" },
  { school: "Pearcedale Primary School", campus: "Pearcedale Primary School", address: "Baxter-tooradin Road, Pearcedale, VIC, 3912" },
  { school: "Pearcedale Primary School", campus: "Pearcedale Deaf Facility", address: "Baxter Tooradin Road, Pearcedale, VIC, 3912" },
  { school: "Pembroke Primary School", campus: "Pembroke Primary School", address: "Pembroke Road, Mooroolbark, VIC, 3138" },
  { school: "Penders Grove Primary School", campus: "Penders Grove Primary School", address: "370 Victoria Road, Thornbury, VIC, 3071" },
  { school: "Peninsula Specialist College", campus: "Peninsula Specialist College", address: "25 Old White Hill Road, Dromana, VIC, 3936" },
  { school: "Penshurst Primary School", campus: "Penshurst Primary School", address: "87 Ritchie Street, Penhurst, VIC, 3289" },
  { school: "Pentland Primary School", campus: "Pentland Primary School", address: "164 Halletts Way, Bacchus Marsh, VIC, 3340" },
  { school: "Peranbin Primary College", campus: "Violet Town Campus", address: "Tulip street, Violet Town, VIC, 3669" },
  { school: "Peranbin Primary College", campus: "Swanpool Campus", address: "Midland Highway, Swanpool, VIC, 3673" },
  { school: "Peranbin Primary College", campus: "Strathbogie Campus", address: "19-25 Main Street, Strathbogie, VIC, 3666" },
  { school: "Peranbin Primary College", campus: "Baddaginnie Campus", address: "Clarendon Street, Baddaginnie, VIC, 3670" },
  { school: "Perseverance Primary School", campus: "Perseverance Primary School", address: "Centreway, French Island, VIC, 3921" },
  { school: "Peter Lalor Secondary College", campus: "Peter Lalor Secondary College", address: "35 Duncan Road, Lalor, VIC, 3075" },
  { school: "Phoenix P-12 Community College", campus: "Sebastopol Campus", address: "53-71 Herford Street, Sebastopol, VIC, 3356" },
  { school: "Phoenix P-12 Community College", campus: "Redan Campus", address: "32-48 Hertford Street, Sebastopol, VIC, 3356" },
  { school: "Pinewood Primary School", campus: "Pinewood Primary School", address: "27-35 Pinewood Drive, Mount Waverley, VIC, 3149" },
  { school: "Pleasant Street Primary School (Ballarat)", campus: "Pleasant Street Primary School (Ballarat)", address: "101 Pleasant St North, Lake Wendouree, VIC, 3350" },
  { school: "Plenty Parklands Primary School", campus: "Plenty Parklands Primary School", address: "48 Blosssom Park Drive, Mill Park, VIC, 3082" },
  { school: "Point Cook Prep - Year 9 College", campus: "Point Cook Prep - Year 9 College", address: "18-50 Ponsford Drive, Point Cook, VIC, 3030" },
  { school: "Point Cook Senior Secondary College", campus: "Point Cook Senior Secondary College", address: "Cnr Boardwalk Blvd & Bergamot, Point Cook, VIC, 3030" },
  { school: "Point Lonsdale Primary School", campus: "Point Lonsdale Primary School", address: "22 Bowen Street, Point Lonsdale, VIC, 3225" },
  { school: "Pomonal Primary School", campus: "Pomonal Primary School", address: "2859 Ararat - Halls Gap Road, Pomonal, VIC, 3381" },
  { school: "Poowong Consolidated School", campus: "Poowong Consolidated School", address: "5 Gardner Lane, Poowong, VIC, 3988" },
  { school: "Porepunkah Primary School", campus: "Porepunkah Primary School", address: "2 Martley Street, Porepunkah, VIC, 3740" },
  { school: "Port Fairy Consolidated School", campus: "Port Fairy Consolidated School", address: "Albert Street, Port Fairy, VIC, 3284" },
  { school: "Port Melbourne Primary School", campus: "Port Melbourne Primary School", address: "415 Graham Street, Port Melbourne, VIC, 3207" },
  { school: "Port Phillip Specialist School", campus: "Port Phillip Specialist School", address: "Cnr Nott & Pool Streets, Port Melbourne, VIC, 3207" },
  { school: "Portarlington Primary School", campus: "Portarlington Primary School", address: "100 Newcombe Street, Portarlington, VIC, 3223" },
  { school: "Portland Bay School", campus: "Portland Bay School", address: "77 Henty Street, Portland, VIC, 3305" },
  { school: "Portland North Primary School", campus: "Portland North Primary School", address: "42 School Road, Portland North, VIC, 3305" },
  { school: "Portland Primary School", campus: "Portland Primary School", address: "45 Palmer Street, Portland, VIC, 3305" },
  { school: "Portland Secondary College", campus: "Portland Secondary College", address: "Must Street, Portland, VIC, 3305" },
  { school: "Portland South Primary School", campus: "Portland South Primary School", address: "133-141 Edgar Street, Portland, VIC, 3305" },
  { school: "Powlett River Primary School", campus: "Powlett River Primary School", address: "9-21 Bent Street, Dalyston, VIC, 3992" },
  { school: "Prahran High School", campus: "Prahran High School", address: "138 High Street, Windsor, VIC, 3181" },
  { school: "Preston High School", campus: "Preston High School", address: "2-16 Cooma Street, Preston, VIC, 3072" },
  { school: "Preston North East Primary School", campus: "Preston North East Primary School", address: "93 Tyler Street, East Preston, VIC, 3072" },
  { school: "Preston Primary School", campus: "Preston Primary School", address: "240 Tyler Street, Preston, VIC, 3072" },
  { school: "Preston South Primary School", campus: "Preston South Primary School", address: "56B Hotham Street, Preston, VIC, 3072" },
  { school: "Preston West Primary School", campus: "Preston West Primary School", address: "383 Murray Road, Preston, VIC, 3072" },
  { school: "Princes Hill Primary School", campus: "Princes Hill Primary School", address: "280 Pigdon Street, Carlton North, VIC, 3054" },
  { school: "Princes Hill Secondary College", campus: "Princes Hill Secondary College", address: "Arnold Street, Princes Hill, VIC, 3054" },
  { school: "Princes Hill Secondary College", campus: "Mirrimbah Country Centre", address: "Sawmill Settlement, Mirrimbah, VIC, 3723" },
  { school: "Puckapunyal Primary School", campus: "Puckapunyal Primary School", address: "Alamein Road, Puckapunyal, VIC, 3662" },
  { school: "Pyalong Primary School", campus: "Pyalong Primary School", address: "10-14 Bourke Street, Pyalong, VIC, 3521" },
  { school: "Pyramid Hill College", campus: "Pyramid Hill College", address: "101 Kelly Street, Pyramid Hill, VIC, 3575" },
  { school: "Quarry Hill Primary School", campus: "Quarry Hill Primary School", address: "25 Peel Street, Quarry Hill, VIC, 3550" },
  { school: "Queenscliff Primary School", campus: "Queenscliff Primary School", address: "Cnr Stokes & Stevens Street, VIC, 3225" },
  { school: "Rainbow P-12 College", campus: "Rainbow P-12 College", address: "17 Albert Street, Rainbow, VIC, 3424" },
  { school: "Ramlegh Park Primary School", campus: "Ramlegh Park Primary School", address: "34 Thoroughbred Drive, Clyde North, VIC, 3978" },
  { school: "Ranfurly Primary School", campus: "Ranfurly Primary School", address: "348 Ontario Avenue, Mildura, VIC, 3500" },
  { school: "Rangebank Primary School", campus: "Rangebank Primary School", address: "14-36 Lesdon Avenue, Cranbourne, VIC, 3977" },
  { school: "Rangeview Primary School", campus: "Rangeview Primary School", address: "27 Churinga Avenue, Mitcham, VIC, 3132" },
  { school: "Rawson Primary School", campus: "Rawson Primary School", address: "Knotts Siding Road, Rawson, VIC, 3825" },
  { school: "Raywood Primary School", campus: "Raywood Primary School", address: "18-22 Sandhurst Street, Raywood, VIC, 3570" },
  { school: "Red Cliffs East Primary School", campus: "Red Cliffs East Primary School", address: "Block 67a Nerrum Avenue, Red Cliffs, VIC, 3496" },
  { school: "Red Cliffs Primary School", campus: "Red Cliffs Primary School", address: "Murray Avenue, Red Cliffs, VIC, 3496" },
  { school: "Red Cliffs Secondary College", campus: "Red Cliffs Secondary College", address: "55 Fitzroy Avenue, Red Cliffs, VIC, 3496" },
  { school: "Red Cliffs Secondary College", campus: "FLO Connect Campus", address: "78 Pine Avenue, Mildura, VIC, 3500" },
  { school: "Red Hill Consolidated School", campus: "Red Hill Consolidated School", address: "341 Arthurs Seat Road, Red Hill, VIC, 3937" },
  { school: "Redesdale Mia Mia Primary School", campus: "Redesdale Mia Mia Primary School", address: "31 School Road, Redesdale, VIC, 3444" },
  { school: "Regency Park Primary School", campus: "Regency Park Primary School", address: "Amesbury Avenue, Wantirna, VIC, 3152" },
  { school: "Research Primary School", campus: "Research Primary School", address: "1570 Main Road, Research, VIC, 3095" },
  { school: "Reservoir East Primary School", campus: "Reservoir East Primary School", address: "58 Boldrewood Parade, Reservoir, VIC, 3073" },
  { school: "Reservoir High School", campus: "Reservoir Campus    ", address: "855 Plenty Road, Reservoir, VIC, 3073" },
  { school: "Reservoir High School", campus: "Heidelberg Secondary Teaching Unit", address: "855 Plenty Road, Reservoir, VIC, 3073" },
  { school: "Reservoir Primary School", campus: "Reservoir Primary School", address: "Duffy Street, Reservoir, VIC, 3073" },
  { school: "Reservoir Views Primary School", campus: "Reservoir Views Primary School", address: "73-91 Hickford Street, Reservoir, VIC, 3073" },
  { school: "Reservoir West Primary School", campus: "Reservoir West Primary School", address: "5a Carrington Road, Reservoir, VIC, 3073" },
  { school: "Richmond High School", campus: "Gleadell Street Campus", address: "6 Gleadell Street, Richmond, VIC, 3121" },
  { school: "Richmond High School", campus: "Griffiths Street Campus", address: "4 Griffiths Street, Richmond, VIC, 3121" },
  { school: "Richmond Primary School", campus: "Richmond Primary School", address: "85 Brighton Street, Richmond, VIC, 3121" },
  { school: "Richmond West Primary School", campus: "Richmond West Primary School", address: "25 Lennox Street, Richmond, VIC, 3121" },
  { school: "Riddells Creek Primary School", campus: "Riddells Creek Primary School", address: "69-77 Mains Road, Riddells Creek, VIC, 3431" },
  { school: "Ringwood Heights Primary School", campus: "Ringwood Heights Primary School", address: "60 Summit Crescent, Ringwood North, VIC, 3134" },
  { school: "Ringwood North Primary School", campus: "Ringwood North Primary School", address: "172 Oban Road, Ringwood North, VIC, 3134" },
  { school: "Ringwood Secondary College", campus: "Ringwood Secondary College", address: "Bedford, Ringwood, VIC, 3134" },
  { school: "Ripplebrook Primary School", campus: "Ripplebrook Primary School", address: "2260 Westernport, Ripplebrook, VIC, 3818" },
  { school: "Ripponlea Primary School", campus: "Ripponlea Primary School", address: "25 Carrington Grove, St Kilda East, VIC, 3183" },
  { school: "River Gum Primary School", campus: "River Gum Primary School", address: "63 Fordholm Road, Hampton Park, VIC, 3976" },
  { school: "Riverbend Primary School", campus: "Riverbend Primary School", address: "1 Communal Road, Wyndham Vale, VIC, 3024" },
  { school: "Riverwalk Primary School", campus: "Riverwalk Primary School", address: "56 Timbarra Avenue, Werribee, VIC, 3030" },
  { school: "Roberts McCubbin Primary School", campus: "Roberts McCubbin Primary School", address: "57 Birdwood Street, Box Hill South, VIC, 3128" },
  { school: "Robinvale College", campus: "Robinvale College", address: "88 George Street, Robinvale, VIC, 3549" },
  { school: "Rochester Primary School", campus: "Rochester Primary School", address: "2 George Street, Rochesrer, VIC, 3561" },
  { school: "Rochester Secondary College", campus: "Rochester Secondary College", address: "Edward Street, Rochester, VIC, 3561" },
  { school: "Rockbank Primary School", campus: "Rockbank Primary School", address: "97-105 Westcott parade, Rockbank, VIC, 3335" },
  { school: "Rokewood Primary School", campus: "Rokewood Primary School", address: "38 Aitchison Street, Rokewood, VIC, 3330" },
  { school: "Rolling Hills Primary School", campus: "Rolling Hills Primary School", address: "52 Lanscape Drive, Mooroolbark, VIC, 3138" },
  { school: "Rollins Primary School", campus: "Rollins Primary School", address: "Wolseley Grove, Bell Post Hill, VIC, 3215" },
  { school: "Romsey Primary School", campus: "Romsey Primary School", address: "2-56 Station Street, Romsey, VIC, 3434" },
  { school: "Rosamond Special School", campus: "Rosamond Special School", address: "276 Ballarat Road, Braybrook, VIC, 3019" },
  { school: "Rosanna Golf Links Primary School", campus: "Rosanna Golf Links Primary Campus", address: "Interlaken Parade, Rosanna, VIC, 3084" },
  { school: "Rosanna Golf Links Primary School", campus: "Rosanna Golf Links Deaf Facility", address: "Interlaken Parade, Rosanna, VIC, 3084" },
  { school: "Rosanna Primary School", campus: "Rosanna Primary School", address: "Grandview Grove, Rosanna, VIC, 3084" },
  { school: "Rosebud Primary School", campus: "Rosebud Primary School", address: "Point Nepean Road, Rosebud, VIC, 3939" },
  { school: "Rosebud Secondary College", campus: "Rosebud Secondary College", address: "245 Eastbourne Road, Rosebud, VIC, 3939" },
  { school: "Rosedale Primary School", campus: "Rosedale Primary School", address: "1-23 Cricket Street, Rosedale, VIC, 3847" },
  { school: "Rosehill Secondary College", campus: "Rosehill Secondary College", address: "Sapphire Street, Niddrie, VIC, 3042" },
  { school: "Rosewood Downs Primary School", campus: "Rosewood Downs Primary School", address: "25 Murray Road, Dandenoong North, VIC, 3175" },
  { school: "Roslyn Primary School", campus: "Roslyn Primary School", address: "183 Roslyn Road, Belmont, VIC, 3216" },
  { school: "Rowellyn Park Primary School", campus: "Rowellyn Park Primary School", address: "Rowellyn Avenue, Carrum Downs, VIC, 3201" },
  { school: "Rowville Primary School", campus: "Rowville Primary School", address: "Paratea Drive, Rowville, VIC, 3178" },
  { school: "Rowville Secondary College", campus: "Rowville Secondary College", address: "17 Paratea Drive, Rowville, VIC, 3178" },
  { school: "Rowville Secondary College", campus: "Eastern Campus", address: "Humphreys War, Rowville, VIC, 3178" },
  { school: "Roxburgh College", campus: "Roxburgh College", address: "60-70 Donald Cameron Drive, Roxburgh Park, VIC, 3064" },
  { school: "Roxburgh College", campus: "The Gateway School", address: "33-37 Pearcedale Parade, Broadmeadows, VIC, 3047" },
  { school: "Roxburgh Homestead Primary School", campus: "Roxburgh Homestead Primary School", address: "21-33 Mcpherson Boulevard, Roxburgh Park, VIC, 3064" },
  { school: "Roxburgh Park Primary School", campus: "Roxburgh Park Primary School", address: "7-21 Almands Avenue, Roxburgh Park, VIC, 3064" },
  { school: "Roxburgh Rise Primary School", campus: "Roxburgh Rise Primary School", address: "35-59 Lockwood Drive, Roxburgh Park, VIC, 3064" },
  { school: "Royal Childrens Hospital Education Institute", campus: "Royal Childrens Hospital Education Institute", address: "Suite 1.4, level 1, Parkville, VIC, 3052" },
  { school: "Rubicon Outdoor Centre", campus: "Nayook Campus", address: "610 Nayook-Powelltown Road, Nayook, VIC, 3832" },
  { school: "Rubicon Outdoor Centre", campus: "Thornton Campus", address: "264 Rubicon Road, Thornton, VIC, 3712" },
  { school: "Rupanyup Primary School", campus: "Rupanyup Primary School", address: "22 Dyer Street, Rupanyup, VIC, 3388" },
  { school: "Rushworth P-12 College", campus: "Rushworth P-12 College", address: "45 Heily Street, Rushworth, VIC, 3612" },
  { school: "Ruskin Park Primary School", campus: "Ruskin Park Primary School", address: "18-20 Ruskin Ave, Croydon, VIC, 3136" },
  { school: "Rutherglen High School", campus: "Rutherglen High School", address: "40 Sheridans Bridge Road, Rutherglen, VIC, 3685" },
  { school: "Rutherglen Primary School", campus: "Rutherglen Primary School", address: "44 Murray Street, Rutherglen, VIC, 3685" },
  { school: "Rye Primary School", campus: "Rye Primary School", address: "Lyons St, Rye, VIC, 3941" },
  { school: "Sale and District Specialist School", campus: "Sale and District Specialist School", address: "333 Princes Highway, VIC, 3850" },
  { school: "Sale College", campus: "Guthridge Campus", address: "Guthridge Parade, Sale, VIC, 3850" },
  { school: "Sale College", campus: "Macalister Campus", address: "73-81 Macalister Street, Sale, VIC, 3850" },
  { school: "Sale Primary School", campus: "Sale Primary School", address: "Macalister Street, Sale, VIC, 3850" },
  { school: "Saltwater P-9 College", campus: "Saltwater P-9 College", address: "15 Kirra Place, Point Cook, VIC, 3030" },
  { school: "San Remo Primary School", campus: "San Remo Primary School", address: "22 Bergin Grove, San Remo, VIC, 3925" },
  { school: "Sandringham College", campus: "Holloway Road 10-12 Campus", address: "11 Holloway Road, Sandringham, VIC, 3191" },
  { school: "Sandringham College", campus: "Bluff Road 7-9 Campus", address: "356 Bluff Road, sandringham, VIC, 3191" },
  { school: "Sandringham East Primary School", campus: "Sandringham East Primary School", address: "9 Holloway Road, Sandringham, VIC, 3191" },
  { school: "Sandringham Primary School", campus: "Sandringham Primary School", address: "31 Bamfield Street, Sandringham, VIC, 3191" },
  { school: "Sassafras Primary School", campus: "Sassafras Primary School", address: "399 Mt Dandenong Tourist Road, VIC, 3787" },
  { school: "Scoresby Primary School", campus: "Scoresby Primary School", address: "11 Ingrid Street, Scoresby, VIC, 3179" },
  { school: "Scoresby Secondary College", campus: "Scoresby Secondary College", address: "Cavell Street, Scoresby, VIC, 3179" },
  { school: "Seabrook Primary School", campus: "Seabrook Primary School", address: "83-105 Point Cook Road, Seabrook, VIC, 3028" },
  { school: "Seaford North Primary School", campus: "Seaford North Primary School", address: "81 Hallifax Street, Seaford, VIC, 3198" },
  { school: "Seaford Park Primary School", campus: "Seaford Park Primary School", address: "141 East Road, Seaford, VIC, 3198" },
  { school: "Seaford Primary School", campus: "Seaford Primary School", address: "Mcrae Street, Seaford, VIC, 3198" },
  { school: "Seaford Primary School", campus: "Seaford Special Assistance Unit", address: "Mcrae Street, Seaford, VIC, 3198" },
  { school: "Seaholme Primary School", campus: "Seaholme Primary School", address: "Cnr Millers Rd & Civic Parade, Seaholme, VIC, 3018" },
  { school: "Seaspray Primary School", campus: "Seaspray Primary School", address: "15 Prospect Road, Seaspray, VIC, 3851" },
  { school: "Sebastopol Primary School", campus: "Sebastopol Primary School", address: "9 Warreen Street, Sebastopol, VIC, 3356" },
  { school: "Selby Primary School", campus: "Selby Primary School", address: "2 Morley Street, Selby, VIC, 3159" },
  { school: "Serpell Primary School", campus: "Serpell Primary School", address: "Tuckers Road, Templestowe, VIC, 3106" },
  { school: "Seville Primary School", campus: "Seville Primary School", address: "639 Warburton Highway, Seville, VIC, 3139" },
  { school: "Seymour College", campus: "Special Campus", address: "29-47 Stewart Street, Seymour, VIC, 3660" },
  { school: "Seymour College", campus: "Seymour College", address: "29-47 Stewart Street, Seymour, VIC, 3660" },
  { school: "Seymour College", campus: "Seymour College Agricultural Block", address: "52 Delatite Road, Seymour, VIC, 3660" },
  { school: "Shelford Primary School", campus: "Shelford Primary School", address: "1717 Shelford Bannockburn Road, Shelford, VIC, 3329" },
  { school: "Shepparton East Primary School", campus: "Shepparton East Primary School", address: "15 School Road, Shepparton East, VIC, 3631" },
  { school: "Sherbourne Primary School", campus: "Sherbourne Primary School", address: "Outlook Crescent, Briar Hill, VIC, 3088" },
  { school: "Sherbrooke Community School", campus: "Sherbrooke Community School", address: "311 Mt Dandenong Tourist Road, Sassafras, VIC, 3787" },
  { school: "Silvan Primary School", campus: "Silvan Primary School", address: "269 Monbulk Road, Silvan, VIC, 3795" },
  { school: "Silverton Primary School", campus: "Silverton Primary School", address: "77-123 Jacksons Road, Noble Park North, VIC, 3174" },
  { school: "Simpson Primary School", campus: "Simpson Primary School", address: "1 Cockayne Street, Simpson, VIC, 3266" },
  { school: "Skene Street School Stawell", campus: "Skene Street School Stawell", address: "7-13 Skene Street, Stawell, VIC, 3380" },
  { school: "Skipton Primary School", campus: "Skipton Primary School", address: "7 Montgomery Street, Skipton, VIC, 3361" },
  { school: "Skye Primary School", campus: "Skye Primary School", address: "395 Ballarto Road, Skye, VIC, 3977" },
  { school: "Solway Primary School", campus: "Solway Primary School", address: "Winton Road, Ashburton, VIC, 3147" },
  { school: "Somers Primary School", campus: "Somers Primary School", address: "87 Camp Hill Road, Somers, VIC, 3927" },
  { school: "Somers School Camp", campus: "Woorabinda Campus", address: "North Shore Road, Yallourn North, VIC, 3825" },
  { school: "Somers School Camp", campus: "Somers Campus", address: "124 Lord Somers Road, Somers, VIC, 3927" },
  { school: "Somerville Primary School", campus: "Somerville Primary School", address: "37 Eramosa Road East, Somerville, VIC, 3912" },
  { school: "Somerville Rise Primary School", campus: "Somerville Rise Primary School", address: "Blacks Camp Road, Somerville, VIC, 3912" },
  { school: "Somerville Secondary College", campus: "Somerville Secondary College", address: "37 Graf Road, Somerville, VIC, 3912" },
  { school: "Sorrento Primary School", campus: "Sorrento Primary School", address: "32 Kerferd Avenue, Sorrento, VIC, 3943" },
  { school: "South Gippsland Specialist School", campus: "South Gippsland Specialist School", address: "13 Horn Street, Leongatha, VIC, 3953" },
  { school: "South Melbourne Park Primary School", campus: "South Melbourne Park Primary School", address: "29 Albert Park Drive South, Albert Park, VIC, 3206" },
  { school: "South Melbourne Primary School", campus: "South Melbourne Primary School", address: "129-161 Ferrars Street, Southbank, VIC, 3006" },
  { school: "South Oakleigh Secondary College", campus: "South Oakleigh Secondary College", address: "Bakers Road, Oakleigh South, VIC, 3167" },
  { school: "South Yarra Primary School", campus: "South Yarra Primary School", address: "601 Punt Road, South Yarra, VIC, 3141" },
  { school: "Southern Autistic School", campus: "Southern Autistic School", address: "12 Margaretta Street, East Bentleigh, VIC, 3165" },
  { school: "Southern Cross Primary School", campus: "Southern Cross Primary School", address: "1-2 David Collins Drive, Endeavour Hills, VIC, 3802" },
  { school: "Southmoor Primary School", campus: "Southmoor Primary School", address: "Rica Street, Moorabbbin, VIC, 3189" },
  { school: "Sovereign Hill School", campus: "Sovereign Hill School", address: "Bradshaw Street, Ballarat, VIC, 3350" },
  { school: "Specimen Hill Primary School", campus: "Specimen Hill Primary School", address: "Inglewood Street, Golden Square, VIC, 3555" },
  { school: "Spensley Street Primary School", campus: "Spensley Street Primary School", address: "193 Spensley Street, Clifton Hill, VIC, 3068" },
  { school: "Spensley Street Primary School", campus: "Glen Forbes Camp", address: "319 Almurta-Glen Forbes Road, VIC, 3990" },
  { school: "Spotswood Primary School", campus: "Spotswood Primary School", address: "Melbourne Road, Spotswood, VIC, 3015" },
  { school: "Spring Gully Primary School", campus: "Spring Gully Primary School", address: "104-110 Spring Gully Road, Bendigo, VIC, 3550" },
  { school: "Spring Parks Primary School", campus: "West Campus", address: "24 Erica Street, Springvale, VIC, 3171" },
  { school: "Spring Parks Primary School", campus: "Valley Campus", address: "27-39 Clarke Road, Sprinvale South, VIC, 3172" },
  { school: "Springhurst Primary School", campus: "Springhurst Primary School", address: "121 Anzac Road, Springhurst, VIC, 3682" },
  { school: "Springside Primary School", campus: "Springside Primary School", address: "22 Becca Way, Caroline Springs, VIC, 3023" },
  { school: "Springside West Secondary College", campus: "Springside West Secondary College", address: "70 City Vista Court, Fraser Rise, VIC, 3336" },
  { school: "Springvale Park Special Developmental School", campus: "Springvale Park Special Developmental School", address: "13-15 Sandown Road, Springvale, VIC, 3171" },
  { school: "Springvale Rise Primary School", campus: "Wareham Street Campus", address: "32-52 Wareham Street, Springvale, VIC, 3171" },
  { school: "Springvale Rise Primary School", campus: "Springvale Road Campus", address: "355A Springvale Road, Springvale, VIC, 3171" },
  { school: "St Albans East Primary School", campus: "St Albans East Primary School", address: "7-17 Station Avenue, St Albans, VIC, 3021" },
  { school: "St Albans East Primary School", campus: "St Albans East Deaf Facility", address: "Station Avenue, St Albans, VIC, 3021" },
  { school: "St Albans Heights Primary School", campus: "St Albans Heights Primary School", address: "Norwich Street, St Albans, VIC, 3021" },
  { school: "St Albans Meadows Primary School", campus: "St Albans Meadows Primary School", address: "2A Laurel Street, St Albans, VIC, 3021" },
  { school: "St Albans North Primary School", campus: "St Albans North Primary School", address: "George Street, St Albans, VIC, 3021" },
  { school: "St Albans Primary School", campus: "St Albans Primary School", address: "39 West Esplanade, St Albans, VIC, 3021" },
  { school: "St Albans Secondary College", campus: "St Albans Secondary College", address: "289 Main Road East, St Albans, VIC, 3021" },
  { school: "St Albans Secondary College", campus: "Strathbogie Camp", address: "Cnr Watkins road and Creek Junction, VIC, 3669" },
  { school: "St Andrews Primary School", campus: "St Andrews Primary School", address: "Caledonia Street, St Andrews, VIC, 3761" },
  { school: "St Arnaud Primary School", campus: "St Arnaud Primary School", address: "Charlton Road, St Arnaud, VIC, 3478" },
  { school: "St Arnaud Secondary College", campus: "St Arnaud Secondary College", address: "Smith Street, St Arnaud, VIC, 3478" },
  { school: "St Georges Road Primary School Shepparton", campus: "St Georges Road Primary School Shepparton", address: "120 St Georges Road, Shepparton, VIC, 3630" },
  { school: "St Georges Road Primary School Shepparton", campus: "Shepparton English Language Centre", address: "130 Hayes Street, Shepparton, VIC, 3632" },
  { school: "St Helena Secondary College", campus: "St Helena Secondary College", address: "Wallowa Road, Eltham North, VIC, 3095" },
  { school: "St Kilda Park Primary School", campus: "St Kilda Park Primary School", address: "68 Fitzroy Street, St Kilda, VIC, 3182" },
  { school: "St Leonards Primary School", campus: "St Leonards Primary School", address: "1341 Murradoc Road, St Leonards, VIC, 3223" },
  { school: "Stanhope Primary School", campus: "Stanhope Primary School", address: "48 Midland Highway, Stanhope, VIC, 3623" },
  { school: "Staughton College", campus: "Staughton College", address: "Wilson Road, Melton South, VIC, 3338" },
  { school: "Stawell Primary School", campus: "Stawell Primary School", address: "14-28 Barnes Street, Stawell, VIC, 3380" },
  { school: "Stawell Secondary College", campus: "Stawell Secondary College", address: "79-97 Patrick Street, Stawell, VIC, 3380" },
  { school: "Stawell West Primary School", campus: "Stawell West Primary School", address: "80-88 Cooper Street, Stawell, VIC, 3380" },
  { school: "Stevensville Primary School", campus: "Stevensville Primary School", address: "52 Kings Road, St Albans, VIC, 3021" },
  { school: "Stratford Primary School", campus: "Stratford Primary School", address: "Cnr Wellsford & Hobson Streets, Stratford, VIC, 3682" },
  { school: "Strathaird Primary School", campus: "Strathaird Primary School", address: "20 Sherwood Road, Narre Warren South, VIC, 3805" },
  { school: "Strathewen Primary School", campus: "Strathewen Primary School", address: "5 School Ridge Road, Strathewen, VIC, 3099" },
  { school: "Strathfieldsaye Primary School", campus: "Strathfieldsaye Primary School", address: "9 Uxbridge Street, Strathfieldsaye, VIC, 3551" },
  { school: "Strathmerton Primary School", campus: "Strathmerton Primary School", address: "Findlay Street, Strathmerton, VIC, 3641" },
  { school: "Strathmore North Primary School", campus: "Strathmore North Primary School", address: "Mascoma Street, Strathmore, VIC, 3041" },
  { school: "Strathmore Primary School", campus: "Strathmore Primary School", address: "14-34 Lloyd Street, Strathmore, VIC, 3041" },
  { school: "Strathmore Secondary College", campus: "Strathmore Secondary College", address: "400 Pascoe Vale Road, Strathmore, VIC, 3041" },
  { school: "Strathtulloh Primary School", campus: "Strathtulloh Primary School", address: "2 Wembley Avenue, Strathtulloh, VIC, 3338" },
  { school: "Streeton Primary School", campus: "Streeton Primary School", address: "209 Yallambie Road, Yallambie, VIC, 3085" },
  { school: "Sunbury And Macedon Ranges Specialist School", campus: "Bullengarook Senior Campus", address: "705 Bacchus Marsh Road, Bullengarook, VIC, 3437" },
  { school: "Sunbury And Macedon Ranges Specialist School", campus: "Sunbury Campus", address: "127-143 Circular Drive, Sunbury, VIC, 3429" },
  { school: "Sunbury College", campus: "Sunbury College", address: "30 Racecourse Road, Sunbury, VIC, 3429" },
  { school: "Sunbury Downs Secondary College", campus: "Sunbury Downs Secondary College", address: "148-174 Mitchells Lane, Sunbury, VIC, 3429" },
  { school: "Sunbury Heights Primary School", campus: "Sunbury Heights Primary School", address: "Charter Road East, Sunbury, VIC, 3429" },
  { school: "Sunbury Primary School", campus: "Sunbury Primary School", address: "41 The Heights,  Sunbury, VIC, 3429" },
  { school: "Sunbury West Primary School", campus: "Sunbury West Primary School", address: "479-491 Elizabeth Drive, Sunbury, VIC, 3429" },
  { school: "Sunshine College", campus: "Sunshine Senior Campus", address: "Graham Street, Sunshine, VIC, 3020" },
  { school: "Sunshine College", campus: "West Sunshine Campus", address: "Lachlan Road, Sunshine West, VIC, 3020" },
  { school: "Sunshine College", campus: "Ardeer Campus", address: "Glengala Road, Ardeer, VIC, 3022" },
  { school: "Sunshine College", campus: "North Sunshine Campus", address: "Northumberland Road, Sunshine North, VIC, 3020" },
  { school: "Sunshine College", campus: "Sunshine Western Region Deaf Facility", address: "Cnr Glengala Rd & Allison St, Ardeer, VIC, 3022" },
  { school: "Sunshine College", campus: "Sunshine Work Education Training Unit", address: "Graham Street, Sunshine, VIC, 3020" },
  { school: "Sunshine Harvester Primary School", campus: "Sunshine Harvester Primary School", address: "132 Hertford Road, Sunshine, VIC, 3020" },
  { school: "Sunshine Heights Primary School", campus: "Sunshine Heights Primary School", address: "1-19 Mailey Street, Sunshine, VIC, 3020" },
  { school: "Sunshine North Primary School", campus: "Sunshine North Primary School", address: "65-71 Suffolk Road, Sunshine North, VIC, 3020" },
  { school: "Sunshine Primary School", campus: "Sunshine Primary School", address: "Cnr Hampshire & Derby Roads, Sunshine, VIC, 3020" },
  { school: "Sunshine Special Developmental School", campus: "Sunshine Special Developmental School", address: "Ivory Court, Sunshine, VIC, 3020" },
  { school: "Surf Coast Secondary College", campus: "Surf Coast Secondary College", address: "75 White Street, Torquay, VIC, 3228" },
  { school: "Surfside Primary School", campus: "Surfside Primary School", address: "31-65 John Dory Drive, Ocean Grove, VIC, 3226" },
  { school: "Surrey Hills Primary School", campus: "Surrey Hills Primary School", address: "2 Beatrice Avenue, Surrey Hills, VIC, 3127" },
  { school: "Suzanne Cory High School", campus: "Suzanne Cory High School", address: "225 hoppers Lane, Werribee, VIC, 3030" },
  { school: "Swan Hill College", campus: "Swan Hill College", address: "10-32 Pye Street, Swan Hill, VIC, 3585" },
  { school: "Swan Hill College", campus: "FLO Campus", address: "Commercial Road, Morwell, VIC, 3840" },
  { school: "Swan Hill North Primary School", campus: "Swan Hill North Primary School", address: "118 Chapman Street, Swan Hill, VIC, 3585" },
  { school: "Swan Hill Primary School", campus: "Swan Hill Primary School", address: "123 Gray Street, Swan Hill, VIC, 3585" },
  { school: "Swan Hill Specialist School", campus: "Swan Hill Specialist School", address: "27-33 Yana Street, Swan Hill, VIC, 3585" },
  { school: "Swan Reach Primary School", campus: "Swan Reach Primary School", address: "21 School Road, Swan Reach, VIC, 3903" },
  { school: "Swifts Creek P-12 School", campus: "Tambo Campus", address: "6840 Great Alpine Road, Swifts Creek, VIC, 3896" },
  { school: "Swifts Creek P-12 School", campus: "Flagstaff Campus", address: "6855 Great Alpine Road, Swifts Creek, VIC, 3896" },
  { school: "Swinburne Senior Secondary College", campus: "Swinburne Senior Secondary College", address: "505 Burwoood Road, Hawthorn, VIC, 3122" },
  { school: "Swinburne Senior Secondary College", campus: "Camberwell Road Campus", address: "120 Camberwell Road, Hawthorn East, VIC, 3123" },
  { school: "Sydenham - Hillside Primary School", campus: "Hillside Campus", address: "Wattle Valley Drive, Hillside, VIC, 3037" },
  { school: "Sydenham - Hillside Primary School", campus: "Sydenham Campus", address: "39-49 Community Hub, Sydenham, VIC, 3037" },
  { school: "Sydney Road Community School", campus: "Sydney Road Community School", address: "350 Sydney Road, Brunswick, VIC, 3056" },
  { school: "Sydney Road Community School", campus: "Coburg Teaching Unit", address: "189 Urquhart Street, Coburg, VIC, 3058" },
  { school: "Sydney Road Community School", campus: "The Brunswick Learning Space", address: "439 Albion Street, Brunswick South, VIC, 3055" },
  { school: "Syndal South Primary School", campus: "Syndal South Primary School", address: "14 Montgomery Avenue, Mount Waverly, VIC, 3149" },
  { school: "Talbot Primary School", campus: "Talbot Primary School", address: "2 Rowe Street, Talbot, VIC, 3371" },
  { school: "Talgarno Primary School", campus: "Talgarno Primary School", address: "14 Talgarno Schoool Lane, Talgarno, VIC, 3691" },
  { school: "Tallangatta Primary School", campus: "Tallangatta Primary School", address: "1 Wonga Grove, Tallangatta, VIC, 3700" },
  { school: "Tallangatta Secondary College", campus: "Tallangatta Secondary College", address: "145 Towong Street, tallangatta, VIC, 3700" },
  { school: "Tallarook Primary School", campus: "Tallarook Primary School", address: "52 Main Road, Tallarook, VIC, 3659" },
  { school: "Tallygaroopna Primary School", campus: "Tallygaroopna Primary School", address: "24-34 Victoria Street, Tallygaroopna, VIC, 3634" },
  { school: "Tambo Upper Primary School", campus: "Tambo Upper Primary School", address: "890 Tambo Upper Road, Tambo Upper, VIC, 3885" },
  { school: "Tanjil South Primary School", campus: "Tanjil South Primary School", address: "253 Moe-Willow Grove Road, Tanjil South, VIC, 3825" },
  { school: "Taradale Primary School", campus: "Taradale Primary School", address: "98 High Street, Taradale, VIC, 3447" },
  { school: "Tarnagulla Primary School", campus: "Tarnagulla Primary School", address: "51 Stanley Street, Tarnagulla, VIC, 3551" },
  { school: "Tarneit P-9 College", campus: "Tarneit P-9 College", address: "21-23 Brinbrook Street, Tarneit, VIC, 3029" },
  { school: "Tarneit Rise Primary School", campus: "Tarneit Rise Primary School", address: "51-71 Hummingbird Boulevard, Tarneit, VIC, 3029" },
  { school: "Tarneit Senior College", campus: "Tarneit Senior College", address: "861 Leakes Road, Tarneit, VIC, 3029" },
  { school: "Tarwin Lower Primary School", campus: "Tarwin Lower Primary School", address: "School Road, Tarwin Lower, VIC, 3956" },
  { school: "Tarwin Valley Primary School", campus: "Tarwin Valley Primary School", address: "6 Geale Street, Meeniyan, VIC, 3956" },
  { school: "Tate Street Primary School Geelong", campus: "Tate Street Primary School Geelong", address: "Tate Street, Geelong East, VIC, 3219" },
  { school: "Tatura Primary School", campus: "Tatura Primary School", address: "28-40 Albert Street, Tatura, VIC, 3616" },
  { school: "Tawonga Primary School", campus: "Tawonga Primary School", address: "Kiewa Valley Highway, Tawonga, VIC, 3697" },
  { school: "Taylors Hill Primary School", campus: "Taylors Hill Primary School", address: "40-50 Loddon Drive, Taylors Hill, VIC, 3037" },
  { school: "Taylors Lakes Primary School", campus: "Taylors Lakes Primary School", address: "21-35 Chichester Drive, Taylors Lakes, VIC, 3038" },
  { school: "Taylors Lakes Secondary College", campus: "Taylors Lakes Secondary College", address: "1-39 Parmelia Drive, Taylors Lakes, VIC, 3038" },
  { school: "Tecoma Primary School", campus: "Tecoma Primary School", address: "1536 Burwood Highway, Tecoma, VIC, 3160" },
  { school: "Teesdale Primary School", campus: "Teesdale Primary School", address: "Main Road, Teesdale, VIC, 3328" },
  { school: "Templestowe College", campus: "Templestowe College", address: "7 Cypress Avenue, Templestowe Lower, VIC, 3107" },
  { school: "Templestowe Heights Primary School", campus: "Templestowe Heights Primary School", address: "276-300 High Street, Templesrowe Lower, VIC, 3107" },
  { school: "Templestowe Park Primary School", campus: "Templestowe Park Primary School", address: "399-409 Church Road, Templestowe, VIC, 3106" },
  { school: "Templestowe Valley Primary School", campus: "Templestowe Valley Primary School", address: "15 Birchwood Avenue, Lower Templestowe, VIC, 3107" },
  { school: "Templeton Primary School", campus: "Templeton Primary School", address: "Crestdale Road, wantirna, VIC, 3152" },
  { school: "Tempy Primary School", campus: "Tempy Primary School", address: "13 Harrison Street, Tempy, VIC, 3489" },
  { school: "Terang College", campus: "5-12 Campus", address: "45 Strong Street, Terang, VIC, 3264" },
  { school: "Terang College", campus: "P-4 Campus", address: "6655 Princes Highway, Terang, VIC, 3264" },
  { school: "The Alpine School", campus: "Dinner Plain Campus", address: "Community House, Dinner Plain, VIC, 3898" },
  { school: "The Alpine School", campus: "Haining Farm Campus", address: "Don Rd (17 Estate Rd), Don Valley, VIC, 3139" },
  { school: "The Alpine School", campus: "Snowy River Campus", address: "Cape Conran Road, Marlo, VIC, 3888" },
  { school: "The Alpine School", campus: "Gnurad Gundidj Campus", address: "253 Blacks Road, Noorat, VIC, 3265" },
  { school: "The Austin School", campus: "The Austin School", address: "145 Studley Road, Heidelberg, VIC, 3084" },
  { school: "The Basin Primary School", campus: "The Basin Primary School", address: "Cnr Liverpool Road & Mountain Highway, The Basin, VIC, 3154" },
  { school: "The Grange P-12 College", campus: "The Grange P-12 College", address: "30 Deloranine Drive, Hoppers Crossing, VIC, 3029" },
  { school: "The Lake Primary School", campus: "The Lake Primary School", address: "225 Seventeenth Street, Cabarita, VIC, 3505" },
  { school: "The Lakes South Morang College", campus: "The Lakes South Morang College", address: "80 Jardier Terrace, South Morang, VIC, 3752" },
  { school: "The Lakes South Morang College", campus: "Primary Campus", address: "275 Gordons Road, South Morang, VIC, 3752" },
  { school: "The Patch Primary School", campus: "The Patch Primary School", address: "53 Kallista-Emerald Road, The Patch, VIC, 3792" },
  { school: "Thomas Chirnside Primary School", campus: "Thomas Chirnside Primary School", address: "85 Walls Road, Werribee, VIC, 3030" },
  { school: "Thomas Mitchell Primary School", campus: "Thomas Mitchell Primary School", address: "67-77 Thomas Mitchell Drive, Endeavours Hills, VIC, 3802" },
  { school: "Thomastown East Primary School", campus: "Thomastown East Primary School", address: "82 Cedar Street, Thomastown, VIC, 3074" },
  { school: "Thomastown Meadows Primary School", campus: "Thomastown Meadows Primary School", address: "13-21 Diplomat Drive, Thomastown, VIC, 3074" },
  { school: "Thomastown Primary School", campus: "Thomastown Primary School", address: "6 Spring Street, Thomastown, VIC, 3074" },
  { school: "Thomastown Secondary College", campus: "Thomastown Secondary College", address: "80-96 Main street, Thomastown, VIC, 3074" },
  { school: "Thomastown West Primary School", campus: "Thomastown West Primary School", address: "98-112 Main Street, Thomastown, VIC, 3074" },
  { school: "Thornbury High School", campus: "Thornbury High School", address: "238 Collins Street, Thornbury, VIC, 3071" },
  { school: "Thornbury Primary School", campus: "Thornbury Primary School", address: "16-22 Hutton Street, Thornbury, VIC, 3071" },
  { school: "Thorpdale Primary School", campus: "Thorpdale Primary School", address: "19 Robinson Street, Thorpdale, VIC, 3835" },
  { school: "Timbarra P-9 College", campus: "Timbarra P-9 College", address: "159-197 Parkhill drive, Berwick, VIC, 3806" },
  { school: "Timboon P-12 School", campus: "Timboon P-12 School", address: "Bailey Street, Timboon, VIC, 3268" },
  { school: "Timor Primary School", campus: "Timor Primary School", address: "395 Bet Bet Creek Road, Timor, VIC, 3465" },
  { school: "Tinternvale Primary School", campus: "Tinternvale Primary School", address: "Tintern Avenue, Ringwood East, VIC, 3135" },
  { school: "Tongala Primary School", campus: "Tongala Primary School", address: "28 Miller Street, Tongala, VIC, 3621" },
  { school: "Tooborac Primary School", campus: "Tooborac Primary School", address: "5185 Northern Highway, Tooborac, VIC, 3522" },
  { school: "Toolamba Primary School", campus: "Toolamba Primary School", address: "69-77 Wren Street, Toolamba, VIC, 3614" },
  { school: "Toolangi Primary School", campus: "Toolangi Primary School", address: "Main Road, Toolangi, VIC, 3777" },
  { school: "Toolern Vale And District Primary School", campus: "Toolern Vale And District Primary School", address: "361 Creamery Road, Toolern Vale, VIC, 3337" },
  { school: "Toongabbie Primary School", campus: "Toongabbie Primary School", address: "Victoria Street, Toongabbie, VIC, 3856" },
  { school: "Toora Primary School", campus: "Toora Primary School", address: "5 Harriet Street, Toora, VIC, 3962" },
  { school: "Tooradin Primary School", campus: "Tooradin Primary School", address: "Bayview Road, Tooradin, VIC, 3980" },
  { school: "Toorak Primary School", campus: "Toorak Primary School", address: "Cantebury Road, Toorak, VIC, 3142" },
  { school: "Toorloo Arm Primary School", campus: "Toorloo Arm Primary School", address: "315 Lake Tyers Beach Road, Lake Tyers Beach, VIC, 3909" },
  { school: "Tootgarook Primary School", campus: "Tootgarook Primary School", address: "Carmichael Street, Tootgarook, VIC, 3941" },
  { school: "Torquay Coast Primary School", campus: "Torquay Coast Primary School", address: "25 Stretton Drive, Torquay, VIC, 3228" },
  { school: "Torquay P-6 College", campus: "Torquay P-6 College", address: "45-55 Grossmans Road, Torquay, VIC, 3228" },
  { school: "Trafalgar High School", campus: "Trafalgar High School", address: "School Road, Trafalgar, VIC, 3824" },
  { school: "Trafalgar Primary School", campus: "Trafalgar Primary School", address: "84 School Road, Trafalgar, VIC, 3824" },
  { school: "Traralgon (Kosciuszko Street) Primary School", campus: "Traralgon (Kosciuszko Street) Primary School", address: "36 Kosciuszko Street, Traralgon, VIC, 3844" },
  { school: "Traralgon (Liddiard Road) Primary School", campus: "Traralgon (Liddiard Road) Campus", address: "148 Liddiard Road, Traralgon, VIC, 3844" },
  { school: "Traralgon (Liddiard Road) Primary School", campus: "Traralgon Deaf Facility", address: "Liddiard Road, Traralgon, VIC, 3844" },
  { school: "Traralgon (Stockdale Road) Primary School", campus: "Traralgon (Stockdale Road) Primary School", address: "7 Stockdale Road, Traralgon, VIC, 3844" },
  { school: "Traralgon College", campus: "West Campus", address: "24 Erica Street, Springvale, VIC, 3171" },
  { school: "Traralgon College", campus: "East Campus", address: "142-146 Liddiard Road, Traralgon, VIC, 3844" },
  { school: "Traralgon South Primary School", campus: "Traralgon South Primary School", address: "13-19 Keith Morgan Drive, Traralgon, South, VIC, 3844" },
  { school: "Travancore School", campus: "Parkville Campus", address: "900-930 Park Street, Parkville, VIC, 3052" },
  { school: "Travancore School", campus: "Travancore School", address: "50 Flemington Street, TRAVANCORE, VIC, 3032" },
  { school: "Trawalla Primary School", campus: "Trawalla Primary School", address: "11 Trawalla Road, Trawalla, VIC, 3373" },
  { school: "Trentham District Primary School", campus: "Trentham District Primary School", address: "8 Bridge Street, Trentham, VIC, 3458" },
  { school: "Truganina P-9 College", campus: "Truganina P-9 College", address: "55 Clearwood Drive, Truganina, VIC, 3029" },
  { school: "Truganina South Primary School", campus: "Truganina South Primary School", address: "3-19 Parkvista Drive, Truganina, VIC, 3029" },
  { school: "Tucker Road Bentleigh Primary School", campus: "Tucker Road Bentleigh Primary School", address: "16 Tucker Rd, Bentleigh, VIC, 3204" },
  { school: "Tullamarine Primary School", campus: "Tullamarine Primary School", address: "Broadmeadows Road, Tullamarine, VIC, 3043" },
  { school: "Tulliallan Primary School", campus: "Tulliallan Primary School", address: "89 Wheelers Pack Drive, Cranbourne North, VIC, 3977" },
  { school: "Tungamah Primary School", campus: "Tungamah Primary School", address: "15-17 Tower Street, Tungamah, VIC, 3728" },
  { school: "Tyabb Primary School", campus: "Tyabb Primary School", address: "186 Mornington-Tyabb Road, Tyabb, VIC, 3913" },
  { school: "Tyabb Railway Station Primary School", campus: "Tyabb Railway Station Primary School", address: "88 The Crescent, Tyabb, VIC, 3913" },
  { school: "Tyers Primary School", campus: "Tyers Primary School", address: "Main Road, Tyers, VIC, 3844" },
  { school: "Tylden Primary School", campus: "Tylden Primary School", address: "11 Clowes Street, Tylden, VIC, 3444" },
  { school: "Tyrrell College", campus: "Tyrrell College", address: "10 Sutcliff Street, Sea Lake, VIC, 3533" },
  { school: "Ultima Primary School", campus: "Ultima Primary School", address: "Cnr Cameron & O'Connor Street, Ultima, VIC, 3544" },
  { school: "Undera Primary School", campus: "Undera Primary School", address: "45-55 Anderson Street, Undera, VIC, 3629" },
  { school: "Underbool Primary School", campus: "Underbool Primary School", address: "67 Cotter Street, Underbool, VIC, 3509" },
  { school: "University High School", campus: "University High School", address: "77 Story Street, Parkville, VIC, 3052" },
  { school: "University High School", campus: "Elizabeth Blackburn School of Sciences", address: "77 Story Street, Parkville, VIC, 3052" },
  { school: "University Park Primary School", campus: "University Park Primary School", address: "Lister Street, St Albans, VIC, 3021" },
  { school: "Upper Ferntree Gully Primary School", campus: "Upper Ferntree Gully Primary School", address: "Talaskia Road, Upper Ferntree Gully, VIC, 3156" },
  { school: "Upper Plenty Primary School", campus: "Upper Plenty Primary School", address: "10 Clarkes Road, Upper Plenty, VIC, 3756" },
  { school: "Upper Sandy Creek Primary School", campus: "Upper Sandy Creek Primary School", address: "1037 Sandy Creek Road, Sandy Creek, VIC, 3695" },
  { school: "Upper Yarra Secondary College", campus: "Upper Yarra Secondary College", address: "81-89 Little Yarra Road, Yarra Junction, VIC, 3797" },
  { school: "Upwey High School", campus: "Upwey High School", address: "1451 Burwood Highway, Upwey, VIC, 3158" },
  { school: "Upwey Primary School", campus: "Upwey Primary School", address: "15 Darling Avenue, Upwey, VIC, 3158" },
  { school: "Upwey South Primary School", campus: "Upwey South Primary School", address: "91 Morris Road, Upwey, VIC, 3158" },
  { school: "Valkstone Primary School", campus: "Valkstone Primary School", address: "44 Valkstone Street, Bentleigh East, VIC, 3165" },
  { school: "Vermont Primary School", campus: "Vermont Primary School", address: "4-10 Nurlendi Road, Vermont, VIC, 3133" },
  { school: "Vermont Secondary College", campus: "Vermont Secondary College", address: "27-63 Morack Road, Vermont, VIC, 3133" },
  { school: "Vermont South Special School", campus: "Vermont South Special School", address: "22-30 Livingstone Road, Vermont South, VIC, 3133" },
  { school: "Verney Road School", campus: "Verney Road School", address: "2 Verney Road, Shepparton, VIC, 3630" },
  { school: "Victoria Road Primary School", campus: "Victoria Road Primary School", address: "44 Victoria Road, Lilydale, VIC, 3140" },
  { school: "Victoria University Secondary College", campus: "Cairnlea Campus", address: "Cnr Ken Jordan Rd and Nobel Banks Dve, Cairnlea, VIC, 3023" },
  { school: "Victoria University Secondary College", campus: "Deer Park Campus", address: "88 Billingham Road, Deer Park, VIC, 3023" },
  { school: "Victoria University Secondary College", campus: "St Albans Campus", address: "5A Jamieson Streeet, St Albans, VIC, 3021" },
  { school: "Victorian College For The Deaf", campus: "Victorian College For The Deaf", address: "597 St Kilda Road, Melbourne, VIC, 3004" },
  { school: "Victorian College Of The Arts Secondary School", campus: "Victorian College Of The Arts Secondary School", address: "57 Miles Street, Southbank, VIC, 3006" },
  { school: "Victorian School Of Languages", campus: "Victorian School Of Languages", address: "315 Clarendon Street, Thornbury, VIC, 3071" },
  { school: "Viewbank College", campus: "Viewbank College", address: "Warren Road, Rosanna, VIC, 3084" },
  { school: "Viewbank Primary School", campus: "Viewbank Primary School", address: "2-10 Nevin Parade, Viewbank, VIC, 3084" },
  { school: "Virtual School Victoria", campus: "Virtual School Victoria", address: "315 Clarendon Street, Thornbury, VIC, 3071" },
  { school: "Waaia Yalca South Primary School", campus: "Waaia Yalca South Primary School", address: "1 Cowan Street, Waaia, VIC, 3637" },
  { school: "Wahgunyah Primary School", campus: "Wahgunyah Primary School", address: "Elizabeth Street, Wahgunyah, VIC, 3687" },
  { school: "Wales Street Primary School", campus: "Wales Street Primary School", address: "Wales Street, Thornbury, VIC, 3071" },
  { school: "Wallan Primary School", campus: "Wallan Primary School", address: "46-48 Queen Street, Wallan, VIC, 3756" },
  { school: "Wallan Secondary College", campus: "Wallan Secondary College", address: "100 Duke Street, Wallan, VIC, 3756" },
  { school: "Wallarano Primary School", campus: "Wallarano Primary School", address: "38-64 Wallarano Drive, Noble Park, VIC, 3174" },
  { school: "Wallaroo Primary School", campus: "Wallaroo Primary School", address: "Stalwart Avenue, Hastings, VIC, 3915" },
  { school: "Wallington Primary School", campus: "Wallington Primary School", address: "608-610 Wallington Road, Wallington, VIC, 3222" },
  { school: "Walwa Primary School", campus: "Walwa Primary School", address: "Murray River Road, Walwa, VIC, 3709" },
  { school: "Wandiligong Primary School", campus: "Wandiligong Primary School", address: "119 School Road, Wandiligong, VIC, 3744" },
  { school: "Wandin North Primary School", campus: "Wandin North Primary School", address: "220 Warburton Highway, Wandin North, VIC, 3139" },
  { school: "Wandin Yallock Primary School", campus: "Wandin Yallock Primary School", address: "105 Beenak Road, Wandin North, VIC, 3139" },
  { school: "Wandong Primary School", campus: "Wandong Primary School", address: "39-51 Rail Street, Wandong,, VIC, 3758" },
  { school: "Wangaratta District Specialist School", campus: "Wangaratta District Specialist School", address: "139 Appin Street, Wangaratta, VIC, 3677" },
  { school: "Wangaratta High School", campus: "Wangaratta High School", address: "17-49 Edwards Street, Wangaratta, VIC, 3677" },
  { school: "Wangaratta Primary School", campus: "Wangaratta Primary School", address: "19 Chisholm Street, Wangaratta, VIC, 3677" },
  { school: "Wangaratta West Primary School", campus: "Wangaratta West Primary School", address: "47-49 Phillipson Street, Wangaratta, VIC, 3677" },
  { school: "Wantirna College", campus: "Wantirna College", address: "90 Harold Street, Wantirna, VIC, 3152" },
  { school: "Wantirna Primary School", campus: "Wantirna Primary School", address: "120 Mountain Highway, Wantirna, VIC, 3152" },
  { school: "Wantirna South Primary School", campus: "Wantirna South Primary School", address: "16 Tyner Road, Wantirna South, VIC, 3152" },
  { school: "Waratah Special Developmental School", campus: "Waratah Special Developmental School", address: "228 Banksia Street, Bellfield, VIC, 3081" },
  { school: "Warburton Primary School", campus: "Warburton Primary School", address: "16 Hornes Road, Warburton, VIC, 3799" },
  { school: "Warracknabeal Primary School", campus: "Warracknabeal Primary School", address: "5 Werrigar Street, Warracknabeal, VIC, 3393" },
  { school: "Warracknabeal Secondary College", campus: "Warracknabeal Secondary College", address: "1 Tregear Street, Warracknabeal, VIC, 3393" },
  { school: "Warracknabeal Secondary College", campus: "Warracknabeal and Community Re-engagement Program", address: "2 Cox Street, Warracknabeal, VIC, 3393" },
  { school: "Warracknabeal Special Developmental School", campus: "Warracknabeal Special Developmental School", address: "5 Werrigar Street, Warracknabeal, VIC, 3393" },
  { school: "Warragul & District Specialist School", campus: "Warragul & District Specialist School", address: "55 Burke Street, Warragul, VIC, 3820" },
  { school: "Warragul North Primary School", campus: "Warragul North Primary School", address: "5 O'Dowds Road, Warragul, VIC, 3820" },
  { school: "Warragul Primary School", campus: "Warragul Primary School", address: "44 Bowen Street, Warragul, VIC, 3820" },
  { school: "Warragul Regional College", campus: "Warragul Regional College", address: "55 Burke Street, Warragul, VIC, 3820" },
  { school: "Warrandyte High School", campus: "Warrandyte High School", address: "241-247 Heidelberg-Warrandyte Rd, Warrandyte, VIC, 3113" },
  { school: "Warrandyte Primary School", campus: "Warrandyte Primary School", address: "5-11 Forbes Street, Warrandyte, VIC, 3113" },
  { school: "Warranwood Primary School", campus: "Warranwood Primary School", address: "1 Wellington Park Drive, Warranwood, VIC, 3134" },
  { school: "Warrenheip Primary School", campus: "Warrenheip Primary School", address: "93 Warrenheip Road, Warrenheip, VIC, 3352" },
  { school: "Warringa Park School", campus: "Cayleys Road Campus", address: "10 Cayleys Road, Werribee South, VIC, 3030" },
  { school: "Warringa Park School", campus: "Bethany Road Campus", address: "Bethany Road, Hoppers Crossing, VIC, 3029" },
  { school: "Warringa Park School", campus: "Warringa Crescent Campus", address: "81 Warringa Crescent, Hoppers Crossing, VIC, 3029" },
  { school: "Warrnambool College", campus: "Warrnambool Campus", address: "Grafton Road, Warrnambool, VIC, 3820" },
  { school: "Warrnambool College", campus: "Wave Campus", address: "91 Hyland Street, Warrnambool, VIC, 3280" },
  { school: "Warrnambool East Primary School", campus: "Warrnambool East Primary School", address: "Ward Street, Warrnambool, VIC, 3280" },
  { school: "Warrnambool Primary School", campus: "Warrnambool Primary School", address: "Jamieson Street, Warrnambool, VIC, 3280" },
  { school: "Warrnambool Special Developmental School", campus: "Warrnambool Special Developmental School", address: "1 Goodall Street, Warrnambool, VIC, 3280" },
  { school: "Warrnambool West Primary School", campus: "Warrnambool West Primary School", address: "35 Hoddle Street, Warrnambool, VIC, 3280" },
  { school: "Watsonia Heights Primary School", campus: "Watsonia Heights Primary School", address: "61 Henry Street, Greensborough, VIC, 3088" },
  { school: "Watsonia North Primary School", campus: "Watsonia North Primary School", address: "16 Sharpes Road, Watsonia North, VIC, 3087" },
  { school: "Watsonia Primary School", campus: "Watsonia Primary School", address: "Nell Street West, Watsonia, VIC, 3087" },
  { school: "Wattle Glen Primary School", campus: "Wattle Glen Primary School", address: "16-24 Reynolds Road, Wattle Glen, VIC, 3096" },
  { school: "Wattle Park Primary School", campus: "Wattle Park Primary School", address: "225 Warrigal Road, Burwood, VIC, 3125" },
  { school: "Wattle View Primary School", campus: "Wattle View Primary School", address: "Wattletree Road, Ferntree Gully, VIC, 3156" },
  { school: "Waubra Primary School", campus: "Waubra Primary School", address: "2110 Sunraysia Highway, Waubra, VIC, 3352" },
  { school: "Waverley Meadows Primary School", campus: "Waverley Meadows Primary School", address: "11 Columbia Drive, Wheelers Hill, VIC, 3150" },
  { school: "Wedderburn College", campus: "Wedderburn College", address: "15-29 Hospital Street, Wedderburn, VIC, 3518" },
  { school: "Wedge Park Primary School", campus: "Wedge Park Primary School", address: "27 Cambrian Way, Melton West, VIC, 3337" },
  { school: "Weeden Heights Primary School", campus: "Weeden Heights Primary School", address: "41-51 Weeden Drive, Vermont South, VIC, 3133" },
  { school: "Weeroona College Bendigo", campus: "Weeroona College Bendigo", address: "383 Napier Street, Bendigo, VIC, 3550" },
  { school: "Weeroona College Bendigo", campus: "Bendigo FLO", address: "101 High Street, Kangaroo Flat, VIC, 3555" },
  { school: "Wellington Secondary College", campus: "Wellington Secondary College", address: "91 Police Road, Mulgrave, VIC, 3170" },
  { school: "Welshpool And District Primary School", campus: "Welshpool And District Primary School", address: "5960 South Gippsland Highway, Welshpool, VIC, 3966" },
  { school: "Wembley Primary School", campus: "Wembley Primary School", address: "32 St Leonards Ave, Yarraville, VIC, 3013" },
  { school: "Wendouree Primary School", campus: "Wendouree Primary School", address: "1224 Howitt Street, Wendouree, VIC, 3555" },
  { school: "Werribee Primary School", campus: "Werribee Primary School", address: "2 Deutgam Street, Werribee, VIC, 3030" },
  { school: "Werribee Secondary College", campus: "Werribee Secondary College", address: "45 Duncans Road, Werribee, VIC, 3030" },
  { school: "Werrimull P-12 School", campus: "Werrimull P-12 School", address: "5 McDonald Street, Werrimull, VIC, 3496" },
  { school: "Wesburn Primary School", campus: "Wesburn Primary School", address: "2850 Warburton Highway, Wesburn, VIC, 3799" },
  { school: "Westall Primary School", campus: "Westall Primary School", address: "Fairbank Road, Clayton South, VIC, 3169" },
  { school: "Westall Secondary College", campus: "Westall Secondary College", address: "88-128 Rosebank Avenue, Clayton South, VIC, 3169" },
  { school: "Westbreen Primary School", campus: "Westbreen Primary School", address: "2-10 Pascoe Street, pascoe Vale, VIC, 3044" },
  { school: "Western Autistic School", campus: "Laverton Campus", address: "1 Burnley Street, Laverton, VIC, 3028" },
  { school: "Western English Language School", campus: "Tottenham Campus", address: "46 South Road, Braybrook, VIC, 3019" },
  { school: "Western English Language School", campus: "Footscray Campus", address: "Hyde Street, Footscray, VIC, 3011" },
  { school: "Western English Language School", campus: "Wyndham Secondary Campus", address: "Shaws Road, Werribee, VIC, 3030" },
  { school: "Western Heights Secondary College", campus: "Western Heights Secondary College", address: "37-61 Vines Road, Hamlyn Heights, VIC, 3215" },
  { school: "Western Heights Secondary College", campus: "Quamby Campus", address: "Quamby Ave, Hamlyn Heights, VIC, 3215" },
  { school: "Western Port Secondary College", campus: "Western Port Secondary College", address: "215 High Street, Hastings, VIC, 3915" },
  { school: "Westgarth Primary School", campus: "Westgarth Primary School", address: "45 Brooke St & 311 Clarke St, Northcote, VIC, 3070" },
  { school: "Westgrove Primary School", campus: "Westgrove Primary School", address: "37a Thames Boulevard, Werribee, VIC, 3030" },
  { school: "Westmeadows Primary School", campus: "Westmeadows Primary School", address: "34-46Riddell Street, Westmeadows, VIC, 3049" },
  { school: "Wheelers Hill Primary School", campus: "Wheelers Hill Primary School", address: "134-148 Whites Lane, Wheelers Hill, VIC, 3150" },
  { school: "Wheelers Hill Secondary College", campus: "Wheelers Hill Secondary College", address: "2-28 Raphael Drive, Wheelers Hill, VIC, 3150" },
  { school: "White Hills Primary School", campus: "White Hills Primary School", address: "16 Plumridge Street, White Hills, VIC, 3550" },
  { school: "Whitehorse Primary School", campus: "Whitehorse Primary School", address: "70-86 Junction Road, Blackburn North, VIC, 3130" },
  { school: "Whitfield District Primary School", campus: "Whitfield District Primary School", address: "6182 Mansfield Road, Whitfield, VIC, 3733" },
  { school: "Whittington Primary School", campus: "Whittington Primary School", address: "Solar Drive, Whittington, VIC, 3219" },
  { school: "Whittlesea Primary School", campus: "Whittlesea Primary School", address: "2425-2435  Plenty Road, Whittlesea, VIC, 3757" },
  { school: "Whittlesea Secondary College", campus: "Whittlesea Secondary College", address: "55 Laurel Street, Whittlesea, VIC, 3757" },
  { school: "Whorouly Primary School", campus: "Whorouly Primary School", address: "18 Church Street, Whorouly, VIC, 3735" },
  { school: "Wilandra Rise Primary School", campus: "Wilandra Rise Primary School", address: "25 Aayana Street, Clyde North, VIC, 3978" },
  { school: "Willaura Primary School", campus: "Willaura Primary School", address: "14 Warranooke Street, Willaura, VIC, 3379" },
  { school: "William Ruthven Primary School", campus: "William Ruthven Primary School", address: "60 Merrilands Road, Reservoir, VIC, 3073" },
  { school: "William Ruthven Secondary College", campus: "William Ruthven Secondary College", address: "60 Merrilands Road, Reservoir, VIC, 3073" },
  { school: "Williamstown High School", campus: "Pasco Campus", address: "76 Pasco Street, Williamstown, VIC, 3016" },
  { school: "Williamstown High School", campus: "Bayview Campus", address: "Bayview Street, Williamstown, VIC, 3016" },
  { school: "Williamstown North Primary School", campus: "Williamstown North Primary School", address: "133 Melbourne Road, Williamstown, VIC, 3016" },
  { school: "Williamstown Primary School", campus: "Williamstown Primary School", address: "Cecil Street, Williamstown, VIC, 3016" },
  { school: "Willmott Park Primary School", campus: "Willmott Park Primary School", address: "54 Dorchester Street, Craigieburn, VIC, 3064" },
  { school: "Willowbank Primary School", campus: "Willowbank Primary School", address: "385 McGeorge Road Gisborne 3437, Gisborne, VIC, 3437" },
  { school: "Willowbank Primary School", campus: "Willow Grove Primary School", address: "39-45 Moe-Willow Grove Road, Willow Grove Road, VIC, 3825" },
  { school: "Willow Grove Primary School", campus: "Willow Grove Primary School", address: "39-45 Moe-Willow Grove Road, Willow Grove Road, VIC, 3825" },
  { school: "Willowmavin Primary School", campus: "Willowmavin Primary School", address: "690 Willowmavin Road, Willowmavin, VIC, 3764" },
  { school: "Wilmot Road Primary School Shepparton", campus: "Wilmot Road Primary School Shepparton", address: "84-90 Wilmort Road, Shepparton, VIC, 3630" },
  { school: "Winchelsea Primary School", campus: "Winchelsea Primary School", address: "60 main Street, Winchelsea, VIC, 3241" },
  { school: "Windsor Primary School", campus: "Windsor Primary School", address: "Hornby Street, Windsor, VIC, 3181" },
  { school: "Winters Flat Primary School", campus: "Winters Flat Primary School", address: "3 Roberts Avenue, Castlemine, VIC, 3450" },
  { school: "Winton Primary School", campus: "Winton Primary School", address: "Fox Street, Winton, VIC, 3673" },
  { school: "Woady Yaloak Primary School", campus: "Ross Creek Campus", address: "651 Sebastopol Smythesdale Rd, Ross Creek, VIC, 3351" },
  { school: "Woady Yaloak Primary School", campus: "Scarsdale Campus", address: "149 Pitfield Road, Scarsdale, VIC, 3351" },
  { school: "Woady Yaloak Primary School", campus: "Smythesdale Campus", address: "50 Becker Street, Smythesdale, VIC, 3351" },
  { school: "Woady Yaloak Primary School", campus: "Snake Valley Campus", address: "999 Smythesdale Road, Snake Valley, VIC, 3351" },
  { school: "Wodonga Middle Years College", campus: "Huon Campus", address: "22-24 Mitchell Street, Wodonga, VIC, 3690" },
  { school: "Wodonga Middle Years College", campus: "Felltimber Campus", address: "8 Hedgerow Court, West wodonga, VIC, 3690" },
  { school: "Wodonga Middle Years College", campus: "Flying Fruit Fly Circus School", address: "8 Hedgerow Court, West wodonga, VIC, 3690" },
  { school: "Wodonga Middle Years College", campus: "HighWater Campus", address: "Gateway Village, Lincoln Causeway, Wodonga, VIC, 3690" },
  { school: "Wodonga Primary School", campus: "Wodonga Primary School", address: "Brockley Street, Wodonga, VIC, 3690" },
  { school: "Wodonga Senior Secondary College", campus: "Wodonga Senior Secondary College", address: "80 Brockley Street, Wodonga, VIC, 3690" },
  { school: "Wodonga Senior Secondary College", campus: "Wodonga Flexible Learning Centre", address: "4 Bowman Court, wodonga, VIC, 3690" },
  { school: "Wodonga Senior Secondary College", campus: "Benalla Flexible Learning Centre", address: "41-55 Barkly Street, Benalla, VIC, 3672" },
  { school: "Wodonga Senior Secondary College", campus: "Seymour Flexible Learning Centre", address: "50 Tallarook Street, Seymour, VIC, 3660" },
  { school: "Wodonga Senior Secondary College", campus: "Shepparton Flexible Learning Centre", address: "130 Hayes Street, Shepparton, VIC, 3630" },
  { school: "Wodonga South Primary School", campus: "Wodonga South Primary School", address: "15 Cartwright Street, wodonga, VIC, 3690" },
  { school: "Wodonga West Primary School", campus: "Wodonga West Primary School", address: "Lawrence Street, Wodonga, VIC, 3690" },
  { school: "Wonga Park Primary School", campus: "Wonga Park Primary School", address: "Dudley Road, Wonga Park, VIC, 3115" },
  { school: "Wonthaggi North Primary School", campus: "Wonthaggi North Primary School", address: "Cnr White & Wentworth Road, Wonthaggi, VIC, 3995" },
  { school: "Wonthaggi Primary School", campus: "Wonthaggi Primary School", address: "18 Billson Street, Wonhaggi, VIC, 3995" },
  { school: "Wonthaggi Secondary College", campus: "Senior Campus", address: "Etty Street, Castlemaine, VIC, 3450" },
  { school: "Wonthaggi Secondary College", campus: "Dudley Campus", address: "174 South Dudley Road, Wonthaggi, VIC, 3995" },
  { school: "Wonthaggi Secondary College", campus: "Community VCAL Program Campus", address: "239 White Road, Wonthaggi, VIC, 3995" },
  { school: "Woodend Primary School", campus: "Woodend Primary School", address: "146 High Street, Woodend, VIC, 3442" },
  { school: "Woodend Primary School", campus: "Carlsruhe Campus", address: "57 Nicholson Street, Carlsruhe, VIC, 3442" },
  { school: "Woodford Primary School", campus: "Woodford Primary School", address: "8 Victoria Street, Woodford, VIC, 3281" },
  { school: "Woodlands Primary School", campus: "Woodlands Primary School", address: "10 Gum Nut Drive, Langwarrin, VIC, 3910" },
  { school: "Woodmans Hill Secondary College", campus: "Woodmans Hill Secondary College", address: "1 Fussell Street, Bellarat East, VIC, 3350" },
  { school: "Woodside Primary School", campus: "Woodside Primary School", address: "41 Beach Road, Woodside, VIC, 3874" },
  { school: "Woodville Primary School", campus: "Woodville Primary School", address: "33-57 Warringa Crescent, Hoppers Crossing, VIC, 3029" },
  { school: "Woolsthorpe Primary School", campus: "Woolsthorpe Primary School", address: "12 Manifold Street, Woolsthorpe, VIC, 3276" },
  { school: "Wooragee Primary School", campus: "Wooragee Primary School", address: "1011 Beechworth-Wodonga Road, Wooragee, VIC, 3747" },
  { school: "Wooranna Park Primary School", campus: "Wooranna Park Primary School", address: "89-105 Carlton Road, Dandenong, VIC, 3175" },
  { school: "Woori Yallock Primary School", campus: "Woori Yallock Primary School", address: "1360 Healesville Road, Woori Yallock, VIC, 3139" },
  { school: "Woorinen District Primary School", campus: "Woorinen District Primary School", address: "49 Palmer Street, Woorinen South, VIC, 3588" },
  { school: "Wunghnu Primary School", campus: "Wunghnu Primary School", address: "Brunton Street, Wunghnu, VIC, 3635" },
  { school: "Wurruk Primary School", campus: "Wurruk Primary School", address: "13-19 Fisk Street, Wurruk, VIC, 3850" },
  { school: "Wycheproof P-12 College", campus: "Wycheproof P-12 College", address: "McKenzie Crescent, Wycheproof, VIC, 3527" },
  { school: "Wyndham Central Secondary College", campus: "Wyndham Central Secondary College", address: "101 Shaws Road, Werribee, VIC, 3030" },
  { school: "Wyndham Park Primary School", campus: "Wyndham Park Primary School", address: "59-77 Kookaburra Avenue, Weeibee, 3030" },
  { school: "Wyndham Vale Primary School", campus: "Wyndham Vale Primary School", address: "85 Ribblesdale Ave, Wyndham Vale, VIC, 3024" },
  { school: "Yaapeet Primary School", campus: "Yaapeet Primary School", address: "4 Woods Street, Yaapeet, VIC, 3424" },
  { school: "Yackandandah Primary School", campus: "Yackandandah Primary School", address: "39 High Street, Yackandandah, VIC, 3749" },
  { school: "Yallourn North Primary School", campus: "Yallourn North Primary School", address: "2A Reserve Street, Yallourn North, VIC, 3825" },
  { school: "Yandoit Primary School", campus: "Yandoit Primary School", address: "74 High Street, Yandoit, VIC, 3461" },
  { school: "Yarra Glen Primary School", campus: "Yarra Glen Primary School", address: "35 Symonds Street, Yarra Glen, VIC, 3775" },
  { school: "Yarra Hills Secondary College", campus: "Mooroolbark Campus", address: "16 Reay Road, Mooroolbark, VIC, 3138" },
  { school: "Yarra Hills Secondary College", campus: "Mount Evelyn Campus", address: "15 Burdap Drive, Mount Evelyn, VIC, 3796" },
  { school: "Yarra Junction Primary School", campus: "Yarra Junction Primary School", address: "2370 Warburton Highway, Yarra Junction, VIC, 3797" },
  { school: "Yarra Me School", campus: "Hurstbridge Farm Campus", address: "320 Arthurs Crk Hurstbridge Rd, Nutfield, VIC, 3099" },
  { school: "Yarra Me School", campus: "Preston Campus", address: "Highview Road, Preston, VIC, 3072" },
  { school: "Yarra Primary School", campus: "Yarra Primary School", address: "68-76 Davison Street, Richmond, VIC, 3121" },
  { school: "Yarra Ranges Special Developmental School", campus: "Yarra Ranges Special Developmental School", address: "20 Burdap Drive, Mount Evelyn, VIC, 3796" },
  { school: "Yarra Road Primary School", campus: "Yarra Road Primary School", address: "222-228 Yarra Road, Croydon, VIC, 3136" },
  { school: "Yarrabah School", campus: "Yarrabah School", address: "23 Mill Street, Aspendale, VIC, 3195" },
  { school: "Yarragon Primary School", campus: "Yarragon Primary School", address: "65 Loch Street, Yarragon, VIC, 3823" },
  { school: "Yarram Primary School", campus: "Yarram Primary School", address: "2-14 Wesley Street, Yarram, VIC, 3971" },
  { school: "Yarram Secondary College", campus: "Devon North Campus", address: "3 Devon North Connection Road, Devon North, VIC,  3971" },
  { school: "Yarram Secondary College", campus: "Yarram Secondary College", address: "86 James Street, Yarram, VIC, 3971" },
  { school: "Yarraman Oaks Primary School", campus: "Yarraman Oaks Primary School", address: "27 Liege Avenue, Noble Park, VIC, 3174" },
  { school: "Yarrambat Primary School", campus: "Yarrambat Primary School", address: "552 Yan Yean Road, Yarrambat, VIC, 3091" },
  { school: "Yarraville Special Developmental School", campus: "Yarraville Special Developmental School", address: "117 Blackwood Street, Yarraville, VIC, 3013" },
  { school: "Yarraville West Primary School", campus: "Yarraville West Primary School", address: "30 Powell Street, Yarraville, VIC, 3013" },
  { school: "Yarrawonga College P-12", campus: "Pinniger Street Campus", address: "2-24 Pinniger Street, Yarrawonga, VIC, 3730" },
  { school: "Yarrawonga College P-12", campus: "Gilmore Street Campus", address: "8-12 Gilmore Street, Yarrawonga, VIC, 3730" },
  { school: "Yarrunga Primary School", campus: "Yarrunga Primary School", address: "38-50 White Street, Wangaratta, VIC, 3677" },
  { school: "Yea High School", campus: "Yea High School", address: "67 Racecourse Rd, Yea, VIC, 3717" },
  { school: "Yea High School", campus: "Chinese Community Centre", address: "320 Wantirna Road, Bayswater, VIC, 3153" },
  { school: "Yea High School", campus: "Eltham Birribi", address: "10 Eucalyptus Street, Eltham, VIC, 3095" },
  { school: "Yea High School", campus: "Kevin Collopy Pavilion", address: "Jubilee Park, Frankston, VIC, 3199" },
  { school: "Yea High School", campus: "Goldsworthy Road Campus", address: "Goldsworthy Road, Corio, VIC, 3214" },
  { school: "Yea High School", campus: "The Briars", address: "450 Nepean Highway, Mount Martha, VIC, 3934" },
  { school: "Yea High School", campus: "Memorial Hall campus", address: "827 High Street, Epping, VIC, 3076" },
  { school: "Yea Primary School", campus: "Yea Primary School", address: "23 Station Street, Yea, VIC, 3717" },
  { school: "Yering Primary School", campus: "Yering Primary School", address: "22-24 Melba Highway, Yering, VIC, 3770" },
  { school: "Yinnar Primary School", campus: "Yinnar Primary School", address: "30 Main Street, Yinnar, VIC, 3869" },
  { school: "Yinnar South Primary School", campus: "Yinnar South Primary School", address: "Brewsters Road, Yinnar South, VIC, 3869" },
  { school: "Yuille Park P-8 Community College", campus: "Grevillea Campus", address: "39 Violet Grove, Wendouree, VIC, 3355" },
  { school: "Yuille Park P-8 Community College", campus: "Yuille Campus", address: "McKenzie Drive, Wendouree, VIC, 3355" },
  { school: "Yuille Park P-8 Community College", campus: "Young Parents Campus", address: "102 Leawarra Crescent, Delacombe, VIC, 3356" },
  { school: "Zeerust Primary School", campus: "Zeerust Primary School", address: "245 Zeerust Road, Zeerust, VIC, 3634" },
];

const tradeOptions = [
  'Restorer', 'Roof Plumber', 'Plumber', 'Electrician', 'Carpenter', 'Plasterer/Painter', 'Fencing', 'Cleaner', 'Other Trades'
];

const investigationOptions = [
  'No',
  'Mould Inspection',
  'Asbestos Inspection/Removal/Division 6 Audit',
  'Engineers Attendance',
  'Hygienist'
];

const otherTradesRequiredOptions = [
  'No', 'Restorer', 'Roof Plumber', 'Plumber', 'Electrician', 'Carpenter', 'Plasterer', 'Painter'
];

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export const sanitize = (input) => {
  return input.replace(/[^a-zA-Z0-9\-_.]/g, '');
}

const App = () => {
  const [formData, setFormData] = useState({
    contractorName: '',
    workOrderNo: '',
    schoolName: '',
    campusName: '',
    schoolAddress: '',
    dateOfAttendance: new Date(),
    tradeType: '',
    observedDescription: '',
    worksConducted: '',
    clientConcerns: '',
    mattersForConsideration: '',
    tradesmen: [''],
    markedSamsPlan: [],
    beforePhotos: [],
    afterPhotos: [],
    swms: [],
    furtherInvestigation: [],
    otherTradesRequired: [],
    visibleMouldDetected: '',
    hygienistRequired: '',
    mouldContainmentPhotos: [],
    supportingPhotos: [],
    complianceDocuments: [],
    certificateOfElectricalSafety: [],
    roofWorksPreTaskCheck: [],
    safetyPhotos: []
  });

  const [previews, setPreviews] = useState({
    beforePhotos: [], afterPhotos: [], markedSamsPlan: [], swms: [],
    mouldContainmentPhotos: [], supportingPhotos: [], complianceDocuments: [],
    certificateOfElectricalSafety: [], roofWorksPreTaskCheck: [], safetyPhotos: []
  });
  const [captions, setCaptions] = useState({
    beforePhotos: [], afterPhotos: [], markedSamsPlan: [], swms: [],
    mouldContainmentPhotos: [], supportingPhotos: [], complianceDocuments: [],
    certificateOfElectricalSafety: [], roofWorksPreTaskCheck: [], safetyPhotos: []
  });
  const [filesUploaded, setFilesUploaded] = useState({
    beforePhotos: false, afterPhotos: false, markedSamsPlan: false, swms: false,
    mouldContainmentPhotos: false, supportingPhotos: false, complianceDocuments: false,
    certificateOfElectricalSafety: false, roofWorksPreTaskCheck: false, safetyPhotos: false
  });
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const uniqueSchools = useMemo(() => [...new Set(schoolsData.map(data => data.school))], []);

  const campuses = useMemo(() => {
    return schoolsData
      .filter(data => data.school === formData.schoolName)
      .map(data => data.campus);
  }, [formData.schoolName]);

  useEffect(() => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    if (formData.schoolName && formData.campusName) {
      const selected = schoolsData.find(data => data.school === formData.schoolName && data.campus === formData.campusName);
      if (selected) {
        setFormData({ ...formData, schoolAddress: selected.address });
      }
    } else {
      setFormData({ ...formData, schoolAddress: '' });
    }
  }, [formData.schoolName, formData.campusName]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const newFiles = Array.from(files);

    const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
    setPreviews({ ...previews, [name]: [...previews[name], ...newPreviews] });

    if (name === 'beforePhotos' || name === 'afterPhotos' || name === 'markedSamsPlan' || name === 'swms' || name === 'mouldContainmentPhotos' || name === 'supportingPhotos' || name === 'complianceDocuments' || name === 'certificateOfElectricalSafety' || name === 'roofWorksPreTaskCheck' || name === 'safetyPhotos') {
      setCaptions({ ...captions, [name]: [...newFiles.map(() => '')] });
    }

    setFormData({ ...formData, [name]: [...formData[name], ...newFiles] });
    setFilesUploaded({ ...filesUploaded, [name]: true });
  };

  const handleCaptionChange = (index, e, name) => {
    const newCaptions = [...captions[name]];
    newCaptions[index] = e.target.value;
    setCaptions({ ...captions, [name]: newCaptions });
  };

  const removeCaption = (index, name) => {
    const newCaptions = captions[name].filter((_, i) => i !== index);
    const newPreviews = previews[name].filter((_, i) => i !== index);
    setCaptions({ ...captions, [name]: newCaptions });
    setPreviews({ ...previews, [name]: newPreviews });
  };

  const removeFile = (name, index) => {
    const newFiles = formData[name].filter((_, i) => i !== index);
    const newPreviews = previews[name].filter((_, i) => i !== index);
    setFormData({ ...formData, [name]: newFiles });
    setPreviews({ ...previews, [name]: newPreviews });
    if (newFiles.length === 0) {
      setFilesUploaded({ ...filesUploaded, [name]: false });
    }
  };

  const addTradesman = () => {
    setFormData({ ...formData, tradesmen: [...formData.tradesmen, ''] });
  };

  const removeTradesman = (index) => {
    const newTradesmen = formData.tradesmen.filter((_, i) => i !== index);
    setFormData({ ...formData, tradesmen: newTradesmen });
  };

  const handleTradesmanChange = (index, e) => {
    const newTradesmen = formData.tradesmen.map((tradesman, i) => (i === index ? e.target.value : tradesman));
    setFormData({ ...formData, tradesmen: newTradesmen });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dateOfAttendance: date });
  };

  const handleInvestigationChange = (e) => {
    const value = e.target.value;
    if (value.includes('No')) {
      setFormData({ ...formData, furtherInvestigation: ['No'] });
    } else {
      setFormData({ ...formData, furtherInvestigation: value.filter(v => v !== 'No') });
    }
  };

  const handleOtherTradesRequiredChange = (e) => {
    const value = e.target.value;
    if (value.includes('No')) {
      setFormData({ ...formData, otherTradesRequired: ['No'] });
    } else {
      setFormData({ ...formData, otherTradesRequired: value.filter(v => v !== 'No') });
    }
  };

  const handleTradeTypeChange = (e) => {
    setFormData({
      ...formData,
      tradeType: e.target.value,
      observedDescription: '',
      worksConducted: '',
      markedSamsPlan: [],
      beforePhotos: [],
      afterPhotos: [],
      swms: [],
      visibleMouldDetected: '',
      hygienistRequired: '',
      mouldContainmentPhotos: [],
      supportingPhotos: [],
      complianceDocuments: [],
      certificateOfElectricalSafety: [],
      roofWorksPreTaskCheck: [],
      safetyPhotos: [],
      captions: { beforePhotos: [], afterPhotos: [], markedSamsPlan: [], swms: [] },
      filesUploaded: { beforePhotos: false, afterPhotos: false, markedSamsPlan: false, swms: false },
      previews: { beforePhotos: [], afterPhotos: [], markedSamsPlan: [], swms: [] }
    });
  };

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const validateForm = () => {
    let isValid = true;
    let firstInvalidField = null;

    if (!formData.contractorName) {
      isValid = false;
      firstInvalidField = contractorNameRef;
    }
    if (!formData.workOrderNo) {
      isValid = false;
      firstInvalidField = firstInvalidField || workOrderNoRef;
    }
    if (!formData.schoolName) {
      isValid = false;
      firstInvalidField = firstInvalidField || schoolNameRef;
    }
    if (!formData.campusName) {
      isValid = false;
      firstInvalidField = firstInvalidField || campusNameRef;
    }
    if (!formData.dateOfAttendance) {
      isValid = false;
      firstInvalidField = firstInvalidField || dateOfAttendanceRef;
    }
    if (formData.tradesmen.some(tradesman => !tradesman)) {
      isValid = false;
      firstInvalidField = firstInvalidField || tradesmenRef.current[formData.tradesmen.findIndex(t => !t)];
    }
    if (!formData.tradeType) {
      isValid = false;
      firstInvalidField = firstInvalidField || tradeTypeRef;
    }
    if (!formData.observedDescription) {
      isValid = false;
      firstInvalidField = firstInvalidField || observedDescriptionRef;
    }
    if (!formData.worksConducted) {
      isValid = false;
      firstInvalidField = firstInvalidField || worksConductedRef;
    }
    if (formData.tradeType === 'Restorer') {
      if (!formData.visibleMouldDetected) {
        isValid = false;
        firstInvalidField = firstInvalidField || visibleMouldDetectedRef;
      }
      if (formData.visibleMouldDetected === 'Yes' && !formData.hygienistRequired) {
        isValid = false;
        firstInvalidField = firstInvalidField || hygienistRequiredRef;
      }
    }
    if (formData.furtherInvestigation.length === 0) {
      isValid = false;
      firstInvalidField = firstInvalidField || furtherInvestigationRef;
    }

    if (!isValid && firstInvalidField) {
      scrollToRef(firstInvalidField);
      setStatusMessage('Please fill in all required fields.');
      setSnackbarSeverity('error');
      setShowSnackbar(true);
    }

    return isValid;
  };

    // Refs for scrolling to invalid fields
    const contractorNameRef = useRef(null);
    const workOrderNoRef = useRef(null);
    const schoolNameRef = useRef(null);
    const campusNameRef = useRef(null);
    const dateOfAttendanceRef = useRef(null);
    const tradesmenRef = useRef([]);
    const tradeTypeRef = useRef(null);
    const observedDescriptionRef = useRef(null);
    const worksConductedRef = useRef(null);
    const visibleMouldDetectedRef = useRef(null);
    const hygienistRequiredRef = useRef(null);
    const furtherInvestigationRef = useRef(null);

  const zipAndUploadFiles = async (files, fileType, submissionTimestamp) => {
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(file.name, file);
    });

    const blob = await zip.generateAsync({ type: 'blob' });
    const sanitizedSchoolName = sanitize(formData.schoolName);
    const sanitizedCampusName = sanitize(formData.campusName);
    const uniqueIdentifier = Math.random().toString(36).substring(2, 8); // 6-character unique identifier
    const zipFileName = `${fileType}-${formData.workOrderNo}-${sanitizedSchoolName}-${uniqueIdentifier}.zip`; // File name format
    const s3Key = `${formData.workOrderNo}-${sanitizedSchoolName}-${sanitizedCampusName}/${submissionTimestamp}/${fileType}/${zipFileName}`;

    const params = {
      Bucket: 'programmedformupdates', // Specify your S3 bucket name
      Key: s3Key,
      Body: blob,
      ContentType: 'application/zip'
    };

    await s3.upload(params).promise();
    return s3Key;
};



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true);

    // Proactively check for network connectivity
    if (!navigator.onLine) {
        setStatusMessage('No internet connection detected. Please check your connection and try again.');
        setSnackbarSeverity('error');
        setLoading(false);
        setShowSnackbar(true);
        return; // Early exit if no network connection
    }

    // Record the submission timestamp at the start of the process
    const submissionTimestamp = moment().format('DD-MM-YYYY---HH-MM');

    const fileKeys = {};
    const fileFields = [
        'markedSamsPlan',
        'beforePhotos',
        'afterPhotos',
        'swms',
        'mouldContainmentPhotos',
        'supportingPhotos',
        'complianceDocuments',
        'certificateOfElectricalSafety',
        'roofWorksPreTaskCheck',
        'safetyPhotos'
    ];

    try {
        // Attempt to upload files and get their keys
        for (const field of fileFields) {
            if (formData[field] && formData[field].length > 0) {
                fileKeys[field] = await zipAndUploadFiles(formData[field], field, submissionTimestamp);
            }
        }

        // Prepare payload for the Lambda function
        const lambdaPayload = {
            formData,
            fileKeys
        };

        // Set up parameters for invoking Lambda function
        const params = {
            FunctionName: 'ProcessFormData', // Replace with your Lambda function name
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(lambdaPayload)
        };

        // Invoke Lambda function
        const response = await lambda.invoke(params).promise();
        const result = JSON.parse(response.Payload);
        if (result.statusCode === 200) {
            setStatusMessage('Upload successful! You may now close this tab or refresh the page to submit another form.');
            setSnackbarSeverity('success');
        } else if (result.statusCode === 500) {
            setStatusMessage('Amazon Cloud Server Error, please resubmit form in a few mins. If error persists, notify Antillia support with a Screenshot of the error.');
            setSnackbarSeverity('error');
        } else {
            throw new Error(`Server responded with status code ${result.statusCode}`);
        }
    } catch (error) {
        if (error.code === 'NetworkingError' || error.code === 'UnknownEndpoint') {
            // Handle network errors during AWS operations
            setStatusMessage('Internet connection issue: Either no connection or poor signal. Please connect to WiFi or enable Mobile data, and try again');
        } else {
            // Handle other types of errors
            setStatusMessage(`Upload failed: ${error.message} - notify Antillia support with a Screenshot of this error`);
        }
        setSnackbarSeverity('error');
        console.error('Error during form submission:', error);
    } finally {
        setLoading(false);
        setShowSnackbar(true);
    }
};




  const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 46;

    return (
      <div ref={ref} {...other}>
        <FixedSizeList
          height={Math.min(250, itemCount * itemSize)}
          width="100%"
          itemSize={itemSize}
          itemCount={itemCount}
          outerElementType={React.forwardRef((props, ref) => <div {...props} ref={ref} />)}
        >
          {({ index, style }) => React.cloneElement(children[index], { style })}
        </FixedSizeList>
      </div>
    );
  });

  return (
    <Box className="app-container">
      <Header />
      <Box className="content-container">
        <Container>
          <Paper elevation={3} className="form-container">
            <form onSubmit={handleSubmit}>
              <Typography variant="h4" gutterBottom align="center" className="form-heading">
                Make-Safe Reports for Programmed
              </Typography>
              <TextField
                fullWidth
                label="Contractor (Company Name)"
                name="contractorName"
                value={formData.contractorName}
                onChange={handleInputChange}
                margin="normal"
                required
                error={!formData.contractorName}
                helperText={!formData.contractorName ? "This field is required" : ""}
                inputRef={contractorNameRef}
              />
              <TextField
                fullWidth
                label="Work Order No. (Eg: WA9181318)"
                name="workOrderNo"
                value={formData.workOrderNo}
                onChange={handleInputChange}
                margin="normal"
                required
                error={!formData.workOrderNo}
                helperText={!formData.workOrderNo ? "This field is required" : ""}
                inputRef={contractorNameRef}
              />
              <Autocomplete
                fullWidth
                options={uniqueSchools}
                value={formData.schoolName}
                onChange={(event, value) => handleAutocompleteChange('schoolName', value)}
                renderInput={(params) => <TextField {...params} label="School Name" margin="normal"
                required
                error={!formData.schoolName}
                helperText={!formData.schoolName ? "This field is required" : ""}
                inputRef={schoolNameRef}
                   />}
                ListboxComponent={ListboxComponent}
              />
              <Autocomplete
                fullWidth
                options={campuses}
                value={formData.campusName}
                onChange={(event, value) => handleAutocompleteChange('campusName', value)}
                renderInput={(params) => <TextField {...params} label="Campus Name" margin="normal" required
                error={!formData.campusName}
                helperText={!formData.campusName ? "This field is required" : ""}
                inputRef={campusNameRef} />}
                ListboxComponent={ListboxComponent}
              />
              <TextField
                fullWidth
                multiline
                label="School Address"
                name="schoolAddress"
                value={formData.schoolAddress}
                margin="normal"
                rows={2}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Box mt={2} mb={2} width="100%">
                <DatePicker
                  selected={formData.dateOfAttendance}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="date-picker"
                  required

                  error={!formData.dateOfAttendance}
                  helperText={!formData.dateOfAttendance ? "This field is required" : ""}
                  ref={dateOfAttendanceRef}
                  wrapperClassName="date-picker-wrapper"
                  customInput={<TextField fullWidth label="Date of Attendance" margin="normal" />}
                />
              </Box>
              <Typography variant="h6" gutterBottom className="smaller-text">
                All Tradesmen on Site (and Programmed Card No. if available)
              </Typography>
              {formData.tradesmen.map((tradesman, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    fullWidth
                    label={`Tradesman ${index + 1}`}
                    value={tradesman}
                    onChange={(e) => handleTradesmanChange(index, e)}
                    margin="normal"
                    required
                    error={!tradesman}
                    helperText={!tradesman ? "This field is required" : ""}
                    inputRef={(el) => tradesmenRef.current[index] = el}
                    placeholder="Eg: Micheal Jones - PRG-B8Z6-000000098760"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        whiteSpace: 'normal',
                        lineHeight: '1.2',
                        transformOrigin: 'top left',
                        backgroundColor: 'white',
                        padding: '0 4px',
                        transition: 'transform 0.2s ease-out',
                        maxWidth: 'calc(100% - 24px)',
                        marginLeft: '-8px', // Align with the padding of the input
                        marginTop: '-2px', // Adjust to remove gap
                      }
                    }}
                    InputProps={{
                      style: {
                        fontSize: '14px',
                      },
                      startAdornment: <div style={{ padding: '0 0px' }} />, // Padding to align with label
                    }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'grey', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: 'grey', // Hover border color
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'grey', // Focused border color
                        },
                      },
                    }}
                  />
                  <IconButton onClick={() => removeTradesman(index)} style={{ color: 'red', marginLeft: '8px' }}>
                    <Delete />
                  </IconButton>
                </Box>
              ))}
              <Button
                variant="contained"
                sx={{ backgroundColor: '#005b5b', '&:hover': { backgroundColor: '#004848' }, marginTop: '10px', marginBottom: '20px' }}
                startIcon={<Add />}
                onClick={addTradesman}
              >
                Add Tradesman
              </Button>
              <FormControl fullWidth margin="normal"
               required
                error={!formData.tradeType}
                helperText={!formData.tradeType ? "This field is required" : ""}
                inputRef={tradeTypeRef}
               >
                <InputLabel id="trade-type-label">Trade Type</InputLabel>
                <Select
                  labelId="trade-type-label"
                  value={formData.tradeType}
                  onChange={handleTradeTypeChange}
                  label="Trade Type"
                >
                  {tradeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formData.tradeType && (
                <>
                  <TextField
                    fullWidth
                    label="Details of Observations"
                    name="observedDescription"
                    value={formData.observedDescription}
                    onChange={handleInputChange}
                    margin="normal"
                    multiline
                    placeholder={`Eg: ${formData.tradeType} specific observations...`}
                    rows={8}
                    required
                    error={!formData.observedDescription}
                    helperText={!formData.observedDescription ? "This field is required" : ""}
                    inputRef={observedDescriptionRef}
                  />
                  <TextField
                    fullWidth
                    label="Works Undertaken"
                    name="worksConducted"
                    value={formData.worksConducted}
                    onChange={handleInputChange}
                    margin="normal"
                    placeholder={`Eg: ${formData.tradeType} specific works...`}
                    multiline
                    rows={8}
                    required
                    error={!formData.worksConducted}
                    helperText={!formData.worksConducted ? "This field is required" : ""}
                    inputRef={worksConductedRef}
                  />
                  {formData.tradeType === 'Restorer' && (
                    <>
                      <FormControl fullWidth margin="normal"
                       required
                        error={!formData.visibleMouldDetected}
                        helperText={!formData.visibleMouldDetected ? "This field is required" : ""}
                        inputRef={visibleMouldDetectedRef}
                       >
                        <InputLabel id="visible-mould-detected-label">Visible Mould detected?</InputLabel>
                        <Select
                          labelId="visible-mould-detected-label"
                          value={formData.visibleMouldDetected}
                          onChange={(e) => handleInputChange(e)}
                          name="visibleMouldDetected"
                          label="Visible Mould detected?"
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                      {formData.visibleMouldDetected === 'Yes' && (
                        <>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              margin: '16px 0' // Adjust the margin as needed
                            }}
                          >
                            <span style={{ color: 'red', fontWeight: 'bold' }}>Please contain the visible mould</span>
                          </Typography>
                          <FormControl fullWidth margin="normal"
                          required
                            error={!formData.hygienistRequired}
                            helperText={!formData.hygienistRequired ? "This field is required" : ""}
                            inputRef={hygienistRequiredRef}
                          >
                            <InputLabel id="hygienist-required-label">Hygienist required to Investigate and prepare SOW?</InputLabel>
                            <Select
                              labelId="hygienist-required-label"
                              value={formData.hygienistRequired}
                              onChange={(e) => handleInputChange(e)}
                              name="hygienistRequired"
                              label="Hygienist required to Investigate and prepare SOW?"
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                  <FormControl fullWidth margin="normal"
                   required
                    error={formData.furtherInvestigation.length === 0}
                    helperText={formData.furtherInvestigation.length === 0 ? "This field is required" : ""}
                    inputRef={furtherInvestigationRef}
                    variant="outlined">
                    <InputLabel
                      id="further-investigation-label"
                      shrink={formData.furtherInvestigation.length > 0}
                      style={{
                        whiteSpace: 'normal',
                        lineHeight: '1.2',
                        transformOrigin: 'top left',
                        backgroundColor: 'white',
                        padding: '0 4px',
                        transform: formData.furtherInvestigation.length > 0 ? 'translate(14px, -20px) scale(0.75)' : 'translate(14px, 14px) scale(1)',
                        transition: 'transform 0.2s ease-out',
                        maxWidth: 'calc(100% - 24px)',
                      }}
                    >
                      Is further investigation required?
                      <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
                        (to attend to any other items caused by the event)
                      </span>
                    </InputLabel>
                    <Select
                      labelId="further-investigation-label"
                      multiple
                      value={formData.furtherInvestigation}
                      onChange={handleInvestigationChange}
                      renderValue={(selected) => selected.join(', ')}
                      style={{ paddingTop: '25px' }} // Adjust padding to move label inside the field
                    >
                      {formData.tradeType === 'Restorer'
                        ? investigationOptions
                            .filter(option => option !== 'Mould Inspection' && option !== 'Hygienist')
                            .map((option) => (
                              <MenuItem key={option} value={option}>
                                <Checkbox checked={formData.furtherInvestigation.includes(option)} />
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))
                        : investigationOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              <Checkbox checked={formData.furtherInvestigation.includes(option)} />
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Any Concerns Raised by the Client?"
                    name="clientConcerns"
                    value={formData.clientConcerns}
                    onChange={handleInputChange}
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <TextField
                    fullWidth
                    label="Matters for Consideration"
                    name="mattersForConsideration"
                    value={formData.mattersForConsideration}
                    onChange={handleInputChange}
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <FormControl fullWidth margin="normal"
                  required
                    error={formData.otherTradesRequired.length === 0}
                    helperText={formData.otherTradesRequired.length === 0 ? "This field is required" : ""}
                    inputRef={furtherInvestigationRef}

                   variant="outlined">
                    <InputLabel
                      id="other-trades-required-label"
                      shrink={formData.otherTradesRequired.length > 0}
                      style={{
                        whiteSpace: 'normal',
                        lineHeight: '1.2',
                        transformOrigin: 'top left',
                        backgroundColor: 'white',
                        padding: '0 4px'
                      }}
                    >
                      Are other trades required on-site?
                      <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
                        (to attend to any other items caused by the event)
                      </span>
                    </InputLabel>
                    <Select
                      labelId="other-trades-required-label"
                      multiple
                      value={formData.otherTradesRequired}
                      onChange={handleOtherTradesRequiredChange}
                      renderValue={(selected) => selected.join(', ')}
                      label="Are other trades required on-site? (to attend to any other items caused by the event)"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 224, // Adjust as needed for your design
                            width: 250, // Adjust as needed for your design
                          },
                        },
                      }}
                      style={{ paddingTop: '25px' }} // Adjust padding to move label inside the field
                    >
                      {otherTradesRequiredOptions
                        .filter((option) => option !== formData.tradeType)
                        .map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox checked={formData.otherTradesRequired.includes(option)} />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formData.tradeType === 'Carpenter' && <CarpentryFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Electrician' && <ElectricalFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Restorer' && <RestorerFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Roof Plumber' && <RoofPlumberFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Plumber' && <PlumberFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Plasterer/Painter' && <PlastererPainterFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Fencing' && <FencingFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Cleaner' && <CleaningFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                  {formData.tradeType === 'Other Trades' && <GeneralTradeFields formData={formData} handleFileChange={handleFileChange} removeFile={removeFile} previews={previews} captions={captions} handleCaptionChange={handleCaptionChange} />}
                </>
              )}
              <Box mt={4} textAlign="center">
                <Button variant="contained" sx={{ backgroundColor: '#005b5b', '&:hover': { backgroundColor: '#004848' }, fontSize: '1.2em', padding: '10px 20px' }} type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          </Paper>
          <Box textAlign="center" mt={4} className="contact-info">
            <Typography variant="body1">
              <strong>Having issues? Contact IT Technical Support on <a href="tel:0405236236" style={{ color: '#005b5b' }}>0405 236 236</a></strong>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Footer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
       <Box
            sx={{
              backgroundColor: 'rgba(0, 91, 91, 0.7)',
              padding: '20px',
              borderRadius: '8px',
              textAlign: 'center'
            }}
>

          <CircularProgress color="inherit" />
          <Typography variant="h6" mt={2}>
            Please Wait!<br></br> Upload process may take upto 5 minutes or longer depending on the attachment size and internet speed.<br></br> Do not click away or interact with the browser in any way until upload is completed.
          </Typography>
        </Box>
      </Backdrop>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={10000000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity={snackbarSeverity}>
          {statusMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default App;