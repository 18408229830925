import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import { CloudUpload, Delete, CheckCircle } from '@mui/icons-material';
import TextField from '@mui/material/TextField';

const CarpentryFields = ({ formData, handleFileChange, removeFile, previews, captions, handleCaptionChange }) => {
  const [markedSamsPlanMargin, setMarkedSamsPlanMargin] = useState(30);
  const [beforePhotosMargin, setBeforePhotosMargin] = useState(30);
  const [afterPhotosMargin, setAfterPhotosMargin] = useState(30);
  const [swmsMargin, setSwmsMargin] = useState(30);

  useEffect(() => {
    setMarkedSamsPlanMargin(formData.markedSamsPlan.length > 0 ? 0 : 30);
  }, [formData.markedSamsPlan]);

  useEffect(() => {
    setBeforePhotosMargin(formData.beforePhotos.length > 0 ? 0 : 30);
  }, [formData.beforePhotos]);

  useEffect(() => {
    setAfterPhotosMargin(formData.afterPhotos.length > 0 ? 0 : 30);
  }, [formData.afterPhotos]);

  useEffect(() => {
    setSwmsMargin(formData.swms.length > 0 ? 0 : 30);
  }, [formData.swms]);

  const handleRemoveFile = (name, index) => {
    removeFile(name, index);
    if (name === 'markedSamsPlan' && formData.markedSamsPlan.length <= 1) {
      setMarkedSamsPlanMargin(30);
    } else if (name === 'beforePhotos' && formData.beforePhotos.length <= 1) {
      setBeforePhotosMargin(30);
    } else if (name === 'afterPhotos' && formData.afterPhotos.length <= 1) {
      setAfterPhotosMargin(30);
    } else if (name === 'swms' && formData.swms.length <= 1) {
      setSwmsMargin(30);
    }
  };

  return (
    <Box mt={2} textAlign="left" className="upload-container">
      <Typography variant="h6">Marked SAMs Plan</Typography>
      <input
        type="file"
        name="markedSamsPlan"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="markedSamsPlan"
      />
      <label htmlFor="markedSamsPlan" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${markedSamsPlanMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload File
          </Button>
          {formData.markedSamsPlan.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.markedSamsPlan.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.markedSamsPlan[index]}
                onChange={(e) => handleCaptionChange(index, e, 'markedSamsPlan')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('markedSamsPlan', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6">Before Photos</Typography>
      <input
        type="file"
        name="beforePhotos"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="beforePhotos"
      />
      <label htmlFor="beforePhotos" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${beforePhotosMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload Photos
          </Button>
          {formData.beforePhotos.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.beforePhotos.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.beforePhotos[index]}
                onChange={(e) => handleCaptionChange(index, e, 'beforePhotos')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('beforePhotos', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6">After Photos</Typography>
      <input
        type="file"
        name="afterPhotos"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="afterPhotos"
      />
      <label htmlFor="afterPhotos" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${afterPhotosMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload Photos
          </Button>
          {formData.afterPhotos.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.afterPhotos.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.afterPhotos[index]}
                onChange={(e) => handleCaptionChange(index, e, 'afterPhotos')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('afterPhotos', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6">SWMS (Site Specific)</Typography>
      <input
        type="file"
        name="swms"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="swms"
      />
      <label htmlFor="swms" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${swmsMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload File
          </Button>
          {formData.swms.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.swms.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.swms[index]}
                onChange={(e) => handleCaptionChange(index, e, 'swms')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('swms', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CarpentryFields;
