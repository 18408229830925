import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import { CloudUpload, Delete, CheckCircle } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import JSZip from 'jszip';

const RestorerFields = ({ formData, handleFileChange, removeFile, previews, captions, handleCaptionChange }) => {
  const [markedSamsPlanMargin, setMarkedSamsPlanMargin] = useState(30);
  const [mouldContainmentPhotosMargin, setMouldContainmentPhotosMargin] = useState(30);
  const [supportingPhotosMargin, setSupportingPhotosMargin] = useState(30);

  useEffect(() => {
    setMarkedSamsPlanMargin(formData.markedSamsPlan.length > 0 ? 0 : 30);
  }, [formData.markedSamsPlan]);

  useEffect(() => {
    setMouldContainmentPhotosMargin(formData.mouldContainmentPhotos.length > 0 ? 0 : 30);
  }, [formData.mouldContainmentPhotos]);

  useEffect(() => {
    setSupportingPhotosMargin(formData.supportingPhotos.length > 0 ? 0 : 30);
  }, [formData.supportingPhotos]);

  const handleFileChangeInternal = (e, name) => {
    const files = e.target.files;
    if (!files.length) return;

    const newPreviews = Array.from(files).map(file => URL.createObjectURL(file));

    handleFileChange({ target: { name, files } });
    previews[name] = newPreviews;
  };

  const handleRemoveFile = (name, index) => {
    removeFile(name, index);
    if (name === 'markedSamsPlan' && formData.markedSamsPlan.length <= 1) {
      setMarkedSamsPlanMargin(30);
    } else if (name === 'mouldContainmentPhotos' && formData.mouldContainmentPhotos.length <= 1) {
      setMouldContainmentPhotosMargin(30);
    } else if (name === 'supportingPhotos' && formData.supportingPhotos.length <= 1) {
      setSupportingPhotosMargin(30);
    }
  };

  return (
    <Box mt={2} textAlign="left" className="upload-container">
      <Typography variant="h6">Marked SAMs Plan</Typography>
      <input
        type="file"
        name="markedSamsPlan"
        accept="image/*"
        multiple
        onChange={(e) => handleFileChangeInternal(e, 'markedSamsPlan')}
        style={{ display: 'none' }}
        id="markedSamsPlan"
      />
      <label htmlFor="markedSamsPlan" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${markedSamsPlanMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload File
          </Button>
          {formData.markedSamsPlan.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.markedSamsPlan.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.markedSamsPlan[index]}
                onChange={(e) => handleCaptionChange(index, e, 'markedSamsPlan')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('markedSamsPlan', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h6">
        Mould Containment Photos{' '}
        <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
          (If mould is detected, please upload photos showing visible mould before and after containment)
        </span>
      </Typography>
      <input
        type="file"
        name="mouldContainmentPhotos"
        accept="image/*"
        multiple
        onChange={(e) => handleFileChangeInternal(e, 'mouldContainmentPhotos')}
        style={{ display: 'none' }}
        id="mouldContainmentPhotos"
      />
      <label htmlFor="mouldContainmentPhotos" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${mouldContainmentPhotosMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload Photos
          </Button>
          {formData.mouldContainmentPhotos.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.mouldContainmentPhotos.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.mouldContainmentPhotos[index]}
                onChange={(e) => handleCaptionChange(index, e, 'mouldContainmentPhotos')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('mouldContainmentPhotos', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h6">
        Supporting Photos{' '}
        <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
          (if any other trades are required)
        </span>
      </Typography>
      <input
        type="file"
        name="supportingPhotos"
        accept="image/*"
        multiple
        onChange={(e) => handleFileChangeInternal(e, 'supportingPhotos')}
        style={{ display: 'none' }}
        id="supportingPhotos"
      />
      <label htmlFor="supportingPhotos" className="upload-label">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#005b5b',
              '&:hover': { backgroundColor: '#004848' },
              minWidth: '200px',
              marginBottom: `${supportingPhotosMargin}px`,
            }}
            component="span"
            startIcon={<CloudUpload />}
          >
            Upload Photos
          </Button>
          {formData.supportingPhotos.length > 0 && <CheckCircle color="success" sx={{ ml: 1 }} />}
        </Box>
      </label>
      <Grid container spacing={2} mt={2}>
        {previews.supportingPhotos.map((src, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Paper elevation={3} style={{ padding: '10px', textAlign: 'center', minHeight: '300px', position: 'relative', marginBottom: '35px' }}>
              <Box
                component="img"
                src={src}
                alt={`preview-${index}`}
                sx={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'contain' }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Caption"
                value={captions.supportingPhotos[index]}
                onChange={(e) => handleCaptionChange(index, e, 'supportingPhotos')}
                fullWidth
                margin="normal"
                sx={{ position: 'absolute', bottom: '40px', left: '0', right: '0' }}
              />
              <IconButton onClick={() => handleRemoveFile('supportingPhotos', index)} style={{ color: 'red', position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RestorerFields;
