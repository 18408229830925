import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import './Header.css';

const Header = () => (
  <AppBar position="static" className="header" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
    <Toolbar className="toolbar">
      <Box display="flex" alignItems="center" flexGrow={1}>
        <img src="Black Logo.png" alt="Company Logo" className="logo" />
        <Box ml={-2}> {/* Adjusted margin from ml={2} to ml={1} */}
          <Typography variant="h5" component="div" className="header-text">
            Antillia Emergency
          </Typography>
          <Typography variant="h5" component="div" className="header-text">
            Network
          </Typography>
        </Box>
      </Box>
      <img src="tech.png" alt="Right Logo" className="right-logo" />
    </Toolbar>
  </AppBar>
);

export default Header;
